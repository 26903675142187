import {
  ref, toRefs, watch, computed,
} from '@vue/composition-api'

export default function usePatientAnamneseModal(emit, props) {
  const delay = { show: 250, hide: 100 }

  const { userData } = toRefs(props)

  const filteredQuestions = computed(() => (userData.value.anamneses ? userData.value.anamneses.flat().map(el => el.questions).flat().filter(el => !!el.alert) : []))

  const showLocal = ref(false)

  const { show } = toRefs(props)
  watch(show, newValue => {
    showLocal.value = newValue
  })

  const hidden = () => {
    emit('hidden')
  }

  const confirmStatusChange = () => {
    hidden()
  }

  return {
    delay,
    filteredQuestions,
    showLocal,
    hidden,
    confirmStatusChange,
  }
}
