<template>
  <b-modal
    id="modal-add-treatment"
    ref="refModalAddTreatment"
    v-model="showLocal"
    centered
    title="Tratamentos"
    cancel-title="Cancelar"
    cancel-variant="outline-secondary"
    @hidden="hidden"
    @ok="sendToothData"
  >
    <h4>{{ activeTeethData.number }} - {{ activeTeethData.face }}</h4>
    <b-row>

      <!-- Field: Treatment -->
      <b-col
        cols="12"
        md="12"
        class="mt-1 mb-1"
      >
        <v-select
          v-model="procedure"
          :options="procedures"
          :clearable="false"
          :searchable="true"
          input-id="procedure-options"
          placeholder="Procedimento"
        />
      </b-col>

      <!-- Field: Color -->
      <b-col
        cols="12"
        md="12"
        class="mt-25 mb-1"
      >
        <v-select
          v-model="color"
          :options="colors"
          :clearable="false"
          :searchable="false"
          input-id="color-options"
          placeholder="Cor"
        />
      </b-col>
      <b-col
        cols="12"
        md="12"
        class="mt-25 mb-1"
      >
        <label>Observações</label>
        <b-overlay
          rounded="lg"
          opacity="0.6"
        >
          <b-form-textarea
            id="procedure-description"
            v-model="observation"
            class="mb-25"
          />
        </b-overlay>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  toRefs, ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import {
  BModal, BRow, BCol, BOverlay, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import procedureStoreModule from '@/views/apps/procedures/procedureStoreModule'
import store from '@/store'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BOverlay,
    BFormTextarea,
    vSelect,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    activeTeethData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { activeTeethData } = toRefs(props)

    const USER_APP_STORE_MODULE_NAME = 'store-procedure'

    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, procedureStoreModule)

    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('store-procedure/fetchAll')
    const procedures = computed(() => store.getters['store-procedure/forSelect'])

    const showLocal = ref(false)
    const observation = ref('')
    const procedure = ref({
      label: 'Sadio',
      value: 'healthy',
    })

    const color = ref({
      label: 'Vermelho',
      value: 'red',
    })

    const colors = ref([
      {
        label: 'Vermelho',
        value: 'red',
      },
      {
        label: 'Laranja',
        value: 'orange',
      },
      {
        label: 'Azul',
        value: 'blue',
      },
    ])

    // necessário para observar props
    const { show } = toRefs(props)
    watch(show, newValue => {
      showLocal.value = newValue

      procedure.value = procedures.value.find(e => e.value === activeTeethData.value[`${activeTeethData.value.face.toLowerCase()}Treatment`].procedure_id) || {
        label: 'Sadio',
        value: 'healthy',
      }

      color.value = colors.value.find(e => e.value === activeTeethData.value[`${activeTeethData.value.face.toLowerCase()}`]) || {
        label: 'Vermelho',
        value: 'red',
      }

      observation.value = activeTeethData.value[`${activeTeethData.value.face.toLowerCase()}Treatment`].observation
    })

    // Valores Iniciais do dente (puxar do banco de dados)
    const procedureData = ref({
      id: null,
      number: null,
      lingual: 'white',
      lingualTreatment: {
        procedure_id: null,
        procedure: null,
        observation: '',
      },
      mesial: 'white',
      mesialTreatment: {
        procedure_id: null,
        procedure: null,
        observation: '',
      },
      distal: 'white',
      distalTreatment: {
        procedure_id: null,
        procedure: null,
        observation: '',
      },
      vestibular: 'white',
      vestibularTreatment: {
        procedure_id: null,
        procedure: null,
        observation: '',
      },
      oclusal: 'white',
      oclusalTreatment: {
        procedure_id: null,
        procedure: null,
        observation: '',
      },
    })

    const hidden = () => {
      emit('hidden')
      procedure.value = {
        label: 'Sadio',
        value: 'healthy',
      }
      color.value = {
        label: 'Vermelho',
        value: 'red',
      }
      observation.value = ''
    }

    const sendToothData = () => {
      procedureData.value.number = activeTeethData.value.number
      procedureData.value = activeTeethData.value
      if (activeTeethData.value.face === 'lingual') {
        if (procedure.value.value === 'healthy') {
          procedureData.value.lingual = 'white'
          procedureData.value.lingualTreatment.procedure_id = null
          procedureData.value.lingualTreatment.procedure = null
          procedureData.value.lingualTreatment.observation = ''
        } else {
          procedureData.value.lingual = color.value.value
          procedureData.value.lingualTreatment.procedure_id = procedure.value.value
          procedureData.value.lingualTreatment.procedure = procedure.value.label
          procedureData.value.lingualTreatment.observation = observation.value
        }
      }
      if (activeTeethData.value.face === 'mesial') {
        if (procedure.value.value === 'healthy') {
          procedureData.value.mesial = 'white'
          procedureData.value.mesialTreatment.procedure_id = null
          procedureData.value.mesialTreatment.procedure = null
          procedureData.value.mesialTreatment.observation = ''
        } else {
          procedureData.value.mesial = color.value.value
          procedureData.value.mesialTreatment.procedure_id = procedure.value.value
          procedureData.value.mesialTreatment.procedure = procedure.value.label
          procedureData.value.mesialTreatment.observation = observation.value
        }
      }
      if (activeTeethData.value.face === 'distal') {
        if (procedure.value.value === 'healthy') {
          procedureData.value.distal = 'white'
          procedureData.value.distalTreatment.procedure_id = null
          procedureData.value.distalTreatment.procedure = null
          procedureData.value.distalTreatment.observation = ''
        } else {
          procedureData.value.distal = color.value.value
          procedureData.value.distalTreatment.procedure_id = procedure.value.value
          procedureData.value.distalTreatment.procedure = procedure.value.label
          procedureData.value.distalTreatment.observation = observation.value
        }
      }
      if (activeTeethData.value.face === 'vestibular') {
        if (procedure.value.value === 'healthy') {
          procedureData.value.vestibular = 'white'
          procedureData.value.vestibularTreatment.procedure_id = null
          procedureData.value.vestibularTreatment.procedure = null
          procedureData.value.vestibularTreatment.observation = ''
        } else {
          procedureData.value.vestibular = color.value.value
          procedureData.value.vestibularTreatment.procedure_id = procedure.value.value
          procedureData.value.vestibularTreatment.procedure = procedure.value.label
          procedureData.value.vestibularTreatment.observation = observation.value
        }
      }
      if (activeTeethData.value.face === 'oclusal') {
        if (procedure.value.value === 'healthy') {
          procedureData.value.oclusal = 'white'
          procedureData.value.oclusalTreatment.procedure_id = null
          procedureData.value.oclusalTreatment.procedure = null
          procedureData.value.oclusalTreatment.observation = ''
        } else {
          procedureData.value.oclusal = color.value.value
          procedureData.value.oclusalTreatment.procedure_id = procedure.value.value
          procedureData.value.oclusalTreatment.procedure = procedure.value.label
          procedureData.value.oclusalTreatment.observation = observation.value
        }
      }
      emit('updateTeeth', procedureData.value)
    }

    return {
      hidden,
      sendToothData,

      showLocal,
      procedures,
      colors,
      procedure,
      color,
      observation,
      procedureData,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
