import axios from '@axios'

export default {
  namespaced: true,
  state: {
    procedure: {},
    procedures: [],
  },
  getters: {
    forSelectAll(state) {
      const data = []
      if (Array.isArray(state.procedures)) {
        // eslint-disable-next-line array-callback-return
        state.procedures.map(e => {
          data.push({
            value: e.id,
            label: e.procedure,
            data: e,
          })
        })
      }
      return data
    },
    forSelect(state) {
      if (Array.isArray(state.procedures)) {
        const data = [{
          label: 'Sadio',
          value: 'healthy',
        }]
        // eslint-disable-next-line array-callback-return
        state.procedures.map(e => {
          data.push({
            value: e.id,
            label: e.procedure,
            data: e,
          })
        })
        return data
      }
      return [{
        label: 'Sadio',
        value: 'healthy',
      }]
    },
  },

  mutations: {
    SET_PROCEDURES(state, data) {
      state.procedures = data
    },
    SET_PROCEDURE(state, data) {
      state.procedure = data
    },
    ADD(state, data) {
      if (Array.isArray(state.procedures)) {
        state.procedures.push(data)
        state.procedures.sort((a, b) => a.procedure.localeCompare(b.procedure)) // Ordena por procedure
      }
    },
  },
  actions: {
    async fetchAll({ state, commit }) {
      try {
        if (!state.procedures.length) {
          const resp = await axios.get(`${process.env.VUE_APP_API_URL}/procedures`)
          commit('SET_PROCEDURES', resp.data)
          return resp
        }
        return state.procedures
      } catch (e) {
        return e
      }
    },

    fetchProcedures(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/procedures`, queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchProcedure({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/procedures/${id}`)
          .then(response => {
            commit('SET_PROCEDURE', response.data)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    add({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/procedure`, data)
          .then(resp => {
            commit('ADD', resp.data)
            resolve(resp)
          })
          .catch(e => reject(e))
      })
    },

    update({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/procedure/${data.id}`, data)
          .then(response => {
            commit('SET_PATIENT', response.data)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    delete(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${process.env.VUE_APP_API_URL}/procedure/${data.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
