import {
  ref, onUnmounted, computed,
} from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import axios from '@axios'
import store from '@/store'
import { dateTime } from '@core/utils/filter'
import procedureStoreModule from '@/views/apps/procedures/procedureStoreModule'
import treatmentProcedureStoreModule from './useStoreTreatmentProcedures'

export default function useTreatments(userData, root, emit) {
  const TREATMENT_PROCEDURE_STORE_MODULE_NAME = 'store-treatment-procedure'
  const USER_APP_STORE_MODULE_NAME = 'store-procedure'

  // Register module
  if (!store.hasModule(TREATMENT_PROCEDURE_STORE_MODULE_NAME)) store.registerModule(TREATMENT_PROCEDURE_STORE_MODULE_NAME, treatmentProcedureStoreModule)
  if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, procedureStoreModule)

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(TREATMENT_PROCEDURE_STORE_MODULE_NAME)) store.unregisterModule(TREATMENT_PROCEDURE_STORE_MODULE_NAME)
    if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
  })

  const procedures = computed(() => store.getters['store-procedure/forSelectAll'])
  const finalizing = ref(false)

  // Use toast
  const toast = useToast()

  const tableColumns = [
    {
      key: 'code',
      label: 'Cód.',
      tdClass: 'codeTdClass',
      sortable: true,
    },
    {
      key: 'procedure',
      label: 'Descrição',
      tdClass: 'procedureTdClass',
      sortable: true,
    },
    {
      key: 'teeth_faces',
      label: 'Dentes / Faces',
      tdClass: 'teeth_facesTdClass',
      sortable: false,
    },
    {
      key: 'pivot.budget',
      label: 'Orçamento',
      formatter: val => (val ? `${val.id} | ${dateTime(val.created_at, 'dateTimeNoSecond')}` : 'Avulso'),
      tdClass: 'budgetTdClass',
      sortable: false,
    },
    {
      key: 'observation',
      label: 'Obs.',
      tdClass: 'observationTdClass',
      sortable: false,
    },
    {
      key: 'actions',
      label: 'ações',
      tdClass: 'actionsTdClass',
      sortable: false,
    },
  ]

  const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
    toast({
      component: ToastificationContent,
      props: {
        titleToast,
        icon,
        text,
        variant,
      },
    })
  }

  const catchErr = e => {
    if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'AlertCircleIcon', 'Atenção', e.response.data.message)
    else if (e.response.status === 401) showToast('danger', 'AlertCircleIcon', 'Atenção', 'Sua sessão expirou, faça novo login!')
    else showToast('danger', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
  }

  const procedureData = ref(null)

  const add = async data => {
    try {
      const resp = await store.dispatch('store-treatment-procedure/add', data)
      emit('refresh', resp.data)
      showToast()
    } catch (e) {
      catchErr(e)
    }
  }

  const save = async data => {
    try {
      const resp = await store.dispatch('store-treatment-procedure/update', data)
      emit('refresh', resp.data)
      showToast()
    } catch (e) {
      catchErr(e)
    }
  }

  const blankData = ({
    evolution_type: 'EVOLUTION', // ATTENDANCE - PLANNING - EVOLUTION
    user_id: userData.value.id, // Patient
    pivot: {
      evolutions: [],
    },
  })
  const dt = ref(JSON.parse(JSON.stringify(blankData)))

  const orderEvolutions = procedure => {
    dt.value = JSON.parse(JSON.stringify(procedure))
    dt.value.pivot.evolutions.sort((b, a) => new Date(a.updated_at) - new Date(b.updated_at))
    // // eslint-disable-next-line no-underscore-dangle
    // dt.value.pivot.evolutions[0]._showDetails = true
  }

  const tmpProcedure = ref({})

  const saveEvolution = async (data, finalize) => {
    try {
      let resp = null
      const idx = userData.value.procedures.findIndex(e => e.pivot.uuid === data.procedure_uuid)
      if (data.id) {
        resp = await store.dispatch('store-treatment-procedure/updateEvolution', data)
        const idx2 = userData.value.procedures[idx].pivot.evolutions.findIndex(e => e.id === data.id)
        userData.value.procedures[idx].pivot.evolutions.splice(idx2, 1, resp.data)
        dt.value.pivot.evolutions.splice(idx2, 1, resp.data)
      } else {
        resp = await store.dispatch('store-treatment-procedure/addEvolution', data)
        userData.value.procedures[idx].pivot.evolutions.push(resp.data)
        dt.value.pivot.evolutions.push(resp.data)
      }

      if (finalize) {
        const tmp = JSON.parse(JSON.stringify(tmpProcedure.value))
        tmp.pivot.tooth_face_id = JSON.parse(tmp.pivot.tooth_face_id)
        tmp.pivot.status = 'FINISHED'
        save(tmp)
        tmpProcedure.value = {}
      }

      orderEvolutions(dt.value)
      emit('refresh', userData.value)
      showToast()
    } catch (e) {
      catchErr(e)
    }
  }
  const handleNewProcedureShowModal = ref(false)
  const handleNewProcedureShow = () => {
    handleNewProcedureShowModal.value = true
  }

  const handleObservationShowModal = ref(false)
  const handleObservationShow = data => {
    if (!data.item.pivot.deleted_at) {
      handleObservationShowModal.value = true
      procedureData.value = data.item
    }
  }

  const handleEditShowModal = ref(false)
  const handleEdit = data => {
    if (!data.item.pivot.deleted_at) {
      handleEditShowModal.value = true
      procedureData.value = data.item
    }
  }

  const showListModal = ref(false)
  const showActionModal = ref(false)
  const evolution = ref({})

  const addEvolution = procedure => {
    evolution.value = {
      evolution_type: 'EVOLUTION', // ATTENDANCE - PLANNING - EVOLUTION
      patient_id: procedure.pivot.user_id || null,
      procedure_uuid: procedure.pivot.uuid || null,
    }
    showActionModal.value = true
  }

  const handleEvolution = (dta, showList = false) => {
    evolution.value = dta
    if (showList) showListModal.value = true
    else showActionModal.value = true
  }

  const handleFinalization = procedure => {
    finalizing.value = true
    tmpProcedure.value = procedure
    addEvolution(procedure)
  }

  const handleEvolutions = dta => {
    orderEvolutions(dta)
    showListModal.value = true
  }

  const cancelEvolution = async (evolut, force = null) => {
    try {
      const resp = await store.dispatch('store-treatment-procedure/deleteEvolution', { evolut, force })
      const idx = userData.value.procedures.findIndex(e => e.pivot.uuid === evolut.procedure_uuid)
      const idx2 = userData.value.procedures[idx].pivot.evolutions.findIndex(e => e.id === evolut.id)
      if (idx >= 0 && force) {
        userData.value.procedures[idx].pivot.evolutions.splice(idx2, 1)
        dt.value.pivot.evolutions.splice(idx2, 1)
      } else if (idx >= 0) {
        userData.value.procedures[idx].pivot.evolutions.splice(idx2, 1, resp.data)
        dt.value.pivot.evolutions.splice(idx2, 1, resp.data)
      }
      showToast('success', 'CheckIcon', 'Sucesso', 'Ação Executada com Sucesso.')
    } catch (e) {
      catchErr(e)
    }
  }

  const reactivateEvolution = async evolut => {
    try {
      const resp = await store.dispatch('store-treatment-procedure/reactiveEvolution', evolut)
      const idx = userData.value.procedures.findIndex(e => e.pivot.uuid === evolut.procedure_uuid)
      const idx2 = userData.value.procedures[idx].pivot.evolutions.findIndex(e => e.id === evolut.id)
      if (idx >= 0) {
        userData.value.procedures[idx].pivot.evolutions.splice(idx2, 1, resp.data)
        dt.value.pivot.evolutions.splice(idx2, 1, resp.data)
      }
      showToast('success', 'CheckIcon', 'Sucesso', 'Dados Alterados com Sucesso.')
    } catch (e) {
      catchErr(e)
    }
  }

  const showLoading = ref(false)
  const showPrintModal = ref(false)
  const srcPdf = ref('')

  // eslint-disable-next-line no-unused-vars
  const print = async data => {
    showPrintModal.value = true
    // showLoading.value = true
    // const pdf = await axios.post(`${process.env.VUE_APP_API_URL}/pdf/print-treatment-evolutions`, data)
    // showLoading.value = false
    // srcPdf.value = `data:application/pdf;base64,${pdf.data}`
  }

  const cancelData = async (data, force = null) => {
    try {
      const resp = await store.dispatch('store-treatment-procedure/delete', { data, force })
      const idx = userData.value.procedures.findIndex(e => e.pivot.uuid === data.pivot.uuid)
      if (idx >= 0 && force) {
        userData.value.procedures.splice(idx, 1)
      } else if (idx >= 0) {
        userData.value.procedures.splice(idx, 1, resp.data)
      }

      showToast('success', 'CheckIcon', 'Sucesso', 'Dados Excluídos com Sucesso.')
    } catch (e) {
      catchErr(e)
    }
  }

  const reactiveData = async data => {
    try {
      const resp = await store.dispatch('store-treatment-procedure/reactive', data)
      const idx = userData.value.procedures.findIndex(e => e.pivot.uuid === data.pivot.uuid)
      if (idx >= 0) {
        userData.value.procedures.splice(idx, 1, resp.data)
      }
      showToast('success', 'CheckIcon', 'Sucesso', 'Dados Alterados com Sucesso.')
    } catch (e) {
      catchErr(e)
    }
  }

  const printEvolution = pdfValue => {
    srcPdf.value = pdfValue
    showPrintModal.value = true
  }

  const locale = ref('pt-BR')

  const locales = ref([
    { value: 'pt-BR', text: 'Portuguese BR (pt-BR)' },
    { value: 'en-US', text: 'English US (en-US)' },
  ])

  const labels = {
    'pt-BR': {
      labelPrevDecade: 'Década Anterior',
      labelPrevYear: 'Ano Anterior',
      labelPrevMonth: 'Mês Anterior',
      labelCurrentMonth: 'Mês Atual',
      labelNextMonth: 'Próximo Mês',
      labelNextYear: 'Próximo Ano',
      labelNextDecade: 'Próxima Década',
      labelToday: 'Hoje',
      labelSelected: 'Data Selecionada',
      labelNoDateSelected: 'Nenhuma Data Selecionada',
      labelCalendar: 'Calendário',
      labelNav: 'Navegação no Calendário',
      labelHelp: 'Use as setas do teclado para navegar',
    },
  }

  const rowClass = (item, type) => {
    if (item && (type === 'row') && item.pivot.deleted_at) return 'strikeout'
    return null
  }

  const notFinishedProcedures = computed(() => userData.value.procedures.filter(e => e.pivot.status === 'NOT_FINISHED' && !e.pivot.evolutions.length))
  const proceduresInEvolution = computed(() => userData.value.procedures.filter(e => e.pivot.status === 'NOT_FINISHED' && e.pivot.evolutions.length))
  const finishedProcedures = computed(() => userData.value.procedures.filter(e => e.pivot.status === 'FINISHED'))

  return {
    tableColumns,
    showPrintModal,
    showLoading,
    srcPdf,
    handleObservationShowModal,
    handleNewProcedureShowModal,
    handleEditShowModal,
    procedureData,
    notFinishedProcedures,
    proceduresInEvolution,
    finishedProcedures,
    showListModal,
    showActionModal,
    dt,
    locale,
    locales,
    labels,
    evolution,
    procedures,
    finalizing,

    addEvolution,
    handleEvolution,
    handleFinalization,
    handleEvolutions,
    handleObservationShow,
    handleNewProcedureShow,
    handleEdit,
    add,
    save,
    saveEvolution,
    print,
    cancelData,
    cancelEvolution,
    reactivateEvolution,
    reactiveData,
    catchErr,
    printEvolution,
    rowClass,
  }
}
