<template>
  <div>
    <b-modal
      id="patient-anamnese-modal"
      v-model="showLocal"
      centered
      size="lg"
      title="Alertas da Anamnese"
      hide-footer
      @hidden="hidden"
    >
      <b-form>
        <div>
          <b-list-group>
            <b-list-group-item
              v-if="!filteredQuestions.length"
              class="text-center text-muted"
            >
              Não há nenhuma questão marcada com alesta no momento
            </b-list-group-item>
            <b-list-group-item
              v-for="question in filteredQuestions"
              :key="question.id"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="2"
                  class="border-right d-flex align-items-center justify-content-center"
                >
                  <feather-icon
                    icon="AlertTriangleIcon"
                    size="19"
                    class="text-warning"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="7"
                  class="align-self-center border-right"
                  style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                >
                  <span
                    :id="`question-${question.id}`"
                  >
                    {{ question.question }}
                  </span>
                  <b-tooltip
                    v-if="question.question.length > 45"
                    :target="`question-${question.id}`"
                    noninteractive
                    :delay="delay"
                  >
                    {{ question.question }}
                  </b-tooltip>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  class="d-flex align-self-center justify-content-center"
                >
                  <span
                    :id="`question-answer-${question.id}`"
                    style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                  >
                    <span v-if="question.type !== 'RADIO_TEXT'">
                      {{ question.text_answer ? question.text_answer : question.radio_answer ? 'Sim' : question.radio_answer === 0 ? 'Não' : 'Sem resposta' }}
                    </span>
                    <span v-else>
                      {{ question.radio_answer ? 'Sim' : question.radio_answer === 0 ? 'Não' : 'Sem resposta' }}
                      <br>
                      {{ question.text_answer ? question.text_answer : 'Sem descrição' }}
                    </span>
                  </span>
                  <b-tooltip
                    v-if="question.text_answer && question.text_answer.length > 10"
                    :target="`question-answer-${question.id}`"
                    noninteractive
                    :delay="delay"
                  >
                    {{ question.text_answer ? question.text_answer : question.radio_answer ? 'Sim' : !question.radio_answer ? 'Não' : 'Sem resposta' }}
                  </b-tooltip>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </div>

        <!-- Footer -->
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="8"
          />

          <b-col
            cols="12"
            md="4"
          >
            <b-button
              variant="primary"
              block
              @click="hidden"
            >
              Ok
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm, BRow, BCol, BButton, BListGroup, BListGroupItem, BTooltip,
} from 'bootstrap-vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import usePatientAnamneseModal from './usePatientAnamneseModal'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BListGroup,
    BListGroupItem,
    BTooltip,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    userData: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const {
      delay,
      filteredQuestions,
      showLocal,
      activeOptions,
      subStatusOptions,
      patientStatus,
      patientSubStatus,
      hidden,
      confirmStatusChange,
    } = usePatientAnamneseModal(emit, props)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      delay,
      filteredQuestions,
      showLocal,
      activeOptions,
      subStatusOptions,
      patientStatus,
      patientSubStatus,
      hidden,
      confirmStatusChange,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
