<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mb-1">Exibir</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50 mb-1"
            />
            <label class="mb-1">resultados</label>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-checkbox-group
              v-model="filter"
              :options="filterOptions"
              class="d-inline-block mr-1 mb-1"
              value-field="item"
              text-field="name"
              disabled-field="notEnabled"
            />
          </b-col>

          <!-- Imprimir PDF exportar Excel -->
          <b-col
            class="text-right"
            cols="12"
            md="2"
          >
            <export-print
              :show-pdf-modal="showPdfModal"
              :pdf-base64="base64Data"
              @pdfClick="printPdf"
              @hidden="showPdfModal = false"
            />
          </b-col>

        </b-row>

      </div>

      <b-overlay
        :show="showOverlay"
        rounded="sm"
        no-fade
      >
        <b-table
          ref="refFinancesBillsInListTable"
          class="position-relative tableMinHeght"
          :items="fetch"
          :tbody-tr-class="rowClass"
          small
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Nenhum resultado encontrado"
          :sort-desc.sync="isSortDirDesc"
          select-mode="multi"
          no-sort-reset
        >

          <!-- Colunm: Vencimento -->
          <template #cell(expiration_date)="data">
            {{ data.item.expiration_date | dateTime('date') }}
          </template>

          <!-- Colunm: Descrição -->
          <template #cell(description)="data">
            Parcela {{ data.item.installment }} {{ data.item.origin === 'MONTHLY_PAYMENT' ? 'de mensalidade' : '' }} {{ data.item.description && ' | ' + data.item.description }}
          </template>

        </b-table>
      </b-overlay>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalData"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BPagination, BFormCheckboxGroup, BOverlay,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import {
  toRefs, computed, onUnmounted, ref,
} from '@vue/composition-api'
import axios from '@axios'
import store from '@/store'
import financeStoreModule from '@/views/apps/finances/financeStoreModule'
import accountPlanStoreModule from '@/views/apps/finances/account-plans/useStoreAccountPlans'
import dentalPlanStoreModule from '@/views/apps/dental-plans/useStoreDentalPlans'
import useFinance from './useFinance'
import exportPrint from './exportPrint.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BFormCheckboxGroup,
    vSelect,
    exportPrint,
    BOverlay,
  },

  props: {
    userData: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const USER_APP_STORE_MODULE_NAME = 'store-finance'
    const USER_APP_STORE_MODULE2_NAME = 'store-account-plan'
    const USER_APP_STORE_MODULE3_NAME = 'store-dental-plan'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, financeStoreModule)
    if (!store.hasModule(USER_APP_STORE_MODULE2_NAME)) store.registerModule(USER_APP_STORE_MODULE2_NAME, accountPlanStoreModule)
    if (!store.hasModule(USER_APP_STORE_MODULE3_NAME)) store.registerModule(USER_APP_STORE_MODULE3_NAME, dentalPlanStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE2_NAME)) store.unregisterModule(USER_APP_STORE_MODULE2_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE3_NAME)) store.unregisterModule(USER_APP_STORE_MODULE3_NAME)
    })
    store.dispatch('store-account-plan/fetch')
    store.dispatch('store-dental-plan/fetch')
    const accountPlans = computed(() => store.getters['store-account-plan/forSelect']('1 - RECEITAS'))
    const dentalPlans = computed(() => store.getters['store-dental-plan/forSelect'])
    const layoutSkin = computed(() => store.state.appConfig.layout.skin)

    const { userData } = toRefs(props)

    const showPdfModal = ref(false)
    const base64Data = ref('')
    const printPdf = async () => {
      try {
        const pdf = await axios.post(`${process.env.VUE_APP_API_URL}/pdf/print-extract`, { user: userData.value || null })
        base64Data.value = pdf.data
      } catch (error) {
        // console.error(error)
      }
      showPdfModal.value = true
    }

    const rowClass = (item, type) => {
      if (item && (type === 'row') && item.deleted_at) return 'strikeout'
      if (item && (type === 'row') && item.situation === 'EXPIRED') return layoutSkin.value === 'light' ? 'expired' : 'expired-dark'
      return null
    }

    const {
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      filter,
      filterOptions,
      sortBy,
      isSortDirDesc,
      refFinancesBillsInListTable,
      showOverlay,

      fetch,
      refetchData,
    } = useFinance(userData.value)

    return {
      accountPlans,
      dentalPlans,
      base64Data,
      showPdfModal,

      printPdf,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      filter,
      filterOptions,
      sortBy,
      isSortDirDesc,
      refFinancesBillsInListTable,
      showOverlay,
      refetchData,
      rowClass,
      fetch,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
      // resolve a cor do overlay
      .dark-layout {
  div ::v-deep {
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
  }
}
</style>
