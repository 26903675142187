import Vue from 'vue'
import axios from '@axios'

export default {
  namespaced: true,

  state: {
    documentTemplate: {},
    documentTemplates: [],
    alldocumentTemplates: [], // forSelect tenho as 2 situações (documentTemplates e alldocumentTemplates) na mesma página/componente
  },

  getters: {
    forSelect(state) {
      const data = []
      if (Array.isArray(state.alldocumentTemplates)) {
        // eslint-disable-next-line array-callback-return
        state.alldocumentTemplates.map(e => {
          data.push({
            value: e.id,
            label: e.name,
            data: e,
          })
        })
      }
      return data
    },
  },

  mutations: {
    SET(state, data) {
      state.documentTemplate = data
    },

    SET_LIST(state, data) {
      state.documentTemplates = data
    },

    SET_ALL(state, data) {
      state.alldocumentTemplates = data
    },

    ADD_IN_LIST(state, data) {
      if (Array.isArray(state.documentTemplates)) state.documentTemplates.push(data)
      if (Array.isArray(state.alldocumentTemplates)) state.alldocumentTemplates.push(data)
    },

    UPDATE_IN_LIST(state, data) {
      if (Array.isArray(state.documentTemplates)) {
        const idx = state.documentTemplates.findIndex(o => o.id === data.id)
        if (idx >= 0) Vue.set(state.documentTemplates, idx, data)
      }
      if (Array.isArray(state.alldocumentTemplates)) {
        const idxAll = state.alldocumentTemplates.findIndex(o => o.id === data.id)
        if (idxAll >= 0) Vue.set(state.alldocumentTemplates, idxAll, data)
      }
    },

    DELETE_REGISTER(state, payload) {
      const idx = state.documentTemplates.data.findIndex(o => o.id === payload)
      if (idx >= 0) {
        state.documentTemplates.data.splice(idx, 1)
        state.documentTemplates.total -= 1
      }
      if (Array.isArray(state.documentTemplates)) {
        const idx2 = state.documentTemplates.findIndex(o => o.id === payload)
        if (idx2 >= 0) {
          state.documentTemplates.splice(idx, 1)
        }
      }
      if (Array.isArray(state.alldocumentTemplates)) {
        const idxAll = state.documentTemplates.findIndex(o => o.id === payload)
        if (idxAll >= 0) {
          state.alldocumentTemplates.splice(idx, 1)
        }
      }
      if (state.documentTemplate && state.documentTemplate.id === payload) {
        state.documentTemplate = {}
      }
    },
  },

  actions: {
    /**
     * TODO melhorar isso. É possível ter tudo somente em fetch
     * ESTA AQUI SERVE PARA AS LISTAS
     */
    async fetch({ state, commit }, args) {
      try {
        if (!state.documentTemplates.length || args) {
          const resp = await axios.get(`${process.env.VUE_APP_API_URL}/document-templates${args ? `?${new URLSearchParams(args).toString()}` : '/'}`)
          commit('SET_LIST', resp.data)
          return resp
        }
        return state.documentTemplates
      } catch (e) {
        return e
      }
    },

    /**
     * TODO melhorar isso. É possível ter tudo somente em fetch
     * ESTA AQUI SERVE PARA OS DROPDOWNS
     * @param {*} param
     * @param {*} args
     * @returns
     */
    // async fetchAll({ state, commit }, args) {
    //   try {
    //     if (!state.users.length || args) {
    //       const resp = await axios.get(`${process.env.VUE_APP_API_URL}/users${args ? `?${new URLSearchParams(args).toString()}` : '/'}`)
    //       commit('SET_ALL', resp.data)
    //       return resp
    //     }
    //     return state.allUsers
    //   } catch (e) {
    //     return e
    //   }
    // },

    // fetchOne({ commit }, id) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get(`${process.env.VUE_APP_API_URL}/document-template/${id}`)
    //       .then(response => {
    //         commit('SET', response.data)
    //         commit('UPDATE_IN_LIST', response.data)
    //         return resolve(response)
    //       })
    //       .catch(error => reject(error))
    //   })
    // },

    async delete({ commit }, data) {
      try {
        const resp = await axios.delete(`${process.env.VUE_APP_API_URL}/document-template/${data.id}`)
        commit('DELETE_REGISTER', data)
        return resp
      } catch (e) {
        return e
      }
    },

    async reactive({ commit }, data) {
      try {
        const resp = await axios.put(`${process.env.VUE_APP_API_URL}/document-template/reactive/${data.id}`)
        commit('UPDATE_IN_LIST', data)
        return resp
      } catch (e) {
        return e
      }
    },

    add({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/document-template`, data)
          .then(response => {
            commit('SET', response.data)
            commit('ADD_IN_LIST', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    update({ commit }, data) {
      const handleUserData = JSON.parse(JSON.stringify(data))
      handleUserData.photo = null // É NECESSÁRIO REMOVER DADOS DE PHOTO POIS A MESMA É ALTERADA EM MÉTODO PRÓPRIO
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/document-template/${data.id}`, handleUserData)
          .then(response => {
            commit('SET', response.data)
            commit('UPDATE_IN_LIST', response.data)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
