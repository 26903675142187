<template>

  <div>

    <!-- importar modal add installments -->
    <add-modal
      :show="addModal"
      :dental-plans="dentalPlans"
      :account-plans="accountPlans"
      @save="add"
      @hidden="addModal = false"
    />

    <!-- importar modal edit installment -->
    <edit-modal
      :show="editModal"
      :dental-plans="dentalPlans"
      :account-plans="accountPlans"
      :scheduled-transaction="scheduledTransaction"
      @save="edit"
      @hidden="editModal = false"
    />

    <!-- importar modal delete -->
    <delete-modal
      :show="deleteModal"
      :scheduled-transaction="scheduledTransaction"
      @save="deleteData"
      @hidden="deleteModal = false"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mb-1">Exibir</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50 mb-1"
            />
            <label class="mb-1">resultados</label>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
          <!--  -->
          </b-col>

          <!-- Adicionar Parcelas -->
          <b-col
            cols="12"
            md="2"
          >
          <!--  -->
          </b-col>

          <!-- Reajustar em Lote -->
          <b-col
            cols="12"
            md="4"
          >
            <b-button
              variant="primary"
              class="mb-1"
              block
              @click="addModal = true"
            >
              <span>Adicionar</span>
            </b-button>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refFinancesScheduleTransactionsTable"
        class="position-relative tableMinHeght"
        :items="fetch"
        :tbody-tr-class="rowClass"
        small
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum resultado encontrado"
        :sort-desc.sync="isSortDirDesc"
        no-sort-reset
      >

        <!-- Colunm: A partir de -->
        <template #cell(initial_date)="data">
          {{ data.item.initial_date | dateTime('date') }}
        </template>

        <!-- Colunm: Até -->
        <template #cell(final_date)="data">
          <span v-if="data.item.final_date">{{ data.item.final_date | dateTime('date') }}</span>
          <span v-else>Indefinido</span>
        </template>

        <!-- Colunm: Descrição -->
        <template #cell(description)="data">
          {{ data.item.description }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="!data.item.deleted_at"
              @click="handleEdit(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Alterar</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="!data.item.deleted_at"
              @click="handleDelete(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Cancelar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalData"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import {
  toRefs, ref, computed, onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import accountPlanStoreModule from '@/views/apps/finances/account-plans/useStoreAccountPlans'
import financeStoreModule from '@/views/apps/finances/financeStoreModule'
import dentalPlanStoreModule from '@/views/apps/dental-plans/useStoreDentalPlans'
import scheduledTransactionModule from '@/views/apps/patients/edit/finances/scheduled-transactions/scheduledTransactionModule'
import useMonthlyPayment from './useScheduledTransactions'
import AddModal from './AddModal.vue'
import EditModal from './EditModal.vue'
import DeleteModal from './DeleteModal.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AddModal,
    EditModal,
    DeleteModal,
  },

  props: {
    userData: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const USER_APP_STORE_MODULE_NAME = 'store-finance'
    const USER_APP_STORE_MODULE2_NAME = 'store-account-plan'
    const USER_APP_STORE_MODULE3_NAME = 'store-dental-plan'
    const USER_APP_STORE_MODULE4_NAME = 'store-scheduled-transaction'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, financeStoreModule)
    if (!store.hasModule(USER_APP_STORE_MODULE2_NAME)) store.registerModule(USER_APP_STORE_MODULE2_NAME, accountPlanStoreModule)
    if (!store.hasModule(USER_APP_STORE_MODULE3_NAME)) store.registerModule(USER_APP_STORE_MODULE3_NAME, dentalPlanStoreModule)
    if (!store.hasModule(USER_APP_STORE_MODULE4_NAME)) store.registerModule(USER_APP_STORE_MODULE4_NAME, scheduledTransactionModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE2_NAME)) store.unregisterModule(USER_APP_STORE_MODULE2_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE3_NAME)) store.unregisterModule(USER_APP_STORE_MODULE3_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE4_NAME)) store.unregisterModule(USER_APP_STORE_MODULE4_NAME)
    })

    store.dispatch('store-account-plan/fetch')
    store.dispatch('store-dental-plan/fetch')
    const accountPlans = computed(() => store.getters['store-account-plan/forSelect']('1 - RECEITAS'))
    const dentalPlans = computed(() => store.getters['store-dental-plan/forSelect'])

    const rowClass = (item, type) => {
      if (item && (type === 'row') && item.deleted_at) return 'strikeout'
      return null
    }

    const { userData } = toRefs(props)
    const scheduledTransaction = ref({ })

    const {
      addModal,
      editModal,
      deleteModal,
      transaction,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      filter,
      filterOptions,
      sortBy,
      isSortDirDesc,
      refFinancesScheduleTransactionsTable,
      add,
      edit,
      fetch,
      deleteData,
    } = useMonthlyPayment(userData.value)

    const handleEdit = data => {
      editModal.value = true
      const handledData = JSON.parse(JSON.stringify(data))
      handledData.initial_date = data.initial_date.substring(0, 7)
      handledData.final_date = data.final_date ? data.final_date.substring(0, 7) : ''
      scheduledTransaction.value = handledData
    }

    const handleDelete = data => {
      scheduledTransaction.value = JSON.parse(JSON.stringify(data))
      deleteModal.value = true
    }

    return {
      addModal,
      editModal,
      deleteModal,
      transaction,
      accountPlans,
      dentalPlans,
      scheduledTransaction,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      filter,
      filterOptions,
      sortBy,
      isSortDirDesc,
      refFinancesScheduleTransactionsTable,
      add,
      edit,
      fetch,
      handleEdit,
      handleDelete,
      deleteData,
      rowClass,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
