<template>
  <div>

    <add-treatments
      :show="importProceduresShowModal"
      @hidden="importProceduresShowModal = false"
    />

    <b-sidebar
      id="add-new-user-sidebar"
      :visible="isAddNewUserSidebarActive"
      :sidebar-class="{
        'expanded-menu': !isVerticalMenuCollapsed,
        'retracted-menu': isVerticalMenuCollapsed,
        'no-xl' : currentBreakPoint && currentBreakPoint !== 'xl'
      }"
      bg-variant="white"
      no-header
      z-index="10"
      right
      @hidden="resetBudgetData"
      @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
    >

      <template #default="{ hide }">
        <div
          style="padding-top: 85px"
          class="d-flex content-sidebar-header"
        >
          <feather-icon
            class="cursor-pointer m-1"
            icon="ArrowLeftIcon"
            size="25"
            @click="hide"
          />
        </div>

        <div class="d-flex justify-content-between align-items-center px-2 py-1">
          <h5 class="mb-0">
            {{ isEditing ? 'Editar Orçamento' : 'Novo Orçamento' }}
          </h5>
        </div>

        <!-- Tabs -->
        <b-tabs
          v-model="tabIndex"
          class="ml-1"
        >
          <!-- ========== General Tab ========== -->
          <b-tab>
            <template #title>
              <feather-icon
                icon="InfoIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Geral</span>
            </template>
            <budget-general
              ref="refBudgetGeneral"
              :budget-data="budgetData"
              :user-data="userData"
              @closeSideBar="$emit('update:is-add-new-user-sidebar-active', false)"
              @budgetData="dataToFinances"
              @editingBudgetValue="clearInstallments"
              @refetchBudgets="refetchBudgets"
            />
          </b-tab>

          <!-- ========== Finances Tab ========== -->
          <b-tab
            :disabled="(!budgetData.budget.subtotal ? true : false)"
          >
            <template #title>
              <feather-icon
                icon="DollarSignIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Financeiro</span>
            </template>
            <budget-finances
              ref="refBudgetFinances"
              :budget-data="budgetData"
              @closeSideBar="$emit('update:is-add-new-user-sidebar-active', false)"
              @refetchBudgets="refetchBudgets"
            />
          </b-tab>

          <!-- ========== History Tab ========== -->
          <b-tab
            :disabled="(!budgetData.budget.id ? true : false)"
          >
            <template #title>
              <feather-icon
                icon="FileTextIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Histórico</span>
            </template>
            <budget-history
              ref="refBudgetHistory"
            />
          </b-tab>
        </b-tabs>
      </template>

    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BTabs, BTab,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import addTreatments from '@/views/components/add-treatments/addTreatments.vue'
import BudgetHistory from '@/views/components/budget/history/BudgetHistory.vue'
import BudgetFinances from '@/views/components/budget/finances/BudgetFinances.vue'
import BudgetGeneral from '@/views/components/budget/general/BudgetGeneral.vue'

export default {
  components: {
    BSidebar,
    BTabs,
    BTab,

    BudgetHistory,
    BudgetFinances,
    BudgetGeneral,

    addTreatments,
  },

  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },

  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isVerticalMenuCollapsed() {
      return this.$store.state.verticalMenu.isVerticalMenuCollapsed
    },
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
  },
  setup(props, { emit }) {
    const valueMode = ref('remove')
    const isEditing = ref(false)

    const changeValueMode = () => {
      if (valueMode.value === 'add') {
        valueMode.value = 'remove'
      } else if (valueMode.value === 'remove') {
        valueMode.value = 'add'
      }
    }

    const importProceduresShowModal = ref(false)

    const importProcedures = () => {
      importProceduresShowModal.value = true
    }

    const teethTypes = ref([
      { label: 'Misto' },
      { label: 'Dentes Permanentes' },
      { label: 'Dentes de Tecido' },
    ])

    // const aprooval = ref(false)

    // const aproovalMethod = () => {
    //   console.log(aprooval.value)
    //   if (aprooval.value === false) {
    //     aprooval.value = true
    //   } else if (aprooval.value === true) {
    //     aprooval.value = false
    //   }
    // }

    const budgetData = ref({ budget: {}, totalCost: 0 })
    const tabIndex = ref(0)

    const historyData = ref([])
    const dataToHistory = budget => {
      historyData.value = budget
    }

    const refBudgetGeneral = ref(null)
    const refBudgetFinances = ref(null)
    const refBudgetHistory = ref(null)

    const dataToFinances = (budget, totalAfterDiscount) => {
      budgetData.value.budget = budget
      budgetData.value.totalCost = totalAfterDiscount
      dataToHistory([budget])
      setTimeout(() => {
        tabIndex.value = 1 // Abre a aba financeiro
      }, 100) // timeout created to wait budgetData update before changing tabs
    }

    const clearInstallments = (budget, totalAfterDiscount) => {
      refBudgetFinances.value.fillData(JSON.parse(JSON.stringify(Object.assign([]))))
      dataToFinances(budget, totalAfterDiscount)
    }

    const refetchBudgets = () => {
      tabIndex.value = 0 // Abre a aba geral
      emit('refetchData')
    }

    const resetBudgetData = () => {
      refBudgetGeneral.value.resetData()
      refBudgetFinances.value.resetData()
      budgetData.value = { budget: {}, totalCost: 0 }
      isEditing.value = false
      tabIndex.value = 0
    }

    const fillData = data => {
      isEditing.value = true
      budgetData.value.budget = JSON.parse(JSON.stringify(Object.assign(data)))
      refBudgetGeneral.value.fillData(JSON.parse(JSON.stringify(Object.assign(data))))
      refBudgetFinances.value.fillData(JSON.parse(JSON.stringify(Object.assign(data.installments))))
      refBudgetHistory.value.fillData(JSON.parse(JSON.stringify(Object.assign(data.logs))))
    }

    return {
      tabIndex,
      valueMode,
      isEditing,
      budgetData,
      historyData,
      importProceduresShowModal,
      teethTypes,
      // aprooval,
      refBudgetGeneral,
      refBudgetFinances,
      refBudgetHistory,

      changeValueMode,
      importProcedures,
      dataToFinances,
      clearInstallments,
      dataToHistory,
      // aproovalMethod,
      refetchBudgets,
      resetBudgetData,
      fillData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.expanded-menu {
  width: calc(100% - 288px);
  }

.retracted-menu {
  width: calc(100% - 108px);
  }

.no-xl {
  width: calc(100% - 28px);
}
</style>
