<template>
  <div>
    <div>
      <template>
        <component :is="userData === undefined ? 'div' : 'b-card'">

          <b-tabs
            v-if="userData"
            pills
          >

            <!-- Tab: Extrato de Contas -->
            <b-tab active>
              <template #title>
                <feather-icon
                  icon=""
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Extrato</span>
              </template>
              <user-edit-tab-finances-installments
                ref="refFinancesInstallmentsTab"
                :user-data="userData"
                class="mt-2 pt-75"
              />
            </b-tab>

            <!-- Tab: Orçamento -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon=""
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Orçamento</span>
              </template>
              <user-edit-tab-finances-budget
                :user-data="userData"
                class="mt-2 pt-75"
                @refetchData="refetchData"
              />
            </b-tab>

            <!-- Tab: Mensalidades -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon=""
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Mensalidades</span>
              </template>
              <user-edit-tab-finances-monthly-payment
                :user-data="userData"
                class="mt-2 pt-75"
                @action="refetchData"
              />
            </b-tab>

            <!-- Tab: Mensalidades programadas -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon=""
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Mensalidades Programadas</span>
              </template>
              <user-edit-tab-finances-scheduled-transactions
                :user-data="userData"
                class="mt-2 pt-75"
              />
            </b-tab>

          </b-tabs>
        </component>
      </template>
    </div>

    <!-- Action Buttons
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Salvar Alterações
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Cancelar
    </b-button> -->

  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BCard, BTabs, BTab,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import UserEditTabFinancesInstallments from './installments/UserEditTabFinancesInstallments.vue'
import UserEditTabFinancesScheduledTransactions from './scheduled-transactions/UserEditTabFinancesScheduledTransactions.vue'
import UserEditTabFinancesBudget from './budget/UserEditTabFinancesBudget.vue'
import UserEditTabFinancesMonthlyPayment from './monthly-payment/UserEditTabFinancesMonthlyPayment.vue'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BTabs,
    BTab,
    vSelect,

    UserEditTabFinancesInstallments,
    UserEditTabFinancesBudget,
    UserEditTabFinancesMonthlyPayment,
    UserEditTabFinancesScheduledTransactions,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(ctx, { emit }) {
    const refFinancesInstallmentsTab = ref(null)
    const refetchData = () => {
      refFinancesInstallmentsTab.value.$refs.refFinancesBillsInListTable.refresh()
      emit('refetchData')
    }

    return {
      refFinancesInstallmentsTab,
      refetchData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
