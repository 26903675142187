<template>

  <div>

    <!-- importar modal add installments -->
    <add-installments-modal
      :show="addInstallmentsModal"
      :dental-plans="dentalPlans"
      :account-plans="accountPlans"
      @save="saveInstallments"
      @hidden="addInstallmentsModal = false"
    />

    <!-- importar modal change installments -->
    <change-installments-modal
      :show="changeInstallmentsModal"
      :installments="JSON.parse(JSON.stringify(selected))"
      @save="changeInstallments"
      @hidden="changeInstallmentsModal = false"
    />

    <!-- importar modal readjust in batch -->
    <readjust-installments-modal
      :show="readjustInstallmentsModal"
      :installments="JSON.parse(JSON.stringify(selected))"
      @save="readjustInstallments"
      @hidden="readjustInstallmentsModal = false"
    />

    <!-- importar modal edit installment -->
    <edit-installment-modal
      :show="editInstallmentModal"
      :dental-plans="dentalPlans"
      :account-plans="accountPlans"
      :installment="installment"
      @save="editInstallment"
      @hidden="editInstallmentModal = false"
    />

    <!-- importar modal delete -->
    <delete-modal
      :show="deleteModal"
      :transaction="installment"
      @save="deleteData"
      @hidden="deleteModal = false"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mb-1">Exibir</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50 mb-1"
            />
            <label class="mb-1">resultados</label>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-checkbox-group
              v-model="filter"
              :options="filterOptions"
              class="d-inline-block mr-1 mb-1"
              value-field="item"
              text-field="name"
              disabled-field="notEnabled"
            />
          </b-col>

          <!-- Adicionar Parcelas -->
          <b-col
            cols="12"
            md="2"
          >
            <b-button
              variant="primary"
              class="mb-1"
              block
              @click="addInstallmentsModal = true"
            >
              <span>Adicionar Parcelas</span>
            </b-button>
          </b-col>

          <!-- Alterar Parcelas -->
          <b-col
            cols="12"
            md="2"
          >
            <b-button
              variant="primary"
              class="mb-1"
              block
              @click="showChangeInstallmentsModal"
            >
              <span>Alterar Parcelas</span>
            </b-button>
          </b-col>

          <!-- Reajustar em Lote -->
          <b-col
            cols="12"
            md="2"
          >
            <b-button
              variant="primary"
              class="mb-1"
              block
              @click="readjustInstallmentsModal = true"
            >
              <span>Reajustar em Lote</span>
            </b-button>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refFinancesMonthlyPaymentTable"
        class="position-relative tableMinHeght"
        :items="fetch"
        :tbody-tr-class="rowClass"
        small
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum resultado encontrado"
        :sort-desc.sync="isSortDirDesc"
        select-mode="multi"
        selectable
        no-sort-reset
        @row-selected="onRowSelected"
      >

        <template #cell(selectAll)="{ rowSelected, item }">
          <template v-if="rowSelected && !item.deleted_at">
            <feather-icon
              icon="CheckSquareIcon"
              class="text-primary"
              size="15"
            />
          </template>
          <template v-else>
            <feather-icon
              icon="SquareIcon"
              class="text-primary"
              size="15"
            />
          </template>
        </template>

        <!-- Colunm: Vencimento -->
        <template #cell(expiration_date)="data">
          {{ data.item.expiration_date | dateTime('date') }}
        </template>

        <!-- Colunm: Descrição -->
        <template #cell(description)="data">
          Parcela {{ data.item.installment }}/{{ totalData }} {{ data.item.description && ' | ' + data.item.description }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="!data.item.deleted_at && (data.item.situation === 'NOT_PAID' || data.item.situation === 'EXPIRED')"
              @click="handleEdit(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Alterar</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="!data.item.deleted_at"
              @click="handleDelete(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Cancelar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalData"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BDropdown, BDropdownItem, BPagination, BFormCheckboxGroup,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import {
  toRefs, ref, computed, onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import financeStoreModule from '@/views/apps/finances/financeStoreModule'
import accountPlanStoreModule from '@/views/apps/finances/account-plans/useStoreAccountPlans'
import dentalPlanStoreModule from '@/views/apps/dental-plans/useStoreDentalPlans'
import useMonthlyPayment from './useMonthlyPayment'
import AddInstallmentsModal from './AddInstallmentsModal.vue'
import ChangeInstallmentsModal from './ChangeInstallmentsModal.vue'
import ReadjustInstallmentsModal from './ReadjustInstallmentsModal.vue'
import EditInstallmentModal from './EditInstallmentModal.vue'
import DeleteModal from './DeleteModal.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckboxGroup,
    vSelect,
    AddInstallmentsModal,
    ChangeInstallmentsModal,
    ReadjustInstallmentsModal,
    EditInstallmentModal,
    DeleteModal,
  },

  props: {
    userData: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const USER_APP_STORE_MODULE_NAME = 'store-finance'
    const USER_APP_STORE_MODULE2_NAME = 'store-account-plan'
    const USER_APP_STORE_MODULE3_NAME = 'store-dental-plan'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, financeStoreModule)
    if (!store.hasModule(USER_APP_STORE_MODULE2_NAME)) store.registerModule(USER_APP_STORE_MODULE2_NAME, accountPlanStoreModule)
    if (!store.hasModule(USER_APP_STORE_MODULE3_NAME)) store.registerModule(USER_APP_STORE_MODULE3_NAME, dentalPlanStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE2_NAME)) store.unregisterModule(USER_APP_STORE_MODULE2_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE3_NAME)) store.unregisterModule(USER_APP_STORE_MODULE3_NAME)
    })
    store.dispatch('store-account-plan/fetch')
    store.dispatch('store-dental-plan/fetch')
    const accountPlans = computed(() => store.getters['store-account-plan/forSelect']('1 - RECEITAS'))
    const dentalPlans = computed(() => store.getters['store-dental-plan/forSelect'])
    const layoutSkin = computed(() => store.state.appConfig.layout.skin)

    const rowClass = (item, type) => {
      if (item && (type === 'row') && item.deleted_at) return 'strikeout'
      if (item && (type === 'row') && item.situation === 'EXPIRED') return layoutSkin.value === 'light' ? 'expired' : 'expired-dark'
      return null
    }

    const { userData } = toRefs(props)
    const installment = ref({ })

    const {
      addInstallmentsModal,
      changeInstallmentsModal,
      readjustInstallmentsModal,
      editInstallmentModal,
      deleteModal,
      transaction,
      selected,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      filter,
      filterOptions,
      sortBy,
      isSortDirDesc,
      refFinancesMonthlyPaymentTable,
      saveInstallments,
      changeInstallments,
      readjustInstallments,
      editInstallment,
      fetch,
      showChangeInstallmentsModal,
      onRowSelected,
      deleteData,
    } = useMonthlyPayment(userData.value, emit)

    const handleEdit = data => {
      editInstallmentModal.value = true
      const handledData = {
        id: data.id,
        account_plan_id: data.account_plan_id,
        dental_plan_id: data.dental_plan_id,
        description: data.description,
        expiration_date: data.expiration_date,
        value_installment: data.value_installment,
        origin: data.origin,
      }
      installment.value = handledData
    }

    const handleDelete = data => {
      installment.value = JSON.parse(JSON.stringify(data))
      deleteModal.value = true
    }

    return {
      addInstallmentsModal,
      changeInstallmentsModal,
      readjustInstallmentsModal,
      editInstallmentModal,
      deleteModal,
      transaction,
      selected,
      accountPlans,
      dentalPlans,
      installment,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      filter,
      filterOptions,
      sortBy,
      isSortDirDesc,
      refFinancesMonthlyPaymentTable,
      saveInstallments,
      changeInstallments,
      readjustInstallments,
      editInstallment,
      fetch,
      showChangeInstallmentsModal,
      onRowSelected,
      handleEdit,
      handleDelete,
      rowClass,
      deleteData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
