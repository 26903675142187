<template>
  <div>

    <validation-observer
      #default="{invalid}"
      ref="refFormObserver"
    >
      <b-form>
        <b-row>

          <!-- Field: Nome -->
          <b-col
            cols="12"
            md="4"
          >

            <validation-provider
              #default="{ errors }"
              name="Nome"
              rules="required"
              immediate
            >
              <b-form-group
                label="Nome do responsável"
                label-for="responsible-name"
              >
                <b-form-input
                  id="responsible-name"
                  v-model="userData.responsible.name"
                  autofocus
                  :state="errors.length > 0 ? false : null"
                  trim
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          </b-col>

          <!-- Field: CPF / CNPJ -->
          <b-col
            cols="12"
            md="4"
          >

            <validation-provider
              #default="{ errors }"
              name="CPF"
              rules="cpfCnpj|required"
              immediate
            >
              <b-form-group
                label="CPF"
                label-for="responsible-cpf"
              >
                <b-form-input
                  id="responsible-cpf"
                  v-model="userData.responsible.identification1"
                  v-mask="['###.###.###-##']"
                  autofocus
                  :state="errors.length > 0 ? false : null"
                  trim
                  @keyup="searchPatient"
                />

                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          </b-col>

          <!-- Field: Email -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Email"
              rules="email"
            >
              <b-form-group
                label="Email"
                label-for="responsible-email"
              >
                <b-form-input
                  id="responsible-email"
                  v-model="userData.responsible.email"
                  type="email"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>e-mail inválido</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: RG -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="RG"
              label-for="responsible-rg"
            >
              <b-form-input
                id="responsible-rg"
                v-model="userData.responsible.identification2"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Órgão Emissor-->
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              label="Órgão Emissor"
              label-for="responsible-orgao-emissor-rg-edit"
            >
              <v-select
                v-model="userData.responsible.orgao_emissor_rg"
                :options="orgaoOmissorRgOptions"
                :reduce="val => val.value"
                :clearable="true"
                :searchable="false"
                input-id="responsible-orgao-emissor-rg-edit"
                placeholder="Selecione"
              />

            </b-form-group>
          </b-col>

          <!-- Field: UF / RG -->
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              label="UF / RG"
              label-for="responsible-issuer"
            >
              <v-select
                v-model="userData.responsible.orgao_emissor_rg_uf_id"
                :options="$store.getters['states/getStates']"
                :reduce="val => val.value"
                :clearable="true"
                :searchable="true"
                input-id="responsible-issuer"
                placeholder="Selecione"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Estado Civil -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Estado Civil"
              label-for="responsible-marital-status"
            >
              <v-select
                v-model="userData.responsible.marital_status"
                :options="maritalOptions"
                :reduce="val => val.value"
                :clearable="true"
                :searchable="false"
                input-id="responsible-marital-status"
                placeholder="Selecione"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Birth Date -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group>
              <label for="responsible-birthday">Nascimento</label>
              <small
                class="text-muted ml-1"
              ><i>{{ userData.responsible.birthday | age() }} anos.</i></small>
              <b-form-input
                id="responsible-birthday"
                v-model="userData.responsible.birthday"
                type="date"
                autofocus
                trim
              />

            </b-form-group>
          </b-col>

          <!-- Field: Gênero -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Gênero"
              label-for="responsible-gender"
            >
              <v-select
                v-model="userData.responsible.gender"
                :options="genderOptions"
                :reduce="val => val.value"
                :clearable="false"
                :searchable="false"
                input-id="responsible-gender"
                placeholder="Selecione"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Parentesco -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Parentesco"
              label-for="responsible-kinship"
            >
              <v-select
                v-model="userData.responsible.kinship"
                :options="kinshipOptions"
                :reduce="val => val.value"
                :clearable="false"
                :searchable="false"
                input-id="responsible-kinship"
                placeholder="Selecione"
              />
            </b-form-group>
          </b-col>
          <!-- Field: Profissão -->
          <b-col
            cols="12"
            md="4"
          >

            <validation-provider
              #default="{ errors }"
              name="Profissão"
              immediate
            >
              <b-form-group
                label="Profissão do responsável"
                label-for="responsible-ocupation"
              >
                <b-form-input
                  id="responsible-ocupation"
                  v-model="userData.responsible.ocupation"
                  autofocus
                  :state="errors.length > 0 ? false : null"
                  trim
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          </b-col>
          <!-- Field: naturality -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Naturalidade do responsavel"
              label-for="responsible-naturality"
            >
              <b-form-input
                id="responsible-naturality"
                v-model="userData.responsible.naturality"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Email resp -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="email"
            >
              <b-form-group
                label="Email Responsavel"
                label-for="responsible-email-edit"
              >
                <b-form-input
                  id="responsible-email-edit"
                  v-model="userData.responsible.email"
                  type="email"
                  :state="errors.length > 0 ? false : null"
                  trim
                />

                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Phone Number 1 -->
          <b-col
            cols="12"
            md="4"
          >

            <b-form-group
              label="Celular - Principal"
              label-for="responsible-phone-number"
            >
              <b-form-input
                id="responsible-phone-number"
                v-model="userData.responsible.phoneNumber"
                v-mask="['(##) ####-#####', '(##) # ####-####']"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Phone Number 2 -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Celular"
              label-for="responsible-second-phone-number"
            >
              <b-form-input
                id="responsible-second-phone-number"
                v-model="userData.responsible.secondPhoneNumber"
                v-mask="['(##) ####-#####', '(##) # ####-####']"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Phone Number 3 -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Fixo"
              label-for="responsible-third-phone-number"
            >
              <b-form-input
                id="responsible-third-phone-number"
                v-model="userData.responsible.thirdPhoneNumber"
                v-mask="['(##) ####-#####', '(##) # ####-####']"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="12"
          >
            <hr>
          </b-col>

          <!-- Field: CEP -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="CEP"
              label-for="responsible-zip-code"
            >
              <b-form-input
                id="responsible-zip-code"
                v-model="userData.responsible.address.zip_code"
                v-mask="['#####-###']"
                @keyup="searchZipCode"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Logradouro -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Logradouro"
              label-for="responsible-public-place"
            >
              <v-select
                v-model="userData.responsible.address.public_place"
                :options="publicPlaceOptions"
                :reduce="val => val.value"
                :clearable="true"
                :searchable="true"
                input-id="responsible-public-place"
                placeholder="Selecione"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Endereço -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Endereço"
              label-for="responsible-address"
            >
              <b-form-input
                id="responsible-address"
                v-model="userData.responsible.address.street"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Número -->
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              label="Número"
              label-for="responsible-number"
            >
              <b-form-input
                id="responsible-number"
                v-model="userData.responsible.address.number"
                type="number"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Bairro -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Bairro"
              label-for="responsible-district"
            >
              <b-form-input
                id="responsible-district"
                v-model="userData.responsible.address.neighborhood"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Cidade -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Cidade"
              label-for="responsible-address-city"
            >
              <v-select
                v-model="userData.responsible.address.city_id"
                :reduce="option => option.value"
                :placeholder="cityOptions.length ? 'Selecione' : 'Selecione um estado'"
                :options="cityOptions"
                input-id="responsible-address-city"
              >
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- Field: Estado -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Estado"
              label-for="responsible-address-state"
            >
              <v-select
                v-model="userData.responsible.address.state_id"
                :options="$store.getters['states/getStates']"
                :reduce="val => val.value"
                :clearable="true"
                :searchable="true"
                input-id="responsible-address-state"
                placeholder="Selecione"
                @input="fillCity(userData.address.state_id, null, 'cityOptions'), userData.address.city_id = null"
              >
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- Field: Complemento-->
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Complemento"
              label-for="responsible-complement"
            >
              <b-form-input
                id="responsible-complement"
                v-model="userData.responsible.address.complement"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :disabled="invalid"
          @click="save"
        >
          Salvar Alterações
        </b-button>

        <b-button
          variant="danger"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :disabled="!userData.responsibles || !userData.responsibles.length"
          @click="detachResponsible"
        >
          Desvincular Responsável
        </b-button>

      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mask } from 'vue-the-mask'
import { ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'

import formValidation from '@core/comp-functions/forms/form-validation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormInvalidFeedback,
    vSelect,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    mask,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    localize('pt_BR')
    const showOverlay = false
    const blankUserData = {
      id: null,
      code: null,
      photo: null,
      name: null,
      nickname: null,
      email: null,
      identification1: null,
      identification2: null,
      orgao_emissor_rg: null,
      orgao_emissor_rg_uf_id: null,
      marital_status: null,
      birthday: '',
      gender: 'INDEFINED',
      phoneNumber: null,
      secondPhoneNumber: null,
      thirdPhoneNumber: null,
      observation: null,
      how_did_you_find_us: null, // GOOGLE, FACEBOOK, INSTAGRAM, INDICAÇÃO, FACHADA, TV, RÁDIO, PANFLHETO, OUTRO
      treatment_type: null, // AVALIAÇÃO ORTODONTICA, AVALIAÇÃO CLÍNICA, OUTRO
      reminder: null,
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    const genderOptions = [
      { label: 'Masculino', value: 'MALE' },
      { label: 'Feminino', value: 'FEMALE' },
      { label: 'Indefinido', value: 'UNDEFINED' },
    ]

    const maritalOptions = [
      { label: 'Casado(a)', value: 'MARRIED' },
      { label: 'Convivente', value: 'COHABITANT' },
      { label: 'Solteiro(a)', value: 'SINGLE' },
      { label: 'Viúvo(a)', value: 'WIDOWER' },
      { label: 'Divorciado(a)', value: 'DIVORCED' },
    ]

    const orgaoOmissorRgOptions = [
      { label: 'SSP', value: 'SSP' },
      { label: 'SSPDS', value: 'SSPDS' },
      { label: 'SESP', value: 'SESP' },
      { label: 'SESED', value: 'SESED' },
      { label: 'SESDEC', value: 'SESDEC' },
    ]

    const kinshipOptions = [
      { label: 'Pai', value: 'FATHER' },
      { label: 'Mãe', value: 'MOTHER' },
      { label: 'Parente', value: 'KINSMAN' },
      { label: 'Outro', value: 'OTHER' },
    ]

    const publicPlaceOptions = [
      { label: 'Rua', value: 'Rua' },
      { label: 'Avenida', value: 'Avenida' },
      { label: 'Rodovia', value: 'Rodovia' },
      { label: 'Estrada', value: 'Estrada' },
      { label: 'Lote', value: 'Lote' },
      { label: 'Servidão', value: 'Servidão' },
      { label: 'Quadra', value: 'Quadra' },
      { label: 'Travessa', value: 'Travessa' },
      { label: 'Linha', value: 'Linha' },
    ]

    return {
      genderOptions,
      maritalOptions,
      orgaoOmissorRgOptions,
      kinshipOptions,
      publicPlaceOptions,

      refFormObserver,
      getValidationState,
      resetForm,

      showOverlay,
    }
  },

  data() {
    return {
      cityOptions: [],
    }
  },
  mounted() {
    // Seleciona estado e preenche cidades
    if (this.userData.responsible.address.state_id) {
      if (this.userData.responsible.address.city_id) {
        this.fillCity(this.userData.responsible.address.state_id, this.userData.address.city_id)
      } else {
        this.fillCity(this.userData.responsible.address.state_id, null)
      }
    }
  },

  methods: {
    async save() {
      try {
        this.showOverlay = true
        await this.$store.dispatch('store-patient/saveResponsible', this.userData)
        this.$emit('refreshUserData')
        this.showToast()
        this.showOverlay = false
      } catch (error) {
        this.showOverlay = false
        if (error.response.status === 412) {
          this.showToast('warning', 'AlertCircleIcon', 'Atenção', error.response.data.message)
        } else {
          this.showToast('danger', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
        }
      }
    },

    async detachResponsible() {
      try {
        this.showOverlay = true
        await this.$store.dispatch('store-patient/detachResponsible', this.$store.getters['store-patient/patient'])
        this.$emit('refreshUserData')
        this.showToast()
        this.showOverlay = false
      } catch (error) {
        this.showOverlay = false
        if (error.response.status === 412) {
          this.showToast('warning', 'AlertCircleIcon', 'Atenção', error.response.data.message)
        } else {
          this.showToast('danger', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
        }
      }
    },

    async searchPatient() {
      try {
        const identification = this.userData.responsible.identification1
        if (identification && (identification.length === 14 || identification.length === 18)) {
          const cpfCnpj = identification.replace(/\D/g, '')
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/user/getByCpf/${cpfCnpj}/null,PATIENT`)
          if (!response.data.address) response.data.address = {}
          this.userData.responsible = response.data
        }
      } catch (error) {
        if (error.response.status !== 404) this.showToast('warning', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
      }
    },

    async searchZipCode($event) {
      const zipCode = this.userData.responsible.address.zip_code.trim().replace(/[^0-9]/g, '')

      if (zipCode.length === 8 && ($event.keyCode < 37 || $event.keyCode > 40)) { // $event.keyCode evita chamadas de API ao teclar setas
        axios.noHeaders = true // enviando um get sem Autorization no header
        const resp = await axios.get(`https://viacep.com.br/ws/${zipCode}/json`)
        if (resp.data.erro) {
          this.showToast('warning', 'AlertCircleIcon', 'Atenção', 'O CEP não existe ou não foi encontrado.')
          return
        }

        this.userData.responsible.address.neighborhood = resp.data.bairro
        this.userData.responsible.address.complement = resp.data.complemento

        // abaixo testo a primeira palavra da rua (logradouro), se existir removo da rua e seleciono o logradouro
        const rua = /Rua /i
        const avenida = /Avenida /i
        const rodovia = /Rodovia /i
        const estrada = /Estrada /i
        const lote = /Lote /i
        const servidao = /Servidão /i
        const quadra = /Quadra /i
        const travessa = /Travessa /i
        const linha = /Linha /i

        if (rua.test(resp.data.logradouro)) {
          this.userData.responsible.address.public_place = 'Rua'
          const regEx = new RegExp('Rua ', 'ig')
          this.userData.responsible.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (avenida.test(resp.data.logradouro)) {
          this.userData.responsible.address.public_place = 'Avenida'
          const regEx = new RegExp('Avenida ', 'ig')
          this.userData.responsible.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (rodovia.test(resp.data.logradouro)) {
          this.userData.responsible.address.public_place = 'Rodovia'
          const regEx = new RegExp('Rodovia ', 'ig')
          this.userData.responsible.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (estrada.test(resp.data.logradouro)) {
          this.userData.responsible.address.public_place = 'Estrada'
          const regEx = new RegExp('Estrada ', 'ig')
          this.userData.responsible.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (lote.test(resp.data.logradouro)) {
          this.userData.responsible.address.public_place = 'Lote'
          const regEx = new RegExp('Lote ', 'ig')
          this.userData.responsible.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (servidao.test(resp.data.logradouro)) {
          this.userData.responsible.address.public_place = 'Servidão'
          const regEx = new RegExp('Servidão ', 'ig')
          this.userData.responsible.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (quadra.test(resp.data.logradouro)) {
          this.userData.responsible.address.public_place = 'Quadra'
          const regEx = new RegExp('Quadra ', 'ig')
          this.userData.responsible.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (travessa.test(resp.data.logradouro)) {
          this.userData.responsible.address.public_place = 'Travessa'
          const regEx = new RegExp('Travessa ', 'ig')
          this.userData.responsible.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (linha.test(resp.data.logradouro)) {
          this.userData.responsible.address.public_place = 'Linha'
          const regEx = new RegExp('Linha ', 'ig')
          this.userData.responsible.address.street = resp.data.logradouro.replace(regEx, '')
        } else {
          this.userData.responsible.address.street = resp.data.logradouro
        }

        const state = this.$store.getters['states/getStates'].find(o => o.initials === resp.data.uf)
        this.userData.responsible.address.state_id = state.value
        await this.fillCity(state.value, resp.data.localidade, 'cityOptions', 'tenant')
      }
    },

    async fillCity(stateId, city = null, prop = 'cityOptions') {
      try {
        this[prop] = []
        if (stateId) {
          const resp = await axios.get(`${process.env.VUE_APP_API_URL}/cities/${stateId}`)
          resp.data.map(c => this[prop].push({ value: c.id, label: c.city }))

          if (city) {
            // procura pelo id, se não encontrar peocura pelo nome (motivo: da api recebo ID e do CEP recebo nome)
            let ct = this[prop].find(x => x.value === city)
            if (!ct) {
              ct = this[prop].find(x => x.label === city)
            }
            this.userData.responsible.address.city_id = ct.value
          }
        }
      } catch (error) {
        this.showToast('warning', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
      }
    },

    showToast(variant = 'success', icon = 'CheckIcon', title = 'Sucesso', text = 'Dados Salvos com Sucesso.') {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
