import {
  ref, onUnmounted, onMounted,
} from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'
import store from '@/store'
import treatmentsStoreModule from './useStoreTreatments'

export default function useTreatments(userData) {
  const TREATMENT_STORE_MODULE_NAME = 'store-treatments'

  // Register module
  if (!store.hasModule(TREATMENT_STORE_MODULE_NAME)) store.registerModule(TREATMENT_STORE_MODULE_NAME, treatmentsStoreModule)

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(TREATMENT_STORE_MODULE_NAME)) store.unregisterModule(TREATMENT_STORE_MODULE_NAME)
  })

  // Use toast
  const toast = useToast()

  const refTable = ref(null)

  const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
    toast({
      component: ToastificationContent,
      props: {
        titleToast,
        icon,
        text,
        variant,
      },
    })
  }

  const catchErr = e => {
    if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'AlertCircleIcon', 'Atenção', e.response.data.message)
    else if (e.response.status === 401) showToast('danger', 'AlertCircleIcon', 'Atenção', 'Sua sessão expirou, faça novo login!')
    else showToast('danger', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
  }

  const orthodonticsEvolutions = ref([])
  const orthodonticsPlanning = ref([])
  const treatmentEvolutions = ref([])
  const treatmentPlanning = ref([])

  const filteredData = () => {
    orthodonticsEvolutions.value = userData.value.treatment_evolutions.filter(e => e.treatment_type === 'ORTHODONTIC' && e.evolution_type === 'EVOLUTION')
    orthodonticsPlanning.value = userData.value.treatment_evolutions.filter(e => e.treatment_type === 'ORTHODONTIC' && e.evolution_type === 'PLANNING')
    treatmentEvolutions.value = userData.value.treatment_evolutions.filter(e => e.treatment_type === 'IMPLANT' && e.evolution_type === 'EVOLUTION')
    treatmentPlanning.value = userData.value.treatment_evolutions.filter(e => e.treatment_type === 'IMPLANT' && e.evolution_type === 'PLANNING')
  }

  onMounted(() => {
    filteredData()
  })

  const save = async data => {
    try {
      let resp = null
      if (data.id) {
        resp = await store.dispatch('store-treatments/update', data)
        const idx = userData.value.treatment_evolutions.findIndex(e => e.id === resp.data.id)
        userData.value.treatment_evolutions.splice(idx, 1, resp.data)
      } else {
        resp = await store.dispatch('store-treatments/add', data)
        userData.value.treatment_evolutions.unshift(resp.data)
      }
      filteredData()
      showToast()
    } catch (e) {
      catchErr(e)
    }
  }

  const showLoading = ref(false)
  const showPrintModal = ref(false)
  const srcPdf = ref('')

  const print = async data => {
    showPrintModal.value = true
    showLoading.value = true
    const pdf = await axios.post(`${process.env.VUE_APP_API_URL}/pdf/print-treatment-evolutions`, data)
    showLoading.value = false
    srcPdf.value = `data:application/pdf;base64,${pdf.data}`
  }

  const cancelData = async (data, force = null) => {
    try {
      const resp = await store.dispatch('store-treatments/delete', { data, force })
      const idx = userData.value.treatment_evolutions.findIndex(e => e.id === data.id)
      if (idx >= 0 && force) {
        userData.value.treatment_evolutions.splice(idx, 1)
      } else if (idx >= 0) {
        userData.value.treatment_evolutions.splice(idx, 1, resp.data)
      }
      filteredData()
      showToast('success', 'CheckIcon', 'Sucesso', 'Dados Alterados com Sucesso.')
    } catch (e) {
      catchErr(e)
    }
  }

  const reactiveData = async data => {
    try {
      const resp = await store.dispatch('store-treatments/reactive', data)
      const idx = userData.value.treatment_evolutions.findIndex(e => e.id === data.id)
      if (idx >= 0) {
        userData.value.treatment_evolutions.splice(idx, 1, resp.data)
      }
      filteredData()
      showToast('success', 'CheckIcon', 'Sucesso', 'Dados Alterados com Sucesso.')
    } catch (e) {
      catchErr(e)
    }
  }

  return {
    refTable,
    showPrintModal,
    showLoading,
    srcPdf,

    save,
    print,
    cancelData,
    reactiveData,
    catchErr,
    orthodonticsEvolutions,
    orthodonticsPlanning,
    treatmentEvolutions,
    treatmentPlanning,
  }
}
