import axios from '@axios'

export default {
  namespaced: true,
  state: {
    categoryProcedure: {},
    categoryProcedures: [],
    categoryProceduresList: [],
  },

  getters: {
    categoryProcedures: state => state.categoryProcedures.map(o => ({ value: o.id, label: o.procedure_category, data: o })),
  },

  mutations: {
    ADD_CATEGORY_PROCEDURIES(state, data) {
      state.categoryProcedures.push(data)
      state.categoryProcedures.sort((a, b) => a.procedure_category.localeCompare(b.procedure_category)) // Ordena por procedure_category
    },
    SET_CATEGORY_PROCEDURE(state, data) {
      state.categoryProcedure = data
    },
    SET_CATEGORY_PROCEDURES(state, data) {
      state.categoryProcedures = data
    },
    SET_CATEGORY_PROCEDURES_LIST(state, data) {
      state.categoryProceduresList = data
    },

    REMOVE_RECORD(state, itemId) {
      const idx = state.categoryProceduresList.data.findIndex(e => e.id === itemId)
      if (idx >= 0) {
        state.categoryProceduresList.data.splice(idx, 1)
        state.categoryProceduresList.total -= 1
      }
    },
  },

  actions: {
    async fetch({ state, commit }, args) {
      try {
        if (!state.categoryProcedures.length || args) {
          const resp = await axios.get(`${process.env.VUE_APP_API_URL}/procedure-categories${args ? `?${new URLSearchParams(args).toString()}` : '/'}`)
          if (!args) commit('SET_CATEGORY_PROCEDURES', resp.data)
          else { commit('SET_CATEGORY_PROCEDURES_LIST', resp.data) }
          return resp
        }
        return state.categoryProcedures
      } catch (e) {
        return e
      }
    },

    add({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/procedure-category`, data)
          .then(response => {
            commit('ADD_CATEGORY_PROCEDURIES', response.data)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    update({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/procedure-category/${data.id}`, data)
          .then(response => {
            commit('SET_CATEGORY_PROCEDURE', response.data)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    delete({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${process.env.VUE_APP_API_URL}/procedure-category/${data.id}`)
          .then(response => {
            commit('REMOVE_RECORD', data.id)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
