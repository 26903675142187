<template>
  <b-modal
    id="modal-add-treatments"
    v-model="showLocal"
    centered
    size="xl"
    title="Adicionar Tratamentos"
    @hidden="hidden"
  >

    <b-alert
      v-if="!priceListId || !dentist"
      variant="warning"
      show
    >
      <h4 class="alert-heading">
        Atenção!
      </h4>
      <div class="alert-body">
        <p v-if="!priceListId">
          Favor selecionar um plano <strong>que possua tabela de preços vinculada</strong> antes de inserir tratamentos no orçamento.
        </p>
        <p v-if="!dentist">
          Favor selecionar um dentista antes de inserir tratamentos no orçamento.
        </p>
      </div>
    </b-alert>

    <b-row
      v-else
    >
      <b-col
        cols="12"
        md="12"
      >
        <!-- Table Top -->
        <b-row
          class="mb-25"
        >

          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="categoryFilter"
              style="width: 100%"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="procedureCategoriesOption"
              :reduce="val => val.value"
              :clearable="true"
              placeholder="Filtrar por categoria"
              class="category-selector d-inline-block mx-50"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="5"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
                debounce="500"
              />
            </div>
          </b-col>
        </b-row>
      </b-col>

      <b-col
        cols="12"
        md="12"
      >
        <b-overlay
          variant="white"
          :show="showLoading"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
        >
          <b-table
            ref="refProcedureListTable"
            v-model="tableItems"
            :items="fetchProceduresPriceList"
            responsive
            :fields="tableColumns"
            primary-key="id"
            show-empty
            empty-text="Nenhum resultado encontrado"
            no-sort-reset
            select-mode="multi"
            selectable
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            @row-selected="onRowSelected"
          >

            <template #cell(selectAll)="{ rowSelected }">
              <template v-if="rowSelected">
                <feather-icon
                  icon="CheckSquareIcon"
                  class="text-primary"
                  size="15"
                />
              </template>
              <template v-else>
                <feather-icon
                  icon="SquareIcon"
                  class="text-primary"
                  size="15"
                />
              </template>
            </template>

          </b-table>
        </b-overlay>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalData"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-col>
    </b-row>

    <template #modal-footer="{ cancel }">
      <b-button
        variant="outline-secondary"
        @click="cancel()"
      >
        Cancelar
      </b-button>
      <b-button
        variant="primary"
        :disabled="!priceListId || !dentist || !selected.length"
        @click="emitProcedures(selected)"
      >
        Adicionar
      </b-button>
    </template>

  </b-modal>
</template>

<script>
import { toRefs, ref, watch } from '@vue/composition-api'
import {
  BButton, BTable, BRow, BCol, BFormInput, BPagination,
  BAlert, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useAddProcedures from './useAddProcedures'

export default {
  components: {
    BButton,
    BTable,
    BRow,
    BCol,
    BFormInput,
    BPagination,
    BAlert,
    BOverlay,

    vSelect,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    priceListId: {
      type: Number,
      default: null,
    },
    dentist: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const showLocal = ref(false)

    // necessário para observar props
    const { show } = toRefs(props)
    watch(show, newValue => {
      showLocal.value = newValue
    })

    const hidden = () => {
      emit('hidden')
    }

    const {
      procedureTableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      categoryFilter,
      showLoading,
      procedureCategoriesOption,
      refProcedureListTable,
      tableItems,
      selected,
      tableColumns,

      refetchData,
      onRowSelected,
      fetchProcedureCategories,
      fetchProceduresPriceList,
    } = useAddProcedures(props)

    const emitProcedures = data => {
      const x = data.map(e => ({
        ...e, discount: 0, discountType: 'percent', status: 'NOT_FINISHED', status2: 'AUTHORIZED', observation: '', teeth_id: '', tooth_face_id: '', originalPrice: e.price, cancelled: false,
        // ...e, discount: 0, discountType: 'percent', status: 'NOT_FINISHED', status2: 'authorized', observation: '', teeth_id: '', tooth_face_id: '', price: 250, originalPrice: 250,
      }))
      emit('selectedProcedures', x)
      emit('hidden')
      selected.value = []
    }

    fetchProcedureCategories()

    return {
      showLocal,
      procedureTableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      categoryFilter,
      procedureCategoriesOption,
      refProcedureListTable,
      tableItems,
      selected,
      tableColumns,
      showLoading,

      refetchData,
      onRowSelected,
      emitProcedures,
      hidden,
      fetchProcedureCategories,
      fetchProceduresPriceList,
    }
  },
}
</script>
<style scoped lang="scss">
  @import '~@core/scss/base/bootstrap-extended/include';
  @import '~@core/scss/base/components/variables-dark';

  .dark-layout {
    .b-overlay-wrap ::v-deep .b-overlay {
      // border: 10px solid red;
      .bg-white {
        background-color: $theme-dark-body-bg !important;
      }
    }
  }

</style>
