/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */

import Vue from 'vue'
import axios from '@axios'

export default {
  namespaced: true,

  state: {
    accountPlans: [],
    accountPlan: {},
  },

  getters: {
    // 1 - RECEITAS | 2 - IMPOSTOS | 3 - DESPESAS | 4 - INVESTIMENTOS etc...
    forSelect: (state, getters) => type => {
      const newNode = JSON.parse(JSON.stringify(getters.forTree))
      const root = []
      const tree = !type ? newNode : newNode.filter(t => t.plan === type)

      // eslint-disable-next-line no-shadow
      const f = tree => {
        tree.forEach(node => {
          // eslint-disable-next-line no-param-reassign
          node.value = node.id
          // eslint-disable-next-line no-param-reassign
          node.label = node.plan
          root.push(node)
          if (node.children) {
            f(node.children)
          }
        })
      }
      if (tree.length) f(tree)
      return root
    },

    forTree(state) {
      // Create root for top-level node(s)
      const root = []
      const newNode = JSON.parse(JSON.stringify(state.accountPlans))
      // eslint-disable-next-line consistent-return
      newNode.forEach(node => {
        node.name = node.plan
        node.pid = node.parent_id
        delete node.childs
        delete node.created_at
        delete node.updated_at
        node.addLeafNodeDisabled = true
        // EVITA EDIÇÃO DE RECEITAS, DESPESAS, IMPOSTOS' E INVESTIMENTOS
        if (node.name === '1 - RECEITAS' || node.name === '2 - IMPOSTOS' || node.name === '3 - DESPESAS' || node.name === '4 - INVESTIMENTOS') {
          node.dragDisabled = true
          node.editNodeDisabled = true
          node.delNodeDisabled = true
        }

        if (node.parent_id === 0) return root.push(node)
        // Insert node as child of parent in flat array
        const parentIndex = newNode.findIndex(el => el.id === node.parent_id)
        if (!newNode[parentIndex].children) {
          return newNode[parentIndex].children = [node]
        }
        newNode[parentIndex].children.push(node)
      })
      return root
    },
  },

  mutations: {
    SET(state, payload) {
      // state.accountPlan = Object.assign({}, state.accountPlan, payload) // precisa ser assim para manter a reatividade
      state.accountPlan = { ...state.accountPlan, ...payload } // precisa ser assim para manter a reatividade
    },

    ADD(state, payload) {
      state.accountPlans.push(payload) // precisa ser assim para manter a reatividade
    },

    // SUBSTITUI O ARRAY DE CONTAS POR UM NOVO
    SET_LIST(state, payload) {
      state.accountPlans = payload
    },

    UPDATE(state, payload) {
      const index = state.accountPlans.findIndex(o => o.id === payload.id)
      Vue.set(state.accountPlans, index, payload)
    },

    DELETE(state, payload) {
      const index = state.accountPlans.findIndex(o => o.id === payload)
      state.accountPlans.splice(index, 1) // precisa ser assim para manter a reatividade
    },
  },

  actions: {
    fetch({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}/account-plans`, payload)
          .then(response => {
            commit('SET_LIST', response.data)
            resolve(response)
          })
          .catch(e => { reject(e) })
      })
    },

    add({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_URL}/account-plan`, payload)
          .then(response => {
            commit('SET', response.data)
            commit('ADD', response.data)
            resolve(response)
          })
          .catch(e => { reject(e) })
      })
    },

    update({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_URL}/account-plan/${payload.id}`, payload)
          .then(response => {
            commit('SET', response.data)
            commit('UPDATE', response.data)
            resolve(response)
          })
          .catch(e => { reject(e) })
      })
    },

    delete({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_URL}/account-plan/${id}`)
          .then(response => {
            commit('DELETE', id)
            resolve(response)
          })
          .catch(e => { reject(e) })
      })
    },
  },
}
