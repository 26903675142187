<template>
  <div>
    <div>
      <template>
        <component :is="'b-card'">

          <b-tabs
            v-if="userData"
          >

            <!-- Tab: Anamnese -->
            <b-tab active>
              <template #title>
                <feather-icon
                  icon="ListIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Anamnese</span>
              </template>
              <user-edit-tab-others-anamnese
                :user-data="userData"
                class="mt-2 pt-75"
              />
            </b-tab>

            <!-- Tab: Arquivos -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon="ImageIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Arquivos</span>
              </template>
              <user-edit-tab-others-images
                :user-data="userData"
                class="mt-2 pt-75"
              />
            </b-tab>

            <!-- Tab: Atestatos -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon="FileIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Atestados</span>
              </template>
              <user-edit-tab-others-medical-certificate
                :user-data="userData"
                class="mt-2 pt-75"
              />
            </b-tab>

            <!-- Tab: Receituário -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Receituário</span>
              </template>
              <user-edit-tab-others-prescription
                :user-data="userData"
                class="mt-2 pt-75"
              />
            </b-tab>
          </b-tabs>
        </component>
      </template>
    </div>

  </div>
</template>

<script>
import {
  BCard, BTabs, BTab,
} from 'bootstrap-vue'
import UserEditTabOthersAnamnese from './anamnese/UserEditTabAnamnese.vue'
import UserEditTabOthersImages from './images/UserEditTabImages.vue'
import UserEditTabOthersMedicalCertificate from './UserEditTabMedicalCertificate.vue'
import UserEditTabOthersPrescription from './UserEditTabPrescription.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,

    UserEditTabOthersAnamnese,
    UserEditTabOthersImages,
    UserEditTabOthersMedicalCertificate,
    UserEditTabOthersPrescription,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
    }
  },
}
</script>

<style lang="scss">

</style>
