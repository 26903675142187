/* eslint-disable func-names */
/* eslint-disable no-extend-native */
import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { currency } from '@core/utils/filter'

import store from '@/store'

export default function useFinance(userData) {
  // AJUSTE DE VENCIMENTOS 30 DIAS EM MESES COM MENOS DE 31 DIAS
  Date.isLeapYear = year => (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0))
  Date.getDaysInMonth = (year, month) => [31, (Date.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month]
  Date.prototype.isLeapYear = function () { return Date.isLeapYear(this.getFullYear()) }
  Date.prototype.getDaysInMonth = function () { return Date.getDaysInMonth(this.getFullYear(), this.getMonth()) }
  Date.prototype.addMonths = function (value) {
    const n = this.getDate()
    this.setDate(1)
    this.setMonth(this.getMonth() + value)
    this.setDate(Math.min(n, this.getDaysInMonth()))
    // return `${this.getFullYear()}-0${this.getMonth() + 1}-${this.getDate()}`
    return this
  }

  // Use toast
  const toast = useToast()

  const addInstallmentsModal = ref(false)
  const refFinancesBillsInListTable = ref(null)
  const showOverlay = ref(false)

  // Table Handlers
  const tableColumns = [
    {
      key: 'expiration_date',
      label: 'Vencimento',
      tdClass: 'expirationDateTdClass',
      sortable: true,
    },
    {
      key: 'transaction',
      label: 'Pago em',
      tdClass: 'paymentDateTdClass',
      sortable: true,
    },
    {
      key: 'description',
      label: 'Descrição',
      tdClass: 'descriptionTdClass',
      sortable: true,
    },
    {
      key: 'value_installment',
      label: 'Valor',
      formatter: currency,
      tdClass: 'cashOutTdClass',
      sortable: true,
    },
    {
      key: 'transactionm',
      label: 'Valor Pago',
      formatter: currency,
      tdClass: 'paidValueTdClass',
      sortable: true,
    },
  ]

  const perPage = ref(10)
  const totalData = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50]
  const totalExpired = ref(0)
  const totalNotPaid = ref(0)
  const totalPaid = ref(0)
  const filter = ref(['PAID', 'NOT_PAID'])
  const filterOptions = ref([
    { item: 'PAID', name: 'Pago' },
    { item: 'NOT_PAID', name: 'A Pagar' },
  ])
  const sortBy = ref('expiration_date')
  const isSortDirDesc = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refFinancesBillsInListTable.value ? refFinancesBillsInListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalData.value,
    }
  })

  const refetchData = () => {
    refFinancesBillsInListTable.value.refresh()
  }

  watch([currentPage, perPage, filter], () => {
    refetchData()
  })

  const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
    toast({
      component: ToastificationContent,
      props: {
        titleToast,
        icon,
        text,
        variant,
      },
    })
  }

  const catchErr = e => {
    if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'AlertCircleIcon', 'Atenção', e.response.data.message)
    else if (e.response.status === 401) showToast('danger', 'AlertCircleIcon', 'Atenção', 'Sua sessão expirou, faça novo login!')
    else showToast('danger', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
  }

  const fetch = (ctx, callback) => {
    showOverlay.value = true
    store
      .dispatch('store-finance/fetchBills', {
        q: '',
        situation: filter.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sort: isSortDirDesc.value ? 'desc' : 'asc',
        user_id: userData ? userData.id : null,
        withTrashed: true,
      })
      .then(response => {
        showOverlay.value = false
        const { data, total } = response.data.transactions

        callback(data)
        totalData.value = total
        totalExpired.value = response.data.totalExpired
        totalNotPaid.value = response.data.totalNotPaid
        totalPaid.value = response.data.totalPaid
      })
      .catch(e => {
        showOverlay.value = false
        catchErr(e)
      })
  }

  return {
    tableColumns,
    perPage,
    currentPage,
    totalData,
    dataMeta,
    totalExpired,
    totalNotPaid,
    totalPaid,
    perPageOptions,
    filter,
    filterOptions,
    sortBy,
    isSortDirDesc,
    refFinancesBillsInListTable,
    addInstallmentsModal,

    fetch,
    refetchData,
  }
}
