import axios from '@axios'

export default {
  namespaced: true,
  state: {
    anamnese: {},
    anamneses: [],
  },
  getters: {
    forSelect(state) {
      return Array.isArray(state.anamneses)
        ? state.anamneses.map(e => ({
          value: e.id,
          label: e.name,
          data: e,
        }))
        : []
    },
  },

  mutations: {
    //
  },

  actions: {
    add({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/anamnese`, data)
          .then(response => {
            commit('store-patient/ADD_ANAMNESE', response.data, { root: true })
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    update({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/anamnese/${data.anamnese.id}`, data)
          .then(response => {
            commit('store-patient/UPDATE_ANAMNESE', response.data, { root: true })
            return resolve(response)
          })
          .catch(e => {
            reject(e)
          })
      })
    },

    delete({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${process.env.VUE_APP_API_URL}/anamnese/${data.id}`)
          .then(response => {
            commit('store-patient/DELETE_ANAMNESE', data, { root: true })
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
