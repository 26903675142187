import { ref, watch, computed } from '@vue/composition-api'
import { dateTime } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default function useBudget(emit) {
  // Use toast
  const toast = useToast()

  const refBudgetListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'Código', sortable: true },
    {
      key: 'status',
      label: 'status',
      // eslint-disable-next-line no-nested-ternary
      formatter: value => (value === 'PENDING' ? 'Pendente' : (value === 'APPROVED' ? 'Aprovado' : 'Cancelado')),
      sortable: true,
    },
    {
      key: 'creation_date',
      label: 'Criado em',
      formatter: value => dateTime(value, 'dateTimeNoSecond'),
      sortable: true,
    },
    {
      key: 'canceled_at',
      label: 'Cancelado em',
      formatter: value => dateTime(value, 'dateTimeNoSecond'),
      sortable: true,
    },
    {
      key: 'effective_date',
      label: 'Efetivado em',
      formatter: value => dateTime(value, 'dateTimeNoSecond'),
      sortable: false,
    },
    {
      key: 'dentist.name',
      label: 'Dentista',
      sortable: false,
    },
    {
      key: 'dental_plan.name',
      label: 'Plano',
      formatter: value => value || 'Particular',
      sortable: false,
    },
    {
      key: 'actions',
      label: 'ações',
      sortable: false,
    },
  ]
  const perPage = ref(10)
  const totalData = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50]
  const sortBy = ref('id')
  const isSortDirDesc = ref(false)
  const deleteModal = ref(false)
  const showOverlay = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refBudgetListTable.value ? refBudgetListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalData.value,
    }
  })

  const refetchData = () => {
    refBudgetListTable.value.refresh()
    emit('refetchData')
  }

  const filter = ref({
    status: [],
    dateStart: '',
    dateEnd: '',
    code: '',
  })

  watch([currentPage, perPage, filter], () => {
    refetchData()
  },
  { deep: true })

  const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
    toast({
      component: ToastificationContent,
      props: {
        titleToast,
        icon,
        text,
        variant,
      },
    })
  }

  const catchErr = e => {
    if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'AlertCircleIcon', 'Atenção', e.response.data.message)
    else if (e.response.status === 401) showToast('danger', 'AlertCircleIcon', 'Atenção', 'Sua sessão expirou, faça novo login!')
    else showToast('danger', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
  }

  const statusOptions = ref([
    { label: 'Aprovado', value: 'APPROVED' },
    { label: 'Cancelado', value: 'CANCELED' },
    { label: 'Pendente', value: 'PENDING' },
  ])

  const locale = ref('pt-BR')

  const locales = ref([
    { value: 'pt-BR', text: 'Portuguese BR (pt-BR)' },
    { value: 'en-US', text: 'English US (en-US)' },
  ])

  const labels = {
    'pt-BR': {
      labelPrevDecade: 'Década Anterior',
      labelPrevYear: 'Ano Anterior',
      labelPrevMonth: 'Mês Anterior',
      labelCurrentMonth: 'Mês Atual',
      labelNextMonth: 'Próximo Mês',
      labelNextYear: 'Próximo Ano',
      labelNextDecade: 'Próxima Década',
      labelToday: 'Hoje',
      labelSelected: 'Data Selecionada',
      labelNoDateSelected: 'Nenhuma Data Selecionada',
      labelCalendar: 'Calendário',
      labelNav: 'Navegação no Calendário',
      labelHelp: 'Use as setas do teclado para navegar',
    },
  }

  const isDateValidated = ref(true)

  const returnDiff = (start, end) => (end - start)

  const validateDate = () => {
    const isValid = ref(true)
    if (filter.value.dateStart && filter.value.dateEnd) {
      const yearsDiff = returnDiff(Number(filter.value.dateStart.slice(0, 4)), Number(filter.value.dateEnd.slice(0, 4)))
      const monthDiff = returnDiff(Number(filter.value.dateStart.slice(5, 7)), Number(filter.value.dateEnd.slice(5, 7)))
      const daysDiff = returnDiff(Number(filter.value.dateStart.slice(8, 10)), Number(filter.value.dateEnd.slice(8, 10)))
      isValid.value = (yearsDiff > 0 || (yearsDiff === 0 && monthDiff > 0) || (yearsDiff === 0 && monthDiff === 0 && daysDiff >= 0))
    }
    isDateValidated.value = isValid.value
  }

  const fetch = (ctx, callback) => {
    showOverlay.value = true
    store
      .dispatch('store-budget/fetch', {
        filter: JSON.stringify(filter.value),
        isDateValidated: isDateValidated.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sort: isSortDirDesc.value ? 'desc' : 'asc',
        withTrashed: true,
      })
      .then(response => {
        showOverlay.value = false
        const { data, total } = response.data
        callback(data)
        totalData.value = total
      })
      .catch(e => {
        showOverlay.value = false
        catchErr(e)
      })
  }

  const deleteData = async data => {
    try {
      await store.dispatch('store-budget/delete', data)
      refetchData()
      showToast('success', 'CheckIcon', 'Sucesso', 'Dados Excluídos com Sucesso.')
      deleteModal.value = false
      emit('action') // Refresh history tab
    } catch (e) {
      catchErr(e)
    }
  }

  const sendBudgetData = async budgetData => {
    const [creationDt] = budgetData.creation_date.split(' ')
    const budgetPayload = {
      id: budgetData.id,
      user_id: budgetData.user_id,
      status: 'APPROVED',
      creation_date: creationDt,
      effective_date: budgetData.effective_date,
      health_insurance_id: budgetData.health_insurance_id,
      dental_plan_id: budgetData.dental_plan_id,
      seller_id: budgetData.seller_id,
      doc_identification_number: budgetData.doc_identification_number,
      dentist_id: budgetData.dentist_id,
      discount: budgetData.discount,
      main_discount_type: budgetData.main_discount_type,
      main_discount_mode: budgetData.main_discount_mode,
      subtotal: budgetData.subtotal,
      procedures: budgetData.procedures,
      installments: budgetData.installments,
    }

    try {
      await store.dispatch('store-budget/update', budgetPayload)
      showToast()
      emit('refetchData')
      refetchData()
    } catch (e) {
      catchErr(e)
    }
  }

  return {
    fetch,
    tableColumns,
    perPage,
    currentPage,
    totalData,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refBudgetListTable,
    deleteModal,
    statusOptions,
    filter,
    locale,
    locales,
    labels,
    validateDate,
    isDateValidated,
    sendBudgetData,

    refetchData,
    deleteData,
  }
}
