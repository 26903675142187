<template>
  <div>
    <handle-observation
      :show="handleObservationShowModal"
      :procedure="procedureData"
      @save="save"
      @hidden="handleObservationShowModal = false"
    />

    <evolutions-list-modal
      title="Lista de evoluções"
      :show="showListModal"
      :data="dt"
      @printEvolution="printEvolution"
      @addEvolution="addEvolution"
      @handleEvolution="handleEvolution"
      @cancelEvolution="cancelEvolution"
      @reactivateEvolution="reactivateEvolution"
      @hidden="showListModal = false"
    />

    <procedure-evolution-modal
      title="Evoluir Procedimento"
      :show="showActionModal"
      :evolution="evolution"
      @save="saveEvolution"
      @hidden="showActionModal = false; reset()"
    />

    <b-modal
      id="modal-print"
      v-model="showModal"
      centered
      size="lg"
      title="Imprimir"
      hide-footer
    >
      <object
        :data="srcPdf"
        width="100%"
        style="height: 80vh;"
      />
    </b-modal>

    <b-form>
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
          <b-button
            variant="outline-dark"
            size="sm"
            @click="showFilters = !showFilters"
          >
            <feather-icon
              icon="ChevronRightIcon"
              size="12"
              :class="showFilters ? 'rotate' : 'undo-rotate'"
            />
            <span class="ml-25 align-middle">Filtros</span>
          </b-button>
        </b-col>

        <b-col
          cols="12"
          md="9"
          align="end"
        >
          <b-button
            variant="primary"
            size="sm"
            class="ml-1"
          >
            <feather-icon
              icon="PrinterIcon"
              size="12"
            />
            <span class="ml-25 align-middle">Imprimir Procedimentos</span>
          </b-button>
        </b-col>
      </b-row>

      <b-collapse
        v-model="showFilters"
        class="mt-2"
      >
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <label>Código ou conteúdo</label>
            <b-form-input
              v-model="filter.content"
              placeholder="Buscar..."
            />
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <label>Categoria</label>
            <v-select
              v-model="filter.category_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="procedureCategoriesOption"
              :reduce="val => val.value"
              placeholder="Selecione..."
              clearable
            />
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <label>Dentista</label>
            <v-select
              v-model="filter.dentist_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="dentistOptions"
              :reduce="val => val.value"
              placeholder="Selecione..."
              clearable
            />
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col
            cols="12"
            md="6"
          >
            <label for="date-filter-beginning">De</label>
            <b-form-datepicker
              id="date-filter-beginning"
              v-model="filter.dateStart"
              :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
              v-bind="labels[locale] || {}"
              :locale="locale"
              :show-decade-nav="false"
              :state="isDateValidated ? null : false"
              hide-header
              placeholder="Início"
            />
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <label for="date-filter-beginning">Até</label>
            <b-form-datepicker
              id="date-filter-beginning"
              v-model="filter.dateEnd"
              :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
              v-bind="labels[locale] || {}"
              :locale="locale"
              :show-decade-nav="false"
              :state="isDateValidated ? null : false"
              hide-header
              placeholder="Fim"
            />
          </b-col>

        </b-row>
      </b-collapse>

      <b-row>
        <b-col
          cols="12"
          md="12"
          class="mt-2"
        >
          <b-table
            ref="refTable"
            class="position-relative pt-25 tableMinHeght"
            :fields="tableColumns"
            :items="filteredProcedures"
            small
            show-empty
            empty-text="Nenhum resultado encontrado"
            no-sort-reset
          >
            <template #cell(teeth_faces)="data">
              {{ data.item.pivot.teeth.tooth }} / {{ data.item.pivot.tooth_faces && data.item.pivot.tooth_faces.flatMap(e => e.face).toString().replace(/\["(.*?)\"]/g, '').replace(',', ' | ') }}
            </template>

            <template #cell(observation)="data">
              <div
                class="d-flex flex-row justify-content-between"
              >

                <div
                  :id="`observation-indicator-icon-${data.index}`"
                  class="align-self-center mr-50"
                >
                  <feather-icon
                    icon="InfoIcon"
                    class="cursor-pointer"
                    :class="{ 'text-primary': data.item.pivot.observation && data.item.pivot.observation !== '' }"
                    size="20"
                    @click="handleObservationShow(data)"
                  />
                  <b-tooltip
                    v-if="!data.item.pivot.deleted_at && data.item.pivot.observation && data.item.pivot.observation !== ''"
                    :target="`observation-indicator-icon-${data.index}`"
                    noninteractive
                    :delay="{show: 300, hide: 125}"
                  >
                    {{ data.item.pivot.observation }}
                  </b-tooltip>
                </div>

              </div>
            </template>

            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item
                  v-if="!data.item.pivot.deleted_at"
                  @click="handleFinalization(data.item)"
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Retomar</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="!data.item.pivot.deleted_at"
                  @click="handleEvolutions(data.item)"
                >
                  <feather-icon icon="CheckSquareIcon" />
                  <span class="align-middle ml-50">Evolução</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="!data.item.pivot.deleted_at"
                  @click="print(data.item)"
                >
                  <feather-icon icon="PrinterIcon" />
                  <span class="align-middle ml-50">Imprimir</span>
                </b-dropdown-item>

              </b-dropdown>

            </template>

          </b-table>
        </b-col>
      </b-row>

    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BButton, BTable, BCollapse, BFormInput,
  BFormDatepicker, BDropdown, BDropdownItem, BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { toRefs, computed } from '@vue/composition-api'
import store from '@/store'
import handleObservation from './handleObservation.vue'
import useTreatmentProcedures from '../../useTreatmentProcedures'
import useProcedureFilter from './useProcedureFilter'
import EvolutionsListModal from './EvolutionsListModal.vue'
import ProcedureEvolutionModal from './ProcedureEvolutionModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BTable,
    BCollapse,
    BFormInput,
    BFormDatepicker,
    BDropdown,
    BDropdownItem,
    BTooltip,
    handleObservation,
    EvolutionsListModal,
    ProcedureEvolutionModal,

    vSelect,
  },

  props: {
    userData: {
      type: Object,
      required: true,
    },
    dentistOptions: {
      type: Array,
      default: () => [],
    },
  },

  setup(props, { root, emit }) {
    const procedureCategoriesOption = computed(() => store.getters['store-procedure-category/categoryProcedures'])
    const { userData } = toRefs(props)
    const {
      tableColumns,
      showModal,
      srcPdf,
      procedureData,
      handleObservationShowModal,
      showPrintModal,
      showLoading,
      showListModal,
      showActionModal,
      dt,

      print,
      printEvolution,
      save,
      handleObservationShow,
      handleEvolutions,
      addEvolution,
      handleEvolution,
      cancelEvolution,
      reactivateEvolution,
      saveEvolution,

      finishedProcedures,
      locale,
      locales,
      labels,
      evolution,
    } = useTreatmentProcedures(userData, root, emit)

    const {
      data,
      reset,
      showFilters,
      filter,
      isDateValidated,
      filteredProcedures,
    } = useProcedureFilter(userData, finishedProcedures, 'FINISHED')

    const handleFinalization = dta => {
      root.$bvModal
        .msgBoxConfirm('Deseja retomar este tratamento?', {
          title: 'Por Favor Confirme esta Ação',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(resp => {
          if (resp) {
            const tmp = JSON.parse(JSON.stringify(dta))
            tmp.pivot.tooth_face_id = JSON.parse(tmp.pivot.tooth_face_id)
            tmp.pivot.status = 'NOT_FINISHED'
            save(tmp)
          }
        })
    }

    return {
      tableColumns,
      showModal,
      handleObservationShowModal,
      procedureData,
      srcPdf,
      showPrintModal,
      showLoading,
      data,
      showListModal,
      dt,
      showActionModal,
      showFilters,
      locale,
      locales,
      labels,
      filter,
      isDateValidated,
      procedureCategoriesOption,
      filteredProcedures,
      evolution,

      reset,
      print,
      printEvolution,
      addEvolution,
      handleEvolution,
      cancelEvolution,
      reactivateEvolution,
      save,
      handleObservationShow,
      handleFinalization,
      handleEvolutions,
      saveEvolution,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.actionsTdClass {
  width: 116px;
}
</style>
