<template>
  <b-modal
    v-model="showLocal"
    centered
    size="xl"
    :title="title"
    @hidden="hidden"
  >
    <b-row>
      <b-col
        cols="12"
        md="12"
        align="end"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mb-1"
          @click="addEvolution"
        >
          <feather-icon
            icon="PlusCircleIcon"
            size="12"
            class="align-middle"
          />
          <span class="ml-50 align-middle">Adicionar Evolução</span>
        </b-button>
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <b-table
          class="position-relative tableMinHeght"
          :items="data.pivot ? data.pivot.evolutions : []"
          :fields="evolutionsFields"
          :tbody-tr-class="rowClass"
          show-empty
          empty-text="Não há evoluções para serem listadas"
          no-sort-reset
          small
        >

          <template #cell(show_details)="row">
            <feather-icon
              :icon="row.detailsShowing ? 'ChevronDownIcon' : 'ChevronRightIcon'"
              size="16"
              class="align-middle text-body cursor-pointer"
              @click="row.toggleDetails"
            />
          </template>

          <template #row-details="row">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="row.item.content" />
          </template>

          <template
            v-if="data.pivot.status !== 'FINISHED'"
            #cell(actions)="d"
          >
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                v-if="!d.item.deleted_at"
                @click="editEvolution(d.item, false)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="printEvolution"
              >
                <feather-icon icon="PrinterIcon" />
                <span class="align-middle ml-50">Imprimir</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="d.item.deleted_at"
                @click="handleReactivate(d.item)"
              >
                <feather-icon icon="RotateCcwIcon" />
                <span class="align-middle ml-50">Reativar</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="!d.item.deleted_at"
                @click="handleCancel(d.item)"
              >
                <feather-icon icon="SlashIcon" />
                <span class="align-middle ml-50">Cancelar</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="d.item.deleted_at"
                @click="handleDelete(d.item)"
              >
                <feather-icon icon="Trash2Icon" />
                <span class="align-middle ml-50">Excluir</span>
              </b-dropdown-item>

            </b-dropdown>
          </template>

        </b-table>
      </b-col>
    </b-row>
    <template #modal-footer="{ cancel }">
      <b-button
        variant="outline-secondary"
        @click="cancel()"
      >
        Cancelar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { ref, toRefs, watch } from '@vue/composition-api'
import axios from '@axios'
import {
  BModal, BButton, BRow, BCol, BTable,
  BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { dateTime } from '@core/utils/filter'

export default {
  components: {
    BModal,
    BButton,
    BRow,
    BCol,
    BTable,
    BDropdown,
    BDropdownItem,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => { },
    },
  },

  setup(props, { root, emit }) {
    const showLocal = ref(false)

    const { show, data } = toRefs(props)
    watch(show, newValue => {
      showLocal.value = newValue
    })

    const evolutionsFields = [
      { label: 'Detalhes', key: 'show_details', tdClass: 'detailsTd' },
      {
        label: 'Alterado em',
        formatter: value => dateTime(value, 'dateTimeNoSecond'),
        key: 'updated_at',
        tdClass: 'updatedTd',
      },

      { label: 'Dentista', key: 'dentist.name', tdClass: 'dentistTd' },
      { label: 'Ações', key: 'actions', tdClass: 'actionsTd' },
    ]

    const rowClass = (item, type) => {
      if (item && (type === 'row') && item.deleted_at) return 'strikeout'
      return null
    }

    const cancelEvolution = async (evolution, force = null) => {
      emit('cancelEvolution', evolution, force)
    }

    const handleCancel = dat => {
      root.$bvModal
        .msgBoxConfirm('Deseja cancelar esta evolução?', {
          title: 'Por Favor Confirme esta Ação',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(resp => {
          if (resp) cancelEvolution(dat)
        })
    }

    const reactivateEvolution = evolution => {
      emit('reactivateEvolution', evolution)
    }

    const handleReactivate = dat => {
      root.$bvModal
        .msgBoxConfirm('Deseja reativar este registro?', {
          title: 'Por Favor Confirme esta Ação',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) reactivateEvolution(dat)
        })
    }

    const handleDelete = dat => {
      root.$bvModal
        .msgBoxConfirm('Deseja excluir este registro permanentemente?', {
          title: 'Por Favor Confirme esta Ação',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) cancelEvolution(dat, true)
        })
    }

    const printEvolution = async () => {
      const srcPdf = ref('')

      try {
        const pdf = await axios.get(`${process.env.VUE_APP_API_URL}/pdf/print-anamnese/1`)
        srcPdf.value = `data:application/pdf;base64,${pdf.data}`
      } catch (error) {
        // console.error(error)
      }
      emit('printEvolution', srcPdf.value)
    }

    const hidden = () => {
      emit('hidden')
    }

    const addEvolution = () => {
      emit('addEvolution', data.value)
    }

    const editEvolution = (evolution, showList = false) => {
      emit('handleEvolution', evolution, showList)
    }

    return {
      showLocal,
      evolutionsFields,
      addEvolution,
      editEvolution,
      rowClass,
      handleCancel,
      handleReactivate,
      handleDelete,
      printEvolution,

      hidden,
    }
  },
}
</script>

<style lang="scss">
.detailsTd {
  width: 90px;
  text-align: center;
}

.updatedTd {
  width: 20%;
}

.actionsTd {
  width: 70px;
  text-align: center;
}
</style>
