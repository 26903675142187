<template>
  <div>
    <b-tabs
      v-if="userData"
      pills
    >

      <!-- Tab: Tratamentos -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon=""
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Evolução</span>
        </template>
        <evolution
          :user-data="userData"
          :dentist-options="dentistOptions"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Próteses -->
      <b-tab>
        <template #title>
          <feather-icon
            icon=""
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Planejamento</span>
        </template>
        <planning
          :user-data="userData"
          :dentist-options="dentistOptions"
          class="mt-2 pt-75"
        />
      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import Evolution from './subtabs/TreatmentsImplantsEvolution.vue'
import Planning from './subtabs/TreatmentsImplantsPlanning.vue'

export default {
  components: {
    BTabs,
    BTab,

    Evolution,
    Planning,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    dentistOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {
    }
  },
}
</script>

<style lang="scss">

</style>
