import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default function useUploadDocumentsModal(emit) {
  const toast = useToast()

  // <==================== Modal Data ====================>
  const docTypes = ref([
    { value: 'personal', label: 'Pessoal' },
    { value: 'other', label: 'Outro' },
  ])

  const docName = ref(null)
  const docType = ref({ value: 'personal', label: 'Pessoal' })
  const docFile = ref(null)
  const showLoading = ref(false)

  const resetDocModalData = () => {
    docName.value = null
    docType.value = { value: 'personal', label: 'Pessoal' }
    docFile.value = null
  }

  const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
    toast({
      component: ToastificationContent,
      props: {
        titleToast,
        icon,
        text,
        variant,
      },
    })
  }

  const catchErr = e => {
    if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'AlertCircleIcon', 'Atenção', e.response.data.message)
    else if (e.response.status === 401) showToast('danger', 'AlertCircleIcon', 'Atenção', 'Sua sessão expirou, faça novo login!')
    else showToast('danger', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
  }

  // <==================== Payload Data ====================>
  const blankDocData = {
    id: null,
    name: null,
    type: 'personal',
    file: null,
    dentistId: null,
  }

  const docData = ref(JSON.parse(JSON.stringify(blankDocData)))

  const resetDocData = () => {
    docData.value = JSON.parse(JSON.stringify(blankDocData))
  }

  // <==================== Launch Payload ====================>
  const launchPayload = async userData => {
    try {
      showLoading.value = true
      docData.value.userId = userData.id
      if (docData.id) await store.dispatch('patient-store-file/update', docData.value)
      else await store.dispatch('patient-store-file/add', docData.value)
      emit('refreshData')
      emit('hidden')
      resetDocData()

      showLoading.value = false
      showToast()
    } catch (e) {
      showLoading.value = false
      catchErr(e)
    }

    resetDocModalData()
  }

  return {
    docTypes,
    docName,
    docType,
    docFile,
    showLoading,
    docData,

    resetDocModalData,
    resetDocData,
    launchPayload,
  }
}
