import Vue from 'vue'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    budget: {},
    budgets: [],
  },
  getters: { },

  mutations: {
    SET(state, data) {
      state.budget = data
    },
    SET_LIST(state, data) {
      state.budgets = data
    },
    ADD_IN_LIST(state, data) {
      if (Array.isArray(state.budgets)) state.budgets.push(data)
    },
    UPDATE_IN_LIST(state, data) {
      if (Array.isArray(state.budgets)) {
        const idx = state.budgets.findIndex(o => o.id === data.id)
        if (idx >= 0) Vue.set(state.budgets, idx, data)
      }
    },
    DELETE_REGISTER(state, payload) {
      const idx = state.budgets.data.findIndex(o => o.id === payload)
      if (idx >= 0) {
        state.budgets.data.splice(idx, 1)
        state.budgets.total -= 1
      }
      if (Array.isArray(state.budgets)) {
        const idx2 = state.budgets.findIndex(o => o.id === payload)
        if (idx2 >= 0) {
          state.budgets.splice(idx, 1)
        }
      }
      if (state.budget && state.budget.id === payload) {
        state.budget = { }
      }
    },
  },

  actions: {
    async fetch({ state, commit }, args) {
      try {
        if (args.isDateValidated && (!state.budgets.length || args)) {
          const resp = await axios.get(`${process.env.VUE_APP_API_URL}/budgets${args ? `?${new URLSearchParams(args).toString()}` : '/'}`)
          commit('SET_LIST', resp.data)
          return resp
        }
        return state.budgets
      } catch (e) {
        return e
      }
    },

    // fetchOne({ commit }, id) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get(`${process.env.VUE_APP_API_URL}/anamnese-models/${id}`)
    //       .then(response => {
    //         commit('SET', response.data)
    //         commit('UPDATE_IN_LIST', response.data)
    //         return resolve(response)
    //       })
    //       .catch(error => reject(error))
    //   })
    // },

    add({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/budgets`, data)
          .then(response => {
            commit('SET', response.data)
            commit('ADD_IN_LIST', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    update({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/budgets/${data.id}`, data)
          .then(response => {
            commit('SET', response.data)
            commit('UPDATE_IN_LIST', response.data)
            return resolve(response)
          })
          .catch(e => {
            reject(e)
          })
      })
    },

    delete({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/budget/${data.budget.id}`, { reason: data.reason }) // Put por causa de reason
          .then(response => {
            commit('DELETE_REGISTER', data.id)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
