import axios from '@axios'

export default {
  namespaced: true,
  state: {
    priceList: {},
    priceLists: [],
  },
  getters: {
    forSelect(state) {
      if (Array.isArray(state.priceLists)) {
        const data = []
        // eslint-disable-next-line array-callback-return
        state.priceLists.map(e => {
          data.push({
            value: e.id,
            label: e.name,
          })
        })
        return data
      }
      return []
    },
  },

  mutations: {
    SET_PRICE_LIST(state, data) {
      state.priceList = data
    },
    SET_PRICE_LISTS(state, data) {
      state.priceLists = data
    },
  },

  actions: {
    async fetch({ state, commit }, args) {
      try {
        if (!state.priceLists.length || args) {
          const resp = await axios.get(`${process.env.VUE_APP_API_URL}/price-lists${args ? `?${new URLSearchParams(args).toString()}` : '/'}`)
          if (!args) commit('SET_PRICE_LISTS', resp.data)
          else { commit('SET_PRICE_LIST', resp.data) }
          return resp
        }
        return state.priceLists
      } catch (e) {
        return e
      }
    },

    async fetchProceduresPriceList(ctx, args) {
      try {
        const resp = await axios.get(`${process.env.VUE_APP_API_URL}/procedures-price-list${args ? `?${new URLSearchParams(args).toString()}` : '/'}`)
        // if (!args) commit('SET_PRICE_LISTS', resp.data)
        // else { commit('SET_PRICE_LIST', resp.data) }
        return resp
      } catch (e) {
        return e
      }
    },

    add(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/price-list`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    update({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/price-list/${data.priceList.id}`, data)
          .then(response => {
            commit('SET_PRICE_LIST', response.data)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    duplicate(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/price-list/duplicate/${data.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    delete(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${process.env.VUE_APP_API_URL}/price-list/${data.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
