<template>
  <validation-observer
    #default="{invalid}"
    ref="refFormObserver"
  >
    <b-modal
      id="modal-add-procedure-observation"
      v-model="showLocal"
      centered
      size="lg"
      title="Adicionar Procedimento"
      @hidden="hidden"
    >

      <b-row>
        <b-col
          cols="12"
          md="12"
          class="mt-1 mb-1"
        >
          <validation-provider
            #default="validationContext"
            name="Procedimento"
            rules="required"
            immediate
          >

            <b-form-group
              label="Procedimento"
              label-for="procedures-options"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="procedure.id"
                :options="procedures"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :searchable="true"
                input-id="procedures-options"
                placeholder="Selecione"
                :reduce="option => option.value"
              >
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          md="6"
          class="mt-1 mb-1"
        >
          <validation-provider
            #default="validationContext"
            name="Denta"
            rules="required"
            immediate
          >
            <b-form-group
              label="Dente"
              label-for="tooth-id"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="procedure.teeth_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="$store.getters['teeth/getAllTeeth']"
                :reduce="option => option.value"
                :clearable="false"
                input-id="tooth-id"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="mt-1 mb-1"
        >

          <b-form-group
            label="Face(s)"
            label-for="face-id"
          >
            <v-select
              id="face-id"
              v-model="procedure.tooth_face_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="$store.getters['toothFaces/getToothFaces']"
              :reduce="option => option.value"
              :clearable="false"
              multiple
            />
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer="{ cancel }">

        <b-button
          variant="outline-secondary"
          @click="cancel()"
        >
          Cancelar
        </b-button>
        <b-button
          variant="primary"
          :disabled="invalid"
          @click="save"
        >
          Salvar
        </b-button>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import { toRefs, ref, watch } from '@vue/composition-api'
import {
  BModal, BFormGroup, BButton, BRow, BCol, BFormInvalidFeedback,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BModal,
    BFormGroup,
    BButton,
    BRow,
    BCol,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    vSelect,

  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    procedures: {
      type: Array,
      default: () => [],
    },
    patient: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    localize('pt_BR') // vee-validate messages

    const showLocal = ref(false)
    const procedure = ref({
      id: null,
      teeth_id: null,
      tooth_face_id: null,
      patient_id: props.patient.id,
    })

    // necessário para observar props
    const { show } = toRefs(props)
    watch(show, newValue => {
      showLocal.value = newValue
    })

    const resetFormData = () => {
      procedure.value = {
        id: null,
        teeth_id: null,
        tooth_face_id: null,
        patient_id: props.patient.id,
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetFormData)

    const hidden = () => {
      emit('hidden')
    }

    const save = () => {
      emit('add', procedure.value)
      emit('hidden')
    }

    return {
      showLocal,
      procedure,
      required,
      getValidationState,
      resetForm,
      refFormObserver,
      hidden,
      save,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
