<template>
  <div>
    <validation-observer
      #default="{invalid}"
      ref="refFormObserver"
    >
      <b-modal
        id="modal-upload-documents"
        v-model="showLocal"
        centered
        size="lg"
        title="Upload de Documentos"
        hide-footer
        @hidden="hidden"
      >
        <b-overlay
          variant="white"
          :show="showLoading"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
        >

          <b-form>
            <b-row>

              <!-- Field: Document Name -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="Nome do Documento"
                  rules="required"
                  immediate
                >
                  <b-form-group
                    label="Nome do Documento"
                    label-for="doc-file-name"
                  >
                    <b-form-input
                      id="doc-file-name"
                      v-model="docData.name"
                      :state="getValidationState(validationContext) ? null : false"
                      placeholder="..."
                      trim
                    />
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Document Type -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="Documento"
                  rules="required"
                  immediate
                >
                  <b-form-group
                    label="Tipo de Documento"
                    label-for="doc-type"
                    :state="getValidationState(validationContext) ? null : false"
                  >
                    <!-- Não colocar reduce!!! -->
                    <v-select
                      v-model="docData.type"
                      input-id="doc-type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="docTypes"
                      :reduce="option => option.value"
                      :clearable="false"
                      :searchable="false"
                      placeholder="Selecione"
                    >
                      <template #no-options="{ }">
                        Nenhum resultado encontrado.
                      </template>
                    </v-select>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Document Upload Button -->
              <b-col
                cols="12"
                md="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="Documento"
                  rules="required"
                  immediate
                >
                  <b-form-file
                    v-model="docData.file"
                    browse-text="Fazer Upload"
                    placeholder="Nenhum arquivo selecionado"
                    accept=".xlsx, .xls, .ods, .pdf, .png, .jpg, .jpeg, .docx"
                    :state="getValidationState(validationContext) ? null : false"
                    class="mt-1"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-row>

            <hr>

            <!-- Footer -->
            <b-row>
              <b-col
                cols="12"
                md="6"
              />

              <b-col
                cols="12"
                md="3"
              >
                <b-button
                  variant="outline-secondary"
                  block
                  @click="hidden"
                >
                  Cancelar
                </b-button>
              </b-col>

              <b-col
                cols="12"
                md="3"
              >
                <b-button
                  variant="primary"
                  block
                  :disabled="invalid"
                  @click="confirmAndLaunch"
                >
                  Confirmar
                </b-button>
              </b-col>
            </b-row>

          </b-form>
        </b-overlay>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BForm, BRow, BCol, BButton, BFormGroup, BFormInput, BFormFile, BFormInvalidFeedback, BOverlay,
} from 'bootstrap-vue'
import { toRefs, ref, watch } from '@vue/composition-api'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import useUploadDocumentsModal from './useUploadModal'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormInvalidFeedback,
    BOverlay,

    vSelect,

    ValidationProvider,
    ValidationObserver,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    userData: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, { emit }) {
    const {
      docTypes,
      showLoading,

      resetDocModalData,
      launchPayload,
      docData,
      resetDocData,
    } = useUploadDocumentsModal(emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetDocData)

    const showLocal = ref(false)

    // necessário para observar props
    const { show } = toRefs(props)
    watch(show, newValue => {
      showLocal.value = newValue
    })

    const confirmAndLaunch = () => {
      launchPayload(props.userData)
    }

    const hidden = () => {
      resetDocModalData()
      emit('hidden')
    }

    return {
      hidden,
      showLocal,
      showLoading,

      confirmAndLaunch,

      docTypes,
      resetDocModalData,
      launchPayload,
      docData,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
