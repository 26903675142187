<template>
  <b-overlay
    :show="!userData && userData !== undefined"
    rounded="sm"
  >
    <user-view-user-info-card
      v-if="userData"
      :temp-anamnese-data="tempAnamneseData"
    />

    <component :is="userData === undefined ? 'div' : 'b-card'">
      <!-- Alert: No item found -->
      <b-alert
        variant="danger"
        :show="userData === undefined"
      >
        <h4 class="alert-heading">
          Atenção!
        </h4>
        <div class="alert-body">
          Nenhum paciente encontrado com este código. Por favor procure na
          <b-link
            class="alert-link"
            :to="{ name: 'apps-patient-list'}"
          >
            Lista de Pacientes
          </b-link>
          por outros pacientes.
        </div>
      </b-alert>

      <b-tabs
        v-if="userData"
        small
      >
        <!-- Tab: Dados Gerais -->
        <b-tab active>
          <template #title>
            <feather-icon
              icon="InfoIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Dados Gerais</span>
          </template>
          <user-edit-tab-account
            :user-data="userData"
          />
        </b-tab>

        <!-- Tab: Financeiro -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="DollarSignIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Financeiro</span>
          </template>
          <user-edit-tab-finances
            :user-data="userData"
            @refetchData="refetchData"
          />
        </b-tab>

        <!-- Tab: Tratamentos -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="FolderPlusIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Tratamentos</span>
          </template>
          <user-edit-tab-treatments
            :user-data="userData"
          />
        </b-tab>

        <!-- Tab: Odontograma -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="GridIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Odontograma</span>
          </template>
          <user-edit-tab-odontogram
            :user-data="userData"
          />
        </b-tab>

        <!-- Tab: Outros -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="MoreHorizontalIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Documentos</span>
          </template>
          <user-edit-tab-documents
            :user-data="userData"
          />
        </b-tab>
      </b-tabs>
    </component>
  </b-overlay>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BOverlay,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import patientStoreModule from '../patientStoreModule'
import UserEditTabAccount from './general-data/UserEditTabAccount.vue'
import UserEditTabFinances from './finances/UserEditTabFinances.vue'
import UserEditTabTreatments from './treatments/UserEditTabTreatments.vue'
import UserEditTabOdontogram from './odontogram/UserEditTabOdontogram.vue'
import UserEditTabDocuments from './documents/UserEditTabDocuments.vue'
import UserViewUserInfoCard from './UserInfoCard.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BOverlay,

    UserEditTabAccount,
    UserEditTabFinances,
    UserEditTabTreatments,
    UserEditTabOdontogram,
    UserEditTabDocuments,
    UserViewUserInfoCard,
  },
  setup() {
    const userData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'store-patient'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, patientStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const tempAnamneseData = ref({})

    const fetchPatient = async () => store.dispatch('store-patient/fetchPatient', { id: router.currentRoute.params.id })
      .then(() => {
        userData.value = JSON.parse(JSON.stringify(store.getters['store-patient/patient']))
        return userData.value
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })

    fetchPatient()

    const refetchData = async () => {
      await fetchPatient()
    }

    return {
      userData,
      tempAnamneseData,
      refetchData,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";

.dark-layout {
  div ::v-deep {
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
  }
}

</style>
