<template>
  <!-- Procedimentos não aprovados -->
  <div
    class="mr-1"
  >
    <b-row>

      <!-- ========== Info Cards ========== -->
      <b-col
        cols="12"
        md="3"
      >
        <info-card
          icon="DollarSignIcon"
          :statistic="budgetData.totalCost.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
          statistic-title="Total"
          color="success"
        />
      </b-col>

      <b-col
        cols="12"
        md="3"
      >
        <info-card
          icon="ArrowUpRightIcon"
          :statistic="totalPaid.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
          statistic-title="Total Pago"
          color="primary"
        />
      </b-col>

      <b-col
        cols="12"
        md="3"
      >
        <info-card
          icon="ArrowDownRightIcon"
          :statistic="totalOpen.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
          statistic-title="Total em Aberto"
          color="danger"
        />
      </b-col>

      <b-col
        cols="12"
        md="3"
      >
        <info-card
          icon="AlertTriangleIcon"
          :statistic="financialAgreement.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
          statistic-title="Total em Acordo"
          color="warning"
        />
      </b-col>

      <!-- ========== Installment Title ========== -->
      <b-col
        cols="12"
        md="12"
      >
        <div class="d-flex justify-content-start pl-25">
          <feather-icon
            icon="ChevronsRightIcon"
            size="18"
            style="margin-top: 1px"
          />
          <h4
            class="ml-25"
          >
            Parcelamento
          </h4>
        </div>
        <hr class="mx-25 mt-0">
      </b-col>

      <!-- ========== Installment Form ========== -->
      <b-col
        cols="12"
        md="3"
      >
        <b-row class="pb-2">

          <!-- Initial Value -->
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Entrada"
              label-for="init-value"
            >
              <b-input-group>
                <b-input-group-prepend is-text>
                  <span>R$</span>
                </b-input-group-prepend>
                <currency-input
                  id="init-value"
                  v-model="entryValue"
                  trim
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- Initial Value Payment Date -->
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Data da entrada"
              label-for="init-value-payment-date"
            >
              <b-input-group>
                <b-form-input
                  id="init-value-payment-date"
                  v-model="entryDate"
                  trim
                  type="date"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- Number of installments -->
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Número de parcelas"
              label-for="installment-amount"
            >
              <b-input-group>
                <b-input-group-prepend is-text>
                  <span>Nº</span>
                </b-input-group-prepend>
                <b-form-input
                  id="installment-amount"
                  v-model="numberOfInstallments"
                  trim
                  type="number"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- First Installment Date -->
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Data da primeira parcela"
              label-for="first-installment-date"
            >
              <b-input-group>
                <b-form-input
                  id="first-installment-date"
                  v-model="firstInstallmentDate"
                  trim
                  type="date"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- ========== Form Buttons ========== -->

          <!-- Generate Installments -->
          <b-col
            cols="12"
            md="12"
          >
            <b-button
              variant="primary"
              block
              @click="createInstallments"
            >
              Gerar Parcelas
            </b-button>
          </b-col>

          <!-- Return to "pending" -->
          <b-col
            cols="12"
            md="12"
          >
            <b-dropdown
              :text="budgetMainButton()"
              split
              block
              right
              :disabled="!installments.length"
              :variant="isApproved ? 'success' : 'warning'"
              class="mt-1"
              menu-class="w-100"
              @click="sendBudgetData"
            >
              <b-dropdown-item @click="isApproved = !isApproved">
                <div class="d-flex align-items-center">
                  <feather-icon :icon="isApproved ? 'CheckSquareIcon' : 'SquareIcon'" />
                  <span class="pl-1">Efetivar ao {{ budgetData.budget.id ? 'salvar' : 'gerar' }}</span>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
      </b-col>

      <!-- ========== Installments ========== -->
      <b-col
        cols="12"
        md="9"
      >

        <b-col
          cols="12"
          md="12"
        >
          <b-table
            class="position-relative pt-1 tableMinHeght"
            responsive
            :items="installments"
            :fields="installmentsTableColumns"
            primary-key="id"
            show-empty
            empty-text="Nenhum resultado encontrado"
            no-sort-reset
          >
            <!-- Column: installmentDueDate -->
            <template #cell(installmentDueDate)="data">
              <b-input-group>
                <b-form-input
                  id="installment-due-date"
                  trim
                  type="date"
                  :value="data.item.installmentDueDate"
                />
              </b-input-group>
            </template>

            <!-- Column: installmentValue -->
            <template #cell(installmentValue)="data">
              {{ data.item.installmentValue && data.item.installmentValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
            </template>

            <!-- Column: paymentDate -->
            <template #cell(paymentDate)="data">
              {{ data.item.paymentDate && data.item.paymentDate === '' ? '' : data.item.paymentDate | dateTime('date') }}
            </template>

            <!-- Column: paidValue -->
            <template #cell(paidValue)="data">
              {{ data.item.paidValue && data.item.paidValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
            </template>

            <!-- Column: installmentSituation -->
            <template #cell(installmentSituation)="data">
              <div
                class="d-flex justify-content-start"
              >
                <b-badge
                  pill
                  :variant="`light-${data.item.installmentSituation === 'PAID' ? 'success' : (data.item.installmentSituation === 'NOT_PAID' ? 'danger' : 'primary')}`"
                  class="text-capitalize cursor-pointer"
                >
                  {{ data.item.installmentSituation === 'PAID' ? 'Pago' : data.item.installmentSituation === 'NOT_PAID' ? 'Aberto' : 'Acordo' }}
                </b-badge>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BInputGroup, BFormInput, BInputGroupPrepend, BButton,
  BTable, BBadge, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { toRefs } from '@vue/composition-api'
import CurrencyInput from '@/views/components/currency/CurrencyInput.vue'
import useFinances from './useFinances'
import InfoCard from '../cards/InfoCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BButton,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,

    InfoCard,
    CurrencyInput,
  },
  props: {
    budgetData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { budgetData } = toRefs(props)

    const {
      installmentsTableColumns,
      isApproved,
      installments,
      totalAfterDiscount,
      totalPaid,
      totalOpen,
      financialAgreement,
      entryValue,
      entryDate,
      numberOfInstallments,
      firstInstallmentDate,
      createInstallments,
      sendBudgetData,
      budgetMainButton,
      resetData,
      fillData,
    } = useFinances(budgetData, emit)

    return {
      installmentsTableColumns,
      isApproved,
      installments,
      totalAfterDiscount,
      totalPaid,
      totalOpen,
      financialAgreement,
      entryValue,
      entryDate,
      numberOfInstallments,
      firstInstallmentDate,
      sendBudgetData,
      createInstallments,
      budgetMainButton,
      resetData,
      fillData,
    }
  },
}
</script>

<style>

</style>
