<template>
  <validation-observer
    #default="{invalid}"
    ref="refFormObserver"
  >
    <b-modal
      id="installmentsModal"
      v-model="showLocal"
      centered
      size="lg"
      title="Alterar Parcelas"
      @hidden="$emit('hidden')"
    >

      <b-card
        no-body
        class="mb-0"
      >

        <b-form class="pl-2 pr-2">

          <b-row
            v-for="(installment, idx) in installments"
            :key="idx"
          >
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Valor"
                label-for="value-installment"
              >
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <span>R$</span>
                  </b-input-group-prepend>
                  <currency-input
                    id="value-installment"
                    v-model="installment.value_installment"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Parcela"
                label-for="num-installment"
              >
                <b-form-input
                  id="num-installment"
                  v-model="installment.installment"
                  type="number"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="3"
            >
              <validation-provider
                #default="validationContext"
                name="Vencimento"
                rules="required"
                immediate
              >
                <b-form-group
                  label="Vencimento"
                  label-for="due-date"
                >
                  <b-input-group>
                    <b-form-input
                      id="due-date"
                      v-model="installment.expiration_date"
                      :state="getValidationState(validationContext) ? null : false"
                      type="date"
                    />
                  </b-input-group>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >Campo obrigatório</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

          </b-row>
        </b-form>

      </b-card>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="primary"
          :disabled="invalid"
          @click="$emit('save', installments)"
        >
          Salvar
        </b-button>
        <b-button
          variant="outline-secondary"
          @click="cancel()"
        >
          Cancelar
        </b-button>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>

import {
  toRefs, ref, watch,
} from '@vue/composition-api'

import {
  BForm, BFormGroup, BFormInvalidFeedback, BInputGroup, BInputGroupPrepend,
  BCard, BRow, BCol, BFormInput, BButton,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import CurrencyInput from '@/views/components/currency/CurrencyInput.vue'

export default {
  components: {
    CurrencyInput,

    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,

  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    installments: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    localize('pt_BR') // vee-validate messages
    const showLocal = ref(false)
    // necessário para observar props
    const { show } = toRefs(props)
    watch(show, newValue => {
      showLocal.value = newValue
    })

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      showLocal,
      getValidationState,
      refFormObserver,
    }
  },
}
</script>

<style lang="scss">

</style>
