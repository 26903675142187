/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
<template>
  <b-card
    no-body
    style="width: 33px; height: 70px"
    class="text-center"
  >
    <div
      style="transform: scale(0.17)"
    >
      <img
        :id="`${teethData.number} - image`"
        style="margin: 300px 0px 0px -20px; transform: scale(1.6)"
        class="p-absolute cursor-pointer"
        :src="teethData.extracted ? require(`@/assets/images/teeth/t${teethData.number}_rm.png`) : require(`@/assets/images/teeth/t${teethData.number}.png`)"
        alt="#"
        @click="extractTeethModal"
      >
      <div
        style="width: 165px; height: 160px; padding-top: 5px; margin: 60px 0px 0px -50px"
        class="bg-color-gray p-absolute cursor-pointer"
      >
        <div
          :id="`${teethData.number} - lingual`"
          :style="`border-top: 35px solid ${teethData.lingual}; margin: 0px 0px 0px 12px`"
          class="trapezoid trapezoid-down p-absolute cursor-pointer"
          @mouseenter="hover('lingual')"
          @mouseleave="hoverOff('lingual')"
          @click="toggleModal('lingual')"
        >
          <b-tooltip
            :target="`${teethData.number} - lingual`"
            noninteractive
            no-fade
            :delay="delay"
          >
            {{ teethData.number }} - lingual
          </b-tooltip>
        </div>
        <div
          :id="`${teethData.number} - mesial`"
          :style="`border-top: 35px solid ${teethData.mesial}; margin: 58px 0px 0px 70px`"
          class="trapezoid trapezoid-down rotate-right cursor-pointer"
          @mouseenter="hover('mesial')"
          @mouseleave="hoverOff('mesial')"
          @click="toggleModal('mesial')"
        >
          <b-tooltip
            :target="`${teethData.number} - mesial`"
            noninteractive
            no-fade
            :delay="delay"
          >
            {{ teethData.number }} - mesial
          </b-tooltip>
        </div>
        <div
          :id="`${teethData.number} - distal`"
          :style="`border-top: 35px solid ${teethData.distal}; margin: 58px 0px 0px -45px`"
          class="trapezoid trapezoid-down rotate-left cursor-pointer"
          @mouseenter="hover('distal')"
          @mouseleave="hoverOff('distal')"
          @click="toggleModal('distal')"
        >
          <b-tooltip
            :target="`${teethData.number} - distal`"
            noninteractive
            no-fade
            :delay="delay"
          >
            {{ teethData.number }} - distal
          </b-tooltip>
        </div>
        <div
          :id="`${teethData.number} - vestibular`"
          :style="`border-top: 35px solid ${teethData.vestibular}; margin: 115px 0px 0px 12px;`"
          class="trapezoid trapezoid-down rotate-180 cursor-pointer"
          @mouseenter="hover('vestibular')"
          @mouseleave="hoverOff('vestibular')"
          @click="toggleModal('vestibular')"
        >
          <b-tooltip
            :target="`${teethData.number} - vestibular`"
            noninteractive
            no-fade
            :delay="delay"
          >
            {{ teethData.number }} - vestibular
          </b-tooltip>
        </div>
        <h1
          style="margin: -60px 0px 0px 0px; transform: scale(2)"
        >
          {{ teethData.number }}
        </h1>
      </div>
      <div
        :id="`${teethData.number} - oclusal`"
        :style="`width: 65px; height: 67px; margin: 106px 0px 0px 1px; background-color: ${teethData.oclusal}`"
        class="p-absolute cursor-pointer"
        @mouseenter="hoverOclusal"
        @mouseleave="hoverOclusalOff"
        @click="toggleModal('oclusal')"
      >
        <b-tooltip
          :target="`${teethData.number} - oclusal`"
          noninteractive
          no-fade
          :delay="delay"
        >
          {{ teethData.number }} - oclusal
        </b-tooltip>
      </div>
    </div>
  </b-card>
</template>

<script>
import { toRefs, ref } from '@vue/composition-api'
import { BCard, BTooltip } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTooltip,
  },

  props: {
    teethData: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, { emit }) {
    const { teethData } = toRefs(props)

    const handleTeeth = ref(null)

    const delay = ref({ show: 0, hide: 0 })

    const extracted = ref(false)

    const newSrc = ref(null)

    const extractedId = ref(null)

    const hover = face => {
      document.getElementById(`${teethData.value.number} - ${face}`).style.borderTop = '35px solid yellow'
    }

    const hoverOff = face => {
      document.getElementById(`${teethData.value.number} - ${face}`).style.borderTop = `35px solid ${teethData.value[face]}`
    }

    const hoverOclusal = () => {
      document.getElementById(`${teethData.value.number} - oclusal`).style.backgroundColor = 'yellow'
    }

    const hoverOclusalOff = () => {
      document.getElementById(`${teethData.value.number} - oclusal`).style.backgroundColor = `${teethData.value.oclusal}`
    }

    const toggleModal = face => {
      emit('showModal', teethData.value, face)
    }

    const extractTeethModal = () => {
      emit('showModalExtraction', teethData.value)
    }

    return {
      handleTeeth,
      delay,
      extracted,
      newSrc,
      extractedId,
      hover,
      hoverOff,
      hoverOclusal,
      hoverOclusalOff,
      toggleModal,
      extractTeethModal,
    }
  },
}

</script>

<style scoped>
body {
  font-family: Arial, Helvetica, sans-serif;
  color: #808080;
}

.img-sizing {
  height: 100px;
  width: 85px;
  position: absolute;
  top: 10px;
}

.trapezoid {
  height: 0;
  margin-bottom: 20px;
  width: 141px;
}

.trapezoid-down {
  border-left: 35px solid transparent;
  border-right: 35px solid transparent;
  border-top: 25px solid white;
  position: fixed;
}

.rotate-right {
  transform: rotate(90deg);
}

.rotate-left {
  transform: rotate(270deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.bg-color-gray {
  background-color: #808080;
}

.bg-color-white {
  background-color: white;
}

.p-absolute {
  position: absolute;
}

.flip-hor {
  transform: scaleX(-1);
}

.flip-ver {
  transform: scaleY(-1);
}

.full-border {
  border: 1px solid black;
}

.margin-1 {
  margin: 1px 1px 1px 1px;
}

.placeholder {
  position: absolute;
  bottom: 60px;
  width: 100%;
  height: 250px;
}

.div-size {
  max-height: 250px;
  max-width: 300px;
  display: inline-block;
}
</style>
