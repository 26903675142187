<template>
  <div>
    <b-modal
      id="modal-print"
      v-model="showPrintModal"
      centered
      size="lg"
      title="Imprimir"
      hide-footer
    >
      <b-overlay
        variant="white"
        :show="showLoading"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
      >

        <object
          :data="srcPdf"
          width="100%"
          style="height: 80vh;"
        />
      </b-overlay>
    </b-modal>

    <action-modal
      title="Evoluir Tratamento"
      :show="showActionModal"
      :data="data"
      @save="save"
      @hidden="showActionModal = false; reset()"
    />

    <b-row>
      <b-col
        cols="12"
        md="3"
      >
        <b-button
          variant="outline-dark"
          size="sm"
          @click="showFilters = !showFilters"
        >
          <feather-icon
            icon="ChevronRightIcon"
            size="12"
            :class="showFilters ? 'rotate' : 'undo-rotate'"
          />
          <span class="ml-25 align-middle">Filtros</span>
        </b-button>
      </b-col>

      <b-col
        cols="12"
        md="9"
        align="end"
      >
        <b-button
          variant="primary"
          size="sm"
          class="ml-1"
          :disabled="!userData.treatment_evolutions.filter(e => !e.deleted_at && e.treatment_type === 'ORTHODONTIC' && e.evolution_type === 'EVOLUTION').length"
          @click="print(blankData)"
        >
          <feather-icon
            icon="PrinterIcon"
            size="12"
          />
          <span class="ml-25 align-middle">Imprimir Evoluções</span>
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          class="ml-1"
          @click="showActionModal = true"
        >
          <feather-icon
            icon="PlusIcon"
            size="12"
          />
          <span class="ml-25 align-middle">Evoluir Tratamento</span>
        </b-button>
      </b-col>
    </b-row>

    <b-collapse
      v-model="showFilters"
      class="mt-2"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <label>Conteúdo</label>
          <b-form-input
            v-model="filter.content"
            placeholder="Buscar..."
          />
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <label>Dentista</label>
          <v-select
            v-model="filter.dentist_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="dentistOptions"
            :reduce="val => val.value"
            placeholder="Selecione..."
            :clearable="false"
          />
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col
          cols="12"
          md="6"
        >
          <label for="date-filter-beginning">De</label>
          <b-form-datepicker
            id="date-filter-beginning"
            v-model="filter.dateStart"
            :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
            v-bind="labels[locale] || {}"
            :locale="locale"
            :show-decade-nav="false"
            :state="isDateValidated ? null : false"
            hide-header
            placeholder="Início"
          />
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <label for="date-filter-beginning">Até</label>
          <b-form-datepicker
            id="date-filter-beginning"
            v-model="filter.dateEnd"
            :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
            v-bind="labels[locale] || {}"
            :locale="locale"
            :show-decade-nav="false"
            :state="isDateValidated ? null : false"
            hide-header
            placeholder="Fim"
          />
        </b-col>

      </b-row>
    </b-collapse>

    <!-- User Info: Input Fields -->
    <b-row>

      <!-- Field: Próteses -->
      <b-col
        cols="12"
        md="12"
        class="mt-2"
      >
        <b-table
          ref="refTable"
          class="position-relative pt-25 tableMinHeght"
          :fields="tableColumns"
          :items="filteredData"
          :tbody-tr-class="rowClass"
          show-empty
          empty-text="Nenhum resultado encontrado"
          no-sort-reset
        >
          <template #cell(content)="data">
            <small>{{ data.item.created_at | dateTime('dateTimeNoSecond', 'h') }} | {{ data.item.dentist.name }}</small>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="data.item.content" />
          </template>
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                v-if="!data.item.deleted_at"
                @click="handleEdit(data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="!data.item.deleted_at"
                @click="print(data.item)"
              >
                <feather-icon icon="PrinterIcon" />
                <span class="align-middle ml-50">Imprimir</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="!data.item.deleted_at"
                @click="handleCancel(data.item)"
              >
                <feather-icon icon="SlashIcon" />
                <span
                  class="align-middle ml-50"
                >Cancelar</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.deleted_at"
                @click="handleReactivate(data.item)"
              >
                <feather-icon icon="RotateCcwIcon" />
                <span
                  class="align-middle ml-50"
                >Reativar</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.deleted_at"
                @click="handleDelete(data.item)"
              >
                <feather-icon icon="Trash2Icon" />
                <span
                  class="align-middle ml-50"
                >Excluir</span>
              </b-dropdown-item>

            </b-dropdown>

          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BTable, BDropdown, BDropdownItem, BOverlay,
  BCollapse, BFormInput, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, toRefs } from '@vue/composition-api'
import ActionModal from './ActionModal.vue'
import useTreatments from '../../useTreatments'
import useFilter from './useFilter'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BCollapse,
    BFormInput,
    BFormDatepicker,

    ActionModal,

    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    dentistOptions: {
      type: Array,
      default: () => [],
    },
  },

  setup(props, { root }) {
    const { userData } = toRefs(props)

    // Modal Handlers LOCAL
    const blankData = ({
      treatment_type: 'ORTHODONTIC', // ORTHODONTIC - IMPLANT
      evolution_type: 'EVOLUTION', // ATTENDANCE - PLANNING - EVOLUTION
      content: '',
      user_id: userData.value.id, // Patient
    })

    const data = ref(JSON.parse(JSON.stringify(blankData)))
    const reset = () => {
      data.value = JSON.parse(JSON.stringify(blankData))
    }

    // Table Handlers LOCAL
    const tableColumns = [
      {
        key: 'content',
        label: 'Evolução',
        sortable: false,
      },
      {
        key: 'actions',
        label: 'ações',
        tdClass: 'actionsTdClass',
        sortable: false,
      },
    ]

    const {
      srcPdf,
      showPrintModal,
      showLoading,
      orthodonticsEvolutions,

      print,
      save,
      cancelData,
      reactiveData,
    } = useTreatments(userData)

    const {
      showActionModal,
      showFilters,
      filter,
      isDateValidated,
      filteredData,
    } = useFilter(userData, orthodonticsEvolutions)

    const handleEdit = dta => {
      data.value = dta
      showActionModal.value = true
    }

    const handleCancel = dta => {
      root.$bvModal
        .msgBoxConfirm('Deseja cancelar este registro?', {
          title: 'Por Favor Confirme esta Ação',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(resp => {
          if (resp) cancelData(dta)
        })
    }

    const handleDelete = dta => {
      root.$bvModal
        .msgBoxConfirm('Deseja excluir este registro?', {
          title: 'Por Favor Confirme esta Ação',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(resp => {
          if (resp) cancelData(dta, true)
        })
    }

    const handleReactivate = dta => {
      root.$bvModal
        .msgBoxConfirm('Deseja Reativar este Registro?', {
          title: 'Por Favor Confirme esta Ação',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) reactiveData(dta)
        })
    }

    const rowClass = (item, type) => {
      if (item && (type === 'row') && item.deleted_at) return 'strikeout'
      return null
    }

    const locale = ref('pt-BR')

    const locales = ref([
      { value: 'pt-BR', text: 'Portuguese BR (pt-BR)' },
      { value: 'en-US', text: 'English US (en-US)' },
    ])

    const labels = {
      'pt-BR': {
        labelPrevDecade: 'Década Anterior',
        labelPrevYear: 'Ano Anterior',
        labelPrevMonth: 'Mês Anterior',
        labelCurrentMonth: 'Mês Atual',
        labelNextMonth: 'Próximo Mês',
        labelNextYear: 'Próximo Ano',
        labelNextDecade: 'Próxima Década',
        labelToday: 'Hoje',
        labelSelected: 'Data Selecionada',
        labelNoDateSelected: 'Nenhuma Data Selecionada',
        labelCalendar: 'Calendário',
        labelNav: 'Navegação no Calendário',
        labelHelp: 'Use as setas do teclado para navegar',
      },
    }

    return {
      showActionModal,
      data,
      tableColumns,
      srcPdf,
      showPrintModal,
      showLoading,
      blankData,
      isDateValidated,
      filteredData,

      reset,
      print,
      handleEdit,
      handleCancel,
      handleDelete,
      handleReactivate,
      save,
      rowClass,
      showFilters,
      locale,
      locales,
      labels,
      filter,
    }
  },
}
</script>

<style lang="scss">

</style>
