<template>
  <b-modal
    id="modal-add-procedure-observation"
    v-model="showLocal"
    centered
    size="md"
    title="Adicionar Observação"
    @hidden="hidden"
  >

    <!-- {{ observationValue.observation }} -->

    <!-- Textarea -->
    <b-form-group
      label="Observação"
      label-for="procedure-observation"
      label-align-left
    >
      <b-form-textarea
        id="procedure-observation"
        v-model="observationValue"
      />
    </b-form-group>

    <template #modal-footer="{ cancel }">

      <b-button
        variant="outline-secondary"
        @click="cancel()"
      >
        Cancelar
      </b-button>
      <b-button
        variant="primary"
        @click="saveObservation"
      >
        Salvar
      </b-button>

    </template>

  </b-modal>
</template>

<script>
import { toRefs, ref, watch } from '@vue/composition-api'
import {
  BModal, BFormGroup, BFormTextarea, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormTextarea,
    BButton,

  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    procedure: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const showLocal = ref(false)
    const observationValue = ref(null)

    const { procedure } = toRefs(props)

    // necessário para observar props
    const { show } = toRefs(props)
    watch(show, newValue => {
      showLocal.value = newValue
      observationValue.value = procedure.value && procedure.value.observation
    })

    const hidden = () => {
      observationValue.value = null
      emit('hidden')
    }

    const saveObservation = () => {
      procedure.value.observation = observationValue.value
      emit('hidden')
    }

    return {
      showLocal,
      hidden,
      observationValue,
      saveObservation,
    }
  },
}
</script>

<style>

</style>
