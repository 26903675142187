<template>
  <div>
    <div>
      <template>
        <component :is="userData === undefined ? 'div' : 'b-card'">

          <b-tabs
            v-if="userData"
            pills
          >

            <!-- Tab: Procedimentos -->
            <b-tab active>
              <template #title>
                <feather-icon
                  icon=""
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Procedimentos</span>
              </template>
              <treatments-procedures
                ref="refTreatmentsProceduresTab"
                :user-data="userData"
                :dentist-options="dentistOptions"
                class="mt-2 pt-75"
              />
            </b-tab>

            <!-- Tab: Próteses -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon=""
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Ortodontia</span>
              </template>
              <treatments-orthodontics
                :user-data="userData"
                :dentist-options="dentistOptions"
                class="mt-2 pt-75"
              />
            </b-tab>

            <!-- Tab: Implantes -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon=""
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Implantes</span>
              </template>
              <treatments-implants
                :user-data="userData"
                :dentist-options="dentistOptions"
                class="mt-2 pt-75"
              />
            </b-tab>

          </b-tabs>
        </component>
      </template>
    </div>

  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BCard, BTabs, BTab,
} from 'bootstrap-vue'
import procedureCategoryStoreModule from '@/views/apps/procedures/procedureCategoryStoreModule'
import store from '@/store'
import { onUnmounted, computed, ref } from '@vue/composition-api'
import TreatmentsOrthodontics from './tabs/TreatmentsOrthodontics.vue'
import TreatmentsProcedures from './tabs/TreatmentsProcedures.vue'
import TreatmentsImplants from './tabs/TreatmentsImplants.vue'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BTabs,
    BTab,

    TreatmentsOrthodontics,
    TreatmentsImplants,
    TreatmentsProcedures,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const USER_APP_STORE_MODULE2_NAME = 'store-procedure-category'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE2_NAME)) store.registerModule(USER_APP_STORE_MODULE2_NAME, procedureCategoryStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE2_NAME)) store.unregisterModule(USER_APP_STORE_MODULE2_NAME)
    })

    const refTreatmentsProceduresTab = ref(null)

    store.dispatch('store-procedure-category/fetch')
    store.dispatch('app-user/fetchAll')
    const dentistOptions = computed(() => {
      const tmp = store.getters['app-user/forSelect']
      if (tmp) return tmp.filter(s => (s.data.roles[0].name === 'ADMIN' || s.data.roles[0].name === 'DENTIST'))
      return []
    })

    return {
      refTreatmentsProceduresTab,
      dentistOptions,
    }
  },
}
</script>
