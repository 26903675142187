import Vue from 'vue'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    requesting: true,
    file: {},
    files: [],
  },

  getters: {
    forSelect(state) {
      if (Array.isArray(state.files)) {
        const data = []
        // eslint-disable-next-line array-callback-return
        state.files.map(e => {
          data.push({
            value: e.id,
            label: e.name,
            data: e,
          })
        })
        data.sort((a, b) => a.label.localeCompare(b.label)) // Ordena por file
        return data
      }
      return []
    },
  },

  mutations: {
    SET(state, data) {
      state.file = data
    },

    SET_LIST(state, data) {
      state.files = data
    },

    SET_TO_EDIT(state, data) {
      Object.assign(state.files.data[data.index], { editMode: true })
      state.requesting = false
    },

    SET_TO_NO_EDIT(state, data) {
      Object.assign(state.files.data[data.index], { editMode: false })
      state.requesting = false
    },

    ADD_IN_LIST(state, data) {
      if (Array.isArray(state.files)) state.files.push(data)
    },

    UPDATE_IN_LIST(state, data) {
      if (Array.isArray(state.files)) {
        const idx = state.files.findIndex(o => o.id === data.id)
        if (idx >= 0) Vue.set(state.files, idx, data)
      }
    },

    DELETE_REGISTER(state, payload) {
      const idx = state.files.findIndex(o => o.id === payload)
      if (idx >= 0) {
        state.files.splice(idx, 1)
      }
      if (Array.isArray(state.files)) {
        const idx2 = state.files.findIndex(o => o.id === payload)
        if (idx2 >= 0) {
          state.files.splice(idx, 1)
        }
      }
      if (state.file && state.file.id === payload) {
        state.file = {}
      }
    },
  },

  actions: {
    async fetch({ state, commit }, args) {
      try {
        if (state.requesting && (!state.files.length || args)) {
          const resp = await axios.get(`${process.env.VUE_APP_API_URL}/files${args ? `?${new URLSearchParams(args).toString()}` : '/'}`)
          commit('SET_LIST', resp.data)
        }
        state.requesting = true
        return { data: state.files }
      } catch (e) {
        return e
      }
    },

    // fetchOne({ commit }, id) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get(`${process.env.VUE_APP_API_URL}/files/${id}`)
    //       .then(response => {
    //         commit('SET', response.data)
    //         commit('UPDATE_IN_LIST', response.data)
    //         return resolve(response)
    //       })
    //       .catch(error => reject(error))
    //   })
    // },

    add({ commit }, data) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('file', data.file)
        formData.append('id', data.id)
        formData.append('userId', data.userId)
        formData.append('name', data.name)
        formData.append('type', data.type)
        formData.append('path', 'documents')
        formData.append('category', 'documents')

        axios
          .post(`${process.env.VUE_APP_API_URL}/file`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then(response => {
            commit('SET', response.data)
            commit('ADD_IN_LIST', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    update({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/file/${data.id}`, data)
          .then(response => {
            commit('SET', response.data)
            commit('UPDATE_IN_LIST', response.data)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    async delete({ commit }, data) {
      try {
        const resp = await axios.delete(`${process.env.VUE_APP_API_URL}/file/${data.id}`)
        commit('DELETE_REGISTER', data.id)
        return resp
      } catch (e) {
        return e
      }
    },

    async getUrl(ctx, id) {
      try {
        const resp = await axios.get(`${process.env.VUE_APP_API_URL}/file/getUrl/${id}`)
        return resp.data
      } catch (e) {
        return e
      }
    },

  },
}
