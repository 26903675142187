<template>
  <b-modal
    id="modal-change-date"
    v-model="showLocal"
    centered
    size="md"
    title="Alterar Data de Criação do Orçamento"
    @hidden="hidden"
  >
    <div>
      <b-alert
        v-if="alertText"
        variant="warning"
        show
      >
        <h4 class="alert-heading">
          Atenção!
        </h4>
        <div class="alert-body">

          <p>
            {{ alertText }}
          </p>
        </div>
      </b-alert>
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            label="Senha de administrador"
            label-for="admin-password-input"
          >
            <b-input-group>
              <b-form-input
                id="admin-password-input"
                v-model="password"
                type="password"
                @input="showWarning = false"
              />
            </b-input-group>
            <small
              v-if="showWarning"
              class="mt-0 pt-0 text-danger"
            >Senha incorreta!</small>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div
      v-if="showDateField"
    >
      <hr>
      <b-form-group
        label="Nova data de Criação"
        label-for="new-budget-creation-date"
      >
        <b-input-group>
          <b-form-input
            id="new-budget-creation-date"
            v-model="newDate"
            trim
            type="date"
          />
        </b-input-group>
      </b-form-group>
    </div>
    <template #modal-footer="{ cancel }">
      <b-button
        variant="outline-secondary"
        @click="cancel()"
      >
        Cancelar
      </b-button>
      <b-button
        variant="primary"
        @click="confirmPassword(creationDate)"
        @keydown.enter="confirmPassword(creationDate)"
      >
        {{ showDateField === true ? 'Alterar Data' : 'Confirmar' }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { toRefs, ref, watch } from '@vue/composition-api'
import {
  BModal, BAlert, BFormGroup, BInputGroup,
  BFormInput, BButton, BCol, BRow,
} from 'bootstrap-vue'
import useRequestPassword from './useRequestPassword'

export default {
  components: {
    BModal,
    BAlert,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BButton,
    BCol,
    BRow,

  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    creationDate: {
      type: String,
      default: null,
    },
    alertText: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const showLocal = ref(false)

    // necessário para observar props
    const { show, creationDate } = toRefs(props)
    watch(show, newValue => {
      showLocal.value = newValue
    })
    const newDate = ref(creationDate.value)

    const {
      password,
      showWarning,
      showDateField,
      hidden,
      confirmPassword,
    } = useRequestPassword(emit, newDate)

    const confirmChanges = () => {
      emit('changeCreationDate', newDate)
      emit('hidden')
    }

    return {
      showLocal,
      password,
      showWarning,
      showDateField,
      newDate,
      hidden,
      confirmPassword,
      confirmChanges,
    }
  },
}
</script>

<style>

</style>
