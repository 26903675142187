<template>
  <validation-observer
    #default="{invalid}"
    ref="refFormObserver"
  >
    <b-modal
      id="new-anamnese-question-modal"
      v-model="showLocal"
      centered
      size="md"
      title="Nova Pergunta"
      @hidden="hidden"
    >
      <b-form
        @submit.prevent="onSubmit"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <validation-provider
              #default="{ errors }"
              name="pergunta"
              rules="required"
              immediate
            >
              <b-form-group
                label="Pergunta"
                label-for="question"
              >
                <b-form-input
                  id="question"
                  v-model="newQuestion.question"
                  autofocus
                  :state="errors.length > 0 ? false : null"
                  trim
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="12"
          >
            <validation-provider
              #default="{ errors }"
              name="Country"
              rules="required"
            >
              <b-form-group
                label="Tipo de pergunta"
                label-for="question-type"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  v-model="newQuestion.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="questionTypes"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="question-type"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-secondary"
          @click="cancel()"
        >
          Cancelar
        </b-button>
        <b-button
          variant="primary"
          :disabled="invalid"
          @click="save"
        >
          Inserir pergunta
        </b-button>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import { ref, toRefs, watch } from '@vue/composition-api'
import {
  BModal, BButton, BFormInvalidFeedback, BRow, BCol,
  BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'

export default {
  components: {
    BModal,
    BButton,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,

    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const showLocal = ref(false)
    const { show } = toRefs(props)
    watch(show, newValue => {
      showLocal.value = newValue
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const blankQuestion = {
      question: '',
      type: 'TEXT',
      text_answer: '',
      radio_answer: null,
      order: 0, // Alterar ao inserir
      alert: false,
    }

    const newQuestion = ref(JSON.parse(JSON.stringify(blankQuestion)))

    const questionTypes = [
      { label: 'Texto', value: 'TEXT', alert: false },
      { label: 'Sim ou não', value: 'RADIO', alert: false },
      { label: 'Sim ou não e texto', value: 'RADIO_TEXT', alert: false },
    ]

    const resetData = () => {
      newQuestion.value = JSON.parse(JSON.stringify(blankQuestion))
    }

    const hidden = () => {
      resetData()
      emit('hidden')
    }

    const save = () => {
      emit('saveQuestion', newQuestion.value)
      hidden()
    }

    return {
      showLocal,
      hidden,
      newQuestion,
      questionTypes,
      save,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style>

</style>
