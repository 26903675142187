<template>
  <validation-observer
    #default="{invalid}"
    ref="refFormObserver"
  >
    <b-modal
      id="editModal"
      v-model="showLocal"
      centered
      size="lg"
      title="Por Favor Confirme esta Ação"
      @hidden="$emit('hidden'); resetForm()"
    >

      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <b-form class="pl-2 pr-2">
          <b-row>

            <b-col
              cols="12"
              md="12"
            >
              <validation-provider
                #default="validationContext"
                name="Motivo"
                rules="required"
                immediate
              >

                <!-- Description -->
                <b-form-group
                  label="Qual o motivo do cancelamento ?"
                  label-for="description"
                >
                  <b-form-textarea
                    id="description"
                    v-model="reason"
                    rows="3"
                    trim
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>
        </b-form>

      </b-card>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-secondary"
          :disabled="invalid"
          @click="$emit('save', { reason, budget })"
        >
          Cancelar Orçamento
        </b-button>
        <b-button
          variant="primary"
          @click="cancel()"
        >
          Fechar
        </b-button>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>

import {
  toRefs, ref, watch,
} from '@vue/composition-api'

import {
  BFormTextarea, BForm, BFormGroup, BFormInvalidFeedback,
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormTextarea,
    BCard,
    BRow,
    BCol,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    budget: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    localize('pt_BR') // vee-validate messages
    const showLocal = ref(false)
    // necessário para observar props
    const { show } = toRefs(props)
    watch(show, newValue => {
      showLocal.value = newValue
    })

    const reason = ref('')

    const blankReason = ''

    const resetFormData = () => {
      reason.value = blankReason
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetFormData)

    return {
      showLocal,
      reason,
      // installment,
      getValidationState,
      resetForm,
      refFormObserver,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
