import { ref, computed } from '@vue/composition-api'

export default function useFilter(userData, treatments) {
  const showActionModal = ref(false)
  const showListModal = ref(false)

  const showFilters = ref(false)

  const filter = ref({
    dateStart: null,
    dateEnd: null,
    content: null,
    dentist_id: null,
  })

  const treatmentsToRemove = ref([])

  const contentFilter = () => {
    treatmentsToRemove.value.push(...treatments.value.filter(el => !el.content.toLowerCase().includes(filter.value.content.toLowerCase())))
  }

  const dentistFilter = () => {
    treatmentsToRemove.value.push(...treatments.value.filter(el => el.dentist_id !== filter.value.dentist_id))
  }

  const dateFilter = () => {
    if (filter.value.dateStart && !filter.value.dateEnd) {
      treatmentsToRemove.value.push(...treatments.value.filter(el => new Date(el.created_at.substr(0, 10).replace(/-/g, '/')) < new Date(filter.value.dateStart.replace(/-/g, '/'))))
    } else if (!filter.value.dateStart && filter.value.dateEnd) {
      treatmentsToRemove.value.push(...treatments.value.filter(el => new Date(el.created_at.substr(0, 10).replace(/-/g, '/')) > new Date(filter.value.dateEnd.replace(/-/g, '/'))))
    } else if (filter.value.dateStart && filter.value.dateEnd) {
      treatmentsToRemove.value.push(...treatments.value.filter(el => new Date(el.created_at.substr(0, 10).replace(/-/g, '/')) > new Date(filter.value.dateEnd.replace(/-/g, '/')) || new Date(el.created_at.substr(0, 10).replace(/-/g, '/')) < new Date(filter.value.dateStart.replace(/-/g, '/'))))
    }
  }

  const isDateValidated = ref(true)
  const returnDiff = (start, end) => (end - start)
  const validateDate = () => {
    const isValid = ref(true)
    if (filter.value.dateStart && filter.value.dateEnd) {
      const yearsDiff = returnDiff(Number(filter.value.dateStart.slice(0, 4)), Number(filter.value.dateEnd.slice(0, 4)))
      const monthDiff = returnDiff(Number(filter.value.dateStart.slice(5, 7)), Number(filter.value.dateEnd.slice(5, 7)))
      const daysDiff = returnDiff(Number(filter.value.dateStart.slice(8, 10)), Number(filter.value.dateEnd.slice(8, 10)))
      isValid.value = (yearsDiff > 0 || (yearsDiff === 0 && monthDiff > 0) || (yearsDiff === 0 && monthDiff === 0 && daysDiff >= 0))
    }
    isDateValidated.value = isValid.value

    if (isDateValidated.value) dateFilter()
  }

  const isFiltering = () => {
    const filtering = ref(false)
    Object.keys(filter.value).forEach(key => {
      if (filter.value[key] !== null && (filter.value[key].length > 0 || filter.value[key] > 0)) filtering.value = true
    })
    return filtering.value
  }

  const filteredData = computed(() => {
    treatmentsToRemove.value = []

    if (isFiltering()) {
      if (filter.value.content) contentFilter()
      if (filter.value.dentist_id) dentistFilter()
      if (filter.value.dateStart || filter.value.dateEnd) validateDate()

      return treatments.value.filter(el => !treatmentsToRemove.value.includes(el))
    }
    return treatments.value
  })

  return {
    showActionModal,
    showListModal,
    showFilters,
    filter,
    isDateValidated,
    filteredData,

    dentistFilter,
    validateDate,
  }
}
