<template>
  <div>
    <delete-modal
      :show="deleteModal"
      :budget="budget"
      @save="deleteData"
      @hidden="deleteModal = false"
    />

    <add-new-budget
      ref="refAddNewBudget"
      :user-data="userData"
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      @refetchData="refetchData"
    />

    <show-details-modal
      ref="refShowDetailsModal"
      :show="showDetailsModalShow"
      title="Detalhes do cancelamento"
      :details="cancelledBudgetDetails"
      @hidden="showDetailsModalShow = false"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-0">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-end mb-1"
          >
            <div class="d-flex align-items-center">
              <label>Exibir</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>resultados</label>
            </div>
          </b-col>

          <b-col
            cols="12"
            md="6"
            class="d-flex flex-row-reverse align-items-end mb-1"
          >
            <b-button
              variant="primary"
              @click="isAddNewUserSidebarActive = true"
            >
              <span class="text-nowrap">
                Adicionar Orçamento
              </span>
            </b-button>
          </b-col>

          <!-- Filter Type -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Filtrar por Status"
              label-for="status-filter"
            >
              <v-select
                id="status-filter"
                v-model="filter.status"
                :searchable="false"
                :clearable="false"
                :reduce="option => option.value"
                label="label"
                :options="statusOptions"
                placeholder="Selecione"
                multiple
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="3"
          >
            <div>
              <label for="date-filter-beginning">De</label>
              <b-form-datepicker
                id="date-filter-beginning"
                v-model="filter.dateStart"
                :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
                v-bind="labels[locale] || {}"
                :locale="locale"
                :show-decade-nav="false"
                :state="isDateValidated ? null : false"
                hide-header
                class="mb-1"
                placeholder="Criado em"
                @input="validateDate"
              />
            </div>
          </b-col>

          <b-col
            cols="12"
            md="3"
          >
            <div>
              <label for="date-filter-end">Até</label>
              <b-form-datepicker
                id="date-filter-end"
                v-model="filter.dateEnd"
                :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
                v-bind="labels[locale] || {}"
                :locale="locale"
                :show-decade-nav="false"
                :state="isDateValidated ? null : false"
                hide-header
                class="mb-1"
                placeholder="Criado em"
                @input="validateDate"
              />
            </div>
          </b-col>

          <b-col
            cols="12"
            md="3"
          >
            <label for="date-filter-end">Código</label>
            <b-form-input
              v-model="filter.code"
              placeholder="Buscar..."
              debounce="500"
            />
          </b-col>

        </b-row>

      </div>

      <b-overlay
        :show="showOverlay"
        rounded="sm"
        no-fade
      >
        <b-table
          ref="refBudgetListTable"
          class="position-relative tableMinHeght"
          :items="fetch"
          :tbody-tr-class="rowClass"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Nenhum resultado encontrado"
          :sort-desc.sync="isSortDirDesc"
          no-sort-reset
        >

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                v-if="data.item.status === 'PENDING'"
                @click="handleEdit(data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Alterar</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="data.item.status === 'PENDING'"
                @click="handleApplove(data)"
              >
                <feather-icon icon="CheckCircleIcon" />
                <span class="align-middle ml-50">Aprovar</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="data.item.status === 'PENDING'"
                @click="handleDelete(data.item)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Cancelar</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="data.item.status === 'CANCELED'"
                @click="showDetails(data.item)"
              >
                <feather-icon icon="InfoIcon" />
                <span class="align-middle ml-50">Info</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="printBudgetPdf(data.item.id)"
              >
                <feather-icon icon="PrinterIcon" />
                <span class="align-middle ml-50">Imprimir Orçamento</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.status === 'APPROVED'"
                @click="printContractList(data.item.id)"
              >
                <feather-icon icon="PrinterIcon" />
                <span class="align-middle ml-50">Imprimir Contrato</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>
      </b-overlay>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalData"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BFormGroup, BTable,
  BDropdown, BDropdownItem, BPagination, BFormDatepicker, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, computed, onUnmounted } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import store from '@/store'
import ShowDetailsModal from '@/views/components/showDetailsModal/ShowDetailsModal.vue'
import useBudget from './useBudget'
import DeleteModal from './DeleteModal.vue'

import budgetStoreModule from './budgetStoreModule'
import AddNewBudget from './AddNewBudget.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BFormGroup,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormDatepicker,
    BOverlay,

    AddNewBudget,
    DeleteModal,
    vSelect,
    ShowDetailsModal,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },

  setup(props, { root, emit }) {
    const BUDGET_STORE_MODULE_NAME = 'store-budget'

    // Register module
    if (!store.hasModule(BUDGET_STORE_MODULE_NAME)) store.registerModule(BUDGET_STORE_MODULE_NAME, budgetStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BUDGET_STORE_MODULE_NAME)) store.unregisterModule(BUDGET_STORE_MODULE_NAME)
    })

    // Use toast
    const toast = useToast()

    const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
      toast({
        component: ToastificationContent,
        props: {
          titleToast,
          icon,
          text,
          variant,
        },
      })
    }

    const isAddNewUserSidebarActive = ref(false)

    const {
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refBudgetListTable,
      deleteModal,
      statusOptions,
      filter,
      locale,
      locales,
      labels,
      isDateValidated,
      showOverlay,

      fetch,
      refetchData,
      deleteData,
      validateDate,
      sendBudgetData,
    } = useBudget(emit)

    const refAddNewBudget = ref(null)
    const editBudget = data => {
      isAddNewUserSidebarActive.value = true
      refAddNewBudget.value.fillData(JSON.parse(JSON.stringify(Object.assign(data))))
    }

    const printBudgetPdf = budgetId => refAddNewBudget.value.$refs.refBudgetGeneral.printBudgetPdf(budgetId)
    const printContractList = budgetId => refAddNewBudget.value.$refs.refBudgetGeneral.printContractList(budgetId)

    const handleEdit = data => {
      if (data.status === 'APPROVED') {
        showToast('danger', 'AlertCircleIcon', 'Atenção', 'Um orçamento só pode ser alterado previamente à sua efetivação.')
      } else {
        editBudget(data)
      }
    }

    const budget = ref({})
    const handleDelete = data => {
      budget.value = JSON.parse(JSON.stringify(data))
      deleteModal.value = true
    }

    const layoutSkin = computed(() => store.state.appConfig.layout.skin)
    const rowClass = (item, type) => {
      if (item && (type === 'row') && item.deleted_at) return 'strikeout'
      if (item && (type === 'row') && item.situation === 'EXPIRED') return layoutSkin.value === 'light' ? 'expired' : 'expired-dark'
      return null
    }

    const cancelledBudgetDetails = ref(null)
    const showDetailsModalShow = ref(false)
    const showDetails = data => {
      cancelledBudgetDetails.value = data.cancellations[0].reason
      showDetailsModalShow.value = true
    }

    const handleApplove = data => {
      root.$bvModal
        .msgBoxConfirm('Deseja aprovar este orçamento?', {
          title: 'Por Favor Confirme esta Ação',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(resp => {
          if (resp) sendBudgetData(data.item)
        })
    }

    return {
      isAddNewUserSidebarActive,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refBudgetListTable,
      refAddNewBudget,
      deleteModal,
      budget,
      Portuguese,
      ShowDetailsModal,
      showDetailsModalShow,
      cancelledBudgetDetails,
      statusOptions,
      filter,
      locale,
      locales,
      labels,
      isDateValidated,
      showOverlay,

      deleteData,
      rowClass,
      showDetails,
      validateDate,
      fetch,
      refetchData,
      editBudget,
      handleEdit,
      handleDelete,
      sendBudgetData,
      handleApplove,
      printBudgetPdf,
      printContractList,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
      // resolve a cor do overlay
      .dark-layout {
  div ::v-deep {
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
  }
}
</style>
