import { ref } from '@vue/composition-api'

export default function useRequestPassword(emit, newDate) {
  const password = ref(null)
  const showWarning = ref(false)
  const showDateField = ref(false)

  const hidden = () => {
    password.value = null
    showWarning.value = false
    showDateField.value = false
    emit('hidden')
  }

  const confirmPassword = creationDate => {
    if (password.value === '123456') {
      password.value = null
      if (creationDate) {
        showDateField.value = true
      } else {
        emit('passwordOk')
        hidden()
      }
    } else if (password.value !== '123456' && !showDateField.value) {
      showWarning.value = true
      password.value = null
    } else {
      emit('changeCreationDate', newDate)
      hidden()
    }
  }

  return {
    password,
    showWarning,
    showDateField,
    hidden,
    confirmPassword,
  }
}
