<template>
  <div>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Informações Adicionais -->
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            label="Informações Adicionais"
            label-for="misc-info"
          >
            <b-form-textarea
              id="misc-info"
              v-model="userData.observation"
              trim
              rows="10"
            />
          </b-form-group>

        </b-col>
      </b-row>

      <!-- Action Buttons -->
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="save(userData)"
      >
        Salvar Alterações
      </b-button>

    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormTextarea, BForm, BButton,
} from 'bootstrap-vue'
import useTabMiscInfo from './useTabMiscInfo'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BFormTextarea,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(ctx, { emit }) {
    const { save } = useTabMiscInfo(emit)
    return { save }
  },
}
</script>
