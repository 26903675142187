import Vue from 'vue'
import axios from '@axios'

const addressBlank = {
  street: null,
  public_place: null, // string nullable
  number: null,
  complement: null,
  neighborhood: null,
  zip_code: null,
  observation: null,
  type_address: 'RES',
  city_id: null,
  state_id: null,
}

export default {
  namespaced: true,

  state: {
    user: {},
    users: [],
    allUsers: [], // forSelect tenho as 2 situações (users e allUsers) na mesma página/componente
  },

  getters: {
    forSelect(state) {
      const data = []
      if (Array.isArray(state.allUsers)) {
        // eslint-disable-next-line array-callback-return
        state.allUsers.map(e => {
          data.push({
            value: e.id,
            label: e.name,
            data: e,
          })
        })
      }
      return data
    },

    user: state => {
      if (state.user && !state.user.address) {
        state.user.address = addressBlank
      }

      if (state.user && (!state.user.responsibles || !state.user.responsibles.length)) {
        state.user.responsible = {
          address: addressBlank,
        }
      } else if (state.user) {
        [state.user.responsible] = state.user.responsibles
        if (!state.user.responsible.address) state.user.responsible.address = addressBlank
      }

      if (!state.user.phones || !state.user.phones.length) {
        state.user.phones = [
          { phone: null, type: 'MOBILE' },
          { phone: null, type: 'PHONE' },
          { phone: null, type: 'OTHER' },
        ]
      }

      // ESTÁ ASSIM POIS A API ACEITA MUITAS ROLES PORÉM NO FRONT TRABALHAMOS SOMENTE COM UMA
      if (state.user.roles && Array.isArray(state.user.roles)) {
        const [role] = state.user.roles.map(p => p.name)
        state.user.role = role
      }

      // ESTÁ ASSIM POIS A API ACEITA MUITAS contas bancárias PORÉM NO FRONT TRABALHAMOS SOMENTE COM UMA
      if (state.user.bank_accounts && Array.isArray(state.user.bank_accounts)) {
        const [bankAccounts] = state.user.bank_accounts
        state.user.bank_accounts = bankAccounts || {}
      }

      // por default o user só pode ver a prípria agenda
      if (!state.user.schedule_view_users_id) {
        state.user.schedule_view_users_id = [state.user.id]
      }

      if (state.user.specialities) state.user.specialities = state.user.specialities.map(e => e.id ?? e)

      state.user.activity_data = {}
      if (!state.user.office_hour) {
        state.user.office_hour = {
          lunchBreakActivation: false,
          lunchBreakStart: null,
          lunchBreakEnd: null,
          stopScheduling: true,
          warnScheduleInconsistensy: false,
          schedulePeriodStart: null,
          schedulePeriodEnd: null,
          appointmentDuration: 30,
          days: [
            {
              value: 1,
              label: 'Domingo',
              activated: false,
              start: null,
              end: null,
            },
            {
              value: 2,
              label: 'Segunda',
              activated: false,
              start: null,
              end: null,
            },
            {
              value: 3,
              label: 'Terça',
              activated: false,
              start: null,
              end: null,
            },
            {
              value: 4,
              label: 'Quarta',
              activated: false,
              start: null,
              end: null,
            },
            {
              value: 5,
              label: 'Quinta',
              activated: false,
              start: null,
              end: null,
            },
            {
              value: 6,
              label: 'Sexta',
              activated: false,
              start: null,
              end: null,
            },
            {
              value: 7,
              label: 'Sábado',
              activated: false,
              start: null,
              end: null,
            },
          ],
        }
      }

      return state.user
    },
  },

  mutations: {
    SET(state, data) {
      state.user = data
    },

    SET_LIST(state, data) {
      state.users = data
    },

    SET_ALL(state, data) {
      state.allUsers = data
    },

    ADD_IN_LIST(state, data) {
      if (Array.isArray(state.users)) state.users.push(data)
      if (Array.isArray(state.allUsers)) state.allUsers.push(data)
    },

    UPDATE_IN_LIST(state, data) {
      if (Array.isArray(state.users)) {
        const idx = state.users.findIndex(o => o.id === data.id)
        if (idx >= 0) Vue.set(state.users, idx, data)
      }
      if (Array.isArray(state.allUsers)) {
        const idxAll = state.allUsers.findIndex(o => o.id === data.id)
        if (idxAll >= 0) Vue.set(state.allUsers, idxAll, data)
      }
    },

    DELETE_REGISTER(state, payload) {
      const idx = state.users.data.findIndex(o => o.id === payload)
      if (idx >= 0) {
        state.users.data.splice(idx, 1)
        state.users.total -= 1
      }
      if (Array.isArray(state.users)) {
        const idx2 = state.users.findIndex(o => o.id === payload)
        if (idx2 >= 0) {
          state.users.splice(idx, 1)
        }
      }
      if (Array.isArray(state.allUsers)) {
        const idxAll = state.users.findIndex(o => o.id === payload)
        if (idxAll >= 0) {
          state.allUsers.splice(idx, 1)
        }
      }
      if (state.user && state.user.id === payload) {
        state.user = {}
      }
    },
  },

  actions: {
    /**
     * TODO melhorar isso. É possível ter tudo somente em fetch
     * ESTA AQUI SERVE PARA AS LISTAS
     */
    async fetch({ state, commit }, args) {
      try {
        if (!state.users.length || args) {
          const resp = await axios.get(`${process.env.VUE_APP_API_URL}/users${args ? `?${new URLSearchParams(args).toString()}` : '/'}`)
          commit('SET_LIST', resp.data)
          return resp
        }
        return state.users
      } catch (e) {
        return e
      }
    },

    /**
     * TODO melhorar isso. É possível ter tudo somente em fetch
     * ESTA AQUI SERVE PARA OS DROPDOWNS
     * @param {*} param
     * @param {*} args
     * @returns
     */
    async fetchAll({ state, commit }, args) {
      try {
        if (!state.allUsers.length || args) {
          const resp = await axios.get(`${process.env.VUE_APP_API_URL}/users${args ? `?${new URLSearchParams(args).toString()}` : '/'}`)
          commit('SET_ALL', resp.data)
          return resp
        }
        return state.allUsers
      } catch (e) {
        return e
      }
    },

    fetchOne({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/users/${id}`)
          .then(response => {
            commit('SET', response.data)
            commit('UPDATE_IN_LIST', response.data)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    async delete({ commit }, user) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${process.env.VUE_APP_API_URL}/user/${user.id}`)
          .then(response => {
            commit('DELETE_REGISTER', user)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    async inactivate(ctx, user) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${process.env.VUE_APP_API_URL}/user/${user.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    async reactive({ commit }, user) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/user/reactive/${user.id}`)
          .then(response => {
            commit('UPDATE_IN_LIST', user)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    add({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/user`, data)
          .then(response => {
            commit('SET', response.data)
            commit('ADD_IN_LIST', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    update({ commit }, userData) {
      const handleUserData = JSON.parse(JSON.stringify(userData))
      handleUserData.photo = null // É NECESSÁRIO REMOVER DADOS DE PHOTO POIS A MESMA É ALTERADA EM MÉTODO PRÓPRIO
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/user/${userData.id}`, handleUserData)
          .then(response => {
            commit('SET', response.data)
            commit('UPDATE_IN_LIST', response.data)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    changePhoto({ commit }, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/user/change-photo/${userData.id}`, { photo: userData.photo })
          .then(response => {
            commit('SET', response.data)
            commit('UPDATE_IN_LIST', response.data)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
