/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable no-extend-native */
import {
  ref, computed, onUnmounted,
} from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useStoreHealthInsurances from '@/views/apps/health-insurances/useStoreHealthInsurances'

import store from '@/store'

export default function useFinances(budgetData, emit) {
  // AJUSTE DE VENCIMENTOS 30 DIAS EM MESES COM MENOS DE 31 DIAS
  Date.isLeapYear = year => (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0))
  Date.getDaysInMonth = (year, month) => [31, (Date.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month]
  Date.prototype.isLeapYear = function () { return Date.isLeapYear(this.getFullYear()) }
  Date.prototype.getDaysInMonth = function () { return Date.getDaysInMonth(this.getFullYear(), this.getMonth()) }
  Date.prototype.addMonths = function (value) {
    const n = this.getDate()
    this.setDate(1)
    this.setMonth(this.getMonth() + value)
    this.setDate(Math.min(n, this.getDaysInMonth()))
    // return `${this.getFullYear()}-0${this.getMonth() + 1}-${this.getDate()}`
    return this
  }

  const USER_APP_STORE_MODULE_NAME = 'store-health-insurance'

  // Register module
  if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, useStoreHealthInsurances)

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
  })

  store.dispatch('store-health-insurance/fetch')

  const healthInsuranceOptions = computed(() => store.getters['store-health-insurance/forSelect'])

  // Use toast
  const toast = useToast()

  const refProcedureListTable = ref(null)

  const healthInsurance = ref(null)

  const perPage = ref(10)
  const totalData = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50]
  const searchQuery = ref('')
  const sortBy = ref('procedure')
  const isSortDirDesc = ref(false)

  const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
    toast({
      component: ToastificationContent,
      props: {
        title: titleToast,
        icon,
        text,
        variant,
      },
    })
  }

  const catchErr = e => {
    if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'AlertCircleIcon', 'Atenção', e.response.data.message)
    else if (e.response.status === 401) showToast('danger', 'AlertCircleIcon', 'Atenção', 'Sua sessão expirou, faça novo login!')
    else showToast('danger', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
  }

  const today = ref(new Date().toISOString().split('T')[0])

  const installmentsTableColumns = ref([
    { label: 'Nº', key: 'installmentNumber' },
    { label: 'Vencimento', key: 'installmentDueDate' },
    { label: 'Valor', key: 'installmentValue' },
    { label: 'Pago em', key: 'paymentDate' },
    { label: 'Valor pago', key: 'paidValue' },
    { label: 'Status', key: 'installmentSituation' },
  ])

  const isApproved = ref(false)
  const installments = ref([])
  const totalPaid = computed(() => installments.value.filter(e => e.installmentSituation === 'PAID').reduce((accum, item) => accum + parseFloat(item.installmentValue), 0))
  const totalOpen = computed(() => installments.value.filter(e => e.installmentSituation === 'NOT_PAID').reduce((accum, item) => accum + parseFloat(item.installmentValue), 0))
  const financialAgreement = computed(() => installments.value.filter(e => e.installmentSituation === 'FINANCIAL_AGREEMENT').reduce((accum, item) => accum + parseFloat(item.installmentValue), 0))

  const entryValue = ref(0)

  const entryDate = ref(JSON.parse(JSON.stringify(today.value)))

  const numberOfInstallments = ref(1)

  const fillData = data => {
    budgetData.value.totalCost = data[0] ? data[0].value_total : 0
    installments.value = data
  }

  const firstInstallmentDate = ref(JSON.parse(JSON.stringify(today.value)))
  const frequency = ref('MONTHLY')

  const createInstallments = () => {
    installments.value = []
    let dueDate = new Date(`${firstInstallmentDate.value}T00:00:00`)
    let installmentValue = 0
    let step = entryValue.value > 0 ? -1 : 0

    for (step; step < numberOfInstallments.value; step += 1) {
      if (entryValue.value > 0 && step === -1) {
        installmentValue = entryValue.value
        dueDate = new Date(`${entryDate.value}T00:00:00`)
      } else if (entryValue.value > 0 && step >= 0) {
        installmentValue = (budgetData.value.totalCost - entryValue.value) / (numberOfInstallments.value)
      } else {
        installmentValue = budgetData.value.totalCost / numberOfInstallments.value
      }
      installments.value.push({
        installmentId: null,
        installmentDueDate: `${dueDate.getFullYear()}-${(`0${dueDate.getMonth() + 1}`).slice(-2)}-${(`0${dueDate.getDate()}`).slice(-2)}`,
        installmentNumber: step + 1,
        installmentValue: Math.round((installmentValue + Number.EPSILON) * 100) / 100, // Arredondamento necessário
        value_total: Math.round((budgetData.value.totalCost + Number.EPSILON) * 100) / 100, // Arredondamento necessário,
        installmentSituation: 'NOT_PAID', // AGREEMENT | PAID
        paymentDate: '',
        paidValue: '',
        // account_plan_id: installmentOptions.account_plan_id,
        type: 'CREDIT',
        origin: 'BUDGET',
        // description: installmentOptions.description,
      })

      if (frequency.value === 'MONTHLY') dueDate = new Date(`${firstInstallmentDate.value}T00:00:00`).addMonths(step + 1)
      if (frequency.value === 'BIWEEKLY') dueDate.setDate(dueDate.getDate() + 15)
      if (frequency.value === 'WEEKLY') dueDate.setDate(dueDate.getDate() + 7)
    }
  }

  const sendBudgetData = async () => {
    if (isApproved.value === true) {
      budgetData.value.budget.status = 'APPROVED'
    }
    budgetData.value.budget.installments = installments.value
    budgetData.value.budget.history = []
    try {
      if (budgetData.value.budget.id) await store.dispatch('store-budget/update', budgetData.value.budget)
      else await store.dispatch('store-budget/add', budgetData.value.budget)

      showToast()
      if (budgetData.value.budget.status === 'APPROVED') {
        emit('closeSideBar')
      }
      emit('refetchBudgets')
    } catch (e) {
      catchErr(e)
    }
  }

  const budgetMainButton = () => {
    let buttonValue = null
    let string = null

    if (!budgetData.value.budget.id) {
      string = 'Gerar'
    } else {
      string = 'Salvar'
    }

    buttonValue = `${string} orçamento`
    return buttonValue
  }

  const resetData = () => {
    budgetData.value.totalCost = 0
    installments.value = []
    entryValue.value = 0
    entryDate.value = JSON.parse(JSON.stringify(today.value))
    numberOfInstallments.value = 1
    firstInstallmentDate.value = today.value
  }

  return {
    perPage,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProcedureListTable,
    totalData,
    installmentsTableColumns,
    isApproved,
    installments,
    healthInsuranceOptions,
    healthInsurance,
    today,
    totalPaid,
    totalOpen,
    financialAgreement,
    entryValue,
    entryDate,
    numberOfInstallments,
    firstInstallmentDate,
    sendBudgetData,
    createInstallments,
    budgetMainButton,
    resetData,
    fillData,
  }
}
