import Vue from 'vue'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    anamneseModel: {},
    anamneseModels: [],
  },
  getters: {
    forSelect(state) {
      const data = []
      data.push({
        value: null,
        label: 'Modelo vazio',
        data: { questions: [] },
      })
      const result = state.anamneseModels.data || state.anamneseModels || []
      result.map(e => data.push({
        value: e.id,
        label: e.name,
        data: e,
      }))
      return data || []
    },
  },

  mutations: {
    SET(state, data) {
      state.anamneseModel = data
    },
    SET_LIST(state, data) {
      state.anamneseModels = data
    },
    ADD_IN_LIST(state, data) {
      if (Array.isArray(state.anamneseModels)) state.anamneseModels.push(data)
    },
    UPDATE_IN_LIST(state, data) {
      if (Array.isArray(state.anamneseModels)) {
        const idx = state.anamneseModels.findIndex(o => o.id === data.id)
        if (idx >= 0) Vue.set(state.anamneseModels, idx, data)
      }
    },
    DELETE_REGISTER(state, payload) {
      const idx = state.anamneseModels.data.findIndex(o => o.id === payload)
      if (idx >= 0) {
        state.anamneseModels.data.splice(idx, 1)
        state.anamneseModels.total -= 1
      }
      if (Array.isArray(state.anamneseModels)) {
        const idx2 = state.anamneseModels.findIndex(o => o.id === payload)
        if (idx2 >= 0) {
          state.anamneseModels.splice(idx, 1)
        }
      }
      if (state.anamneseModel && state.anamneseModel.id === payload) {
        state.anamneseModel = {}
      }
    },
  },

  actions: {
    async fetch({ state, commit }, args) {
      try {
        if (!state.anamneseModels.length || args) {
          const resp = await axios.get(`${process.env.VUE_APP_API_URL}/anamnese-models${args ? `?${new URLSearchParams(args).toString()}` : '/'}`)
          commit('SET_LIST', resp.data)
          return resp
        }
        return state.anamneseModels
      } catch (e) {
        return e
      }
    },

    fetchOne({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/anamnese-models/${id}`)
          .then(response => {
            commit('SET', response.data)
            commit('UPDATE_IN_LIST', response.data)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    add({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/anamnese-model`, data)
          .then(response => {
            commit('SET', response.data)
            commit('ADD_IN_LIST', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    update({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/anamnese-model/${data.id}`, data)
          .then(response => {
            commit('SET', response.data)
            commit('UPDATE_IN_LIST', response.data)
            return resolve(response)
          })
          .catch(e => {
            reject(e)
          })
      })
    },

    delete({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${process.env.VUE_APP_API_URL}/anamnese-model/${data.id}`)
          .then(response => {
            commit('DELETE_REGISTER', data.id)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
