/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["data"] }] */
/* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
import { ref } from '@vue/composition-api'
import axios from '@axios'

export default function useHistory() {
  const changeLogTableHandler = ref([
    { label: 'Código', key: 'id' },
    { label: 'Alterado em', key: 'updated_at' },
    { label: 'Responsável', key: 'user.name' },
    { label: 'Dentista', key: 'log.dentist.name' },
    { label: 'Ver', key: 'actions' },
  ])

  const changeLog = ref([])

  const fillData = data => {
    changeLog.value = data
  }

  const showModal = ref(false)
  const printBudgetPdfModal = ref(false)
  const showLoading = ref(false)
  const srcPdf = ref('')
  const printBudgetLogPdf = async data => {
    try {
      srcPdf.value = ''
      showModal.value = true
      showLoading.value = true
      const pdf = await axios.post(`${process.env.VUE_APP_API_URL}/pdf/print-budget-log`, { id: data.id })
      srcPdf.value = `data:application/pdf;base64,${pdf.data}`
      showLoading.value = false
    } catch (error) {
      showLoading.value = false
      // console.error(error)
    }
    printBudgetPdfModal.value = true
  }

  return {
    changeLogTableHandler,
    changeLog,
    srcPdf,
    showModal,
    showLoading,
    fillData,
    printBudgetLogPdf,
  }
}
