<template>
  <div>
    <b-dropdown
      variant="link-info"
      no-caret
    >
      <template
        #button-content
      >
        <feather-icon
          size="21"
          :icon="icon"
        />
      </template>
      <b-dropdown-form>
        <b-dropdown-item
          class="mt-0"
          @click="pdfClick"
        >
          PDF
        </b-dropdown-item>
        <b-dropdown-item
          class="mb-0"
          @click="excelClick"
        >
          Excel
        </b-dropdown-item>
      </b-dropdown-form>
    </b-dropdown>

    <!-- Modal Print -->
    <b-modal
      id="modal-print"
      v-model="showLocal"
      centered
      size="lg"
      title="Exportar"
      hide-footer
      @hidden="$emit('hidden')"
    >
      <object
        :data="srcPdf"
        width="100%"
        style="height: 600px;"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BDropdown, BDropdownForm, BDropdownItem,
} from 'bootstrap-vue'

import {
  toRefs, ref, watch,
} from '@vue/composition-api'

export default {
  components: {
    BDropdown,
    BDropdownForm,
    BDropdownItem,
  },
  props: {
    icon: {
      type: String,
      default: 'PrinterIcon',
    },
    pdfBase64: {
      type: String,
      default: null,
    },
    showPdfModal: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const showLocal = ref(false)
    // necessário para observar props
    const { showPdfModal } = toRefs(props)
    const { pdfBase64 } = toRefs(props)
    const srcPdf = ref('')
    watch(showPdfModal, newValue => {
      showLocal.value = newValue
    })
    watch(pdfBase64, newValue => {
      srcPdf.value = `data:application/pdf;base64,${newValue}`
    })

    const pdfClick = () => {
      emit('pdfClick')
    }

    const excelClick = () => {
      emit('excelClick')
    }

    return {
      showLocal,
      srcPdf,
      pdfClick,
      excelClick,
    }
  },
}
</script>
