<template>
  <div>
    <handle-observation
      :show="handleObservationShowModal"
      :procedure="procedureData"
      @save="save"
      @hidden="handleObservationShowModal = false"
    />

    <handle-new-procedure
      :show="handleNewProcedureShowModal"
      :procedures="procedures"
      :patient="userData"
      @add="add"
      @hidden="handleNewProcedureShowModal = false"
    />

    <handle-edit-modal
      :show="handleEditShowModal"
      :procedure="procedureData"
      @save="save"
      @hidden="handleEditShowModal = false"
    />

    <procedure-evolution-modal
      :title="finalizing ? 'Finalizar Procedimento' : 'Evoluir Procedimento'"
      :show="showActionModal"
      :evolution="evolution"
      :finalizing="finalizing"
      @save="saveEvolution"
      @hidden="showActionModal = false; finalizing = false; reset()"
    />

    <b-modal
      id="modal-print"
      v-model="showPrintModal"
      centered
      size="lg"
      title="Imprimir"
      hide-footer
    >
      <object
        :data="srcPdf"
        width="100%"
        style="height: 80vh;"
      />
    </b-modal>

    <b-form>
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
          <b-button
            variant="outline-dark"
            size="sm"
            @click="showFilters = !showFilters"
          >
            <feather-icon
              icon="ChevronRightIcon"
              size="12"
              :class="showFilters ? 'rotate' : 'undo-rotate'"
            />
            <span class="ml-25 align-middle">Filtros</span>
          </b-button>
        </b-col>
        <b-col
          cols="12"
          md="9"
          align="end"
        >
          <b-button
            variant="primary"
            size="sm"
            @click="handleNewProcedureShow"
          >
            <feather-icon
              icon="PlusIcon"
              size="12"
            />
            <span class="ml-25 align-middle">Adicionar Procedimento</span>
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="ml-1"
          >
            <feather-icon
              icon="PrinterIcon"
              size="12"
            />
            <span class="ml-25 align-middle">Imprimir Procedimentos</span>
          </b-button>
        </b-col>
      </b-row>

      <b-collapse
        v-model="showFilters"
        class="mt-2"
      >
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <label>Código ou conteúdo</label>
            <b-form-input
              v-model="filter.content"
              placeholder="Buscar..."
            />
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <label>Categoria</label>
            <v-select
              v-model="filter.category_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="procedureCategoriesOption"
              :reduce="val => val.value"
              placeholder="Selecione..."
              clearable
            />
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <label>Dentista</label>
            <v-select
              v-model="filter.dentist_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="dentistOptions"
              :reduce="val => val.value"
              placeholder="Selecione..."
              clearable
            />
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col
            cols="12"
            md="6"
          >
            <label for="date-filter-beginning">De</label>
            <b-form-datepicker
              id="date-filter-beginning"
              v-model="filter.dateStart"
              :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
              v-bind="labels[locale] || {}"
              :locale="locale"
              :show-decade-nav="false"
              :state="isDateValidated ? null : false"
              hide-header
              placeholder="Início"
            />
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <label for="date-filter-beginning">Até</label>
            <b-form-datepicker
              id="date-filter-beginning"
              v-model="filter.dateEnd"
              :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
              v-bind="labels[locale] || {}"
              :locale="locale"
              :show-decade-nav="false"
              :state="isDateValidated ? null : false"
              hide-header
              placeholder="Fim"
            />
          </b-col>

        </b-row>
      </b-collapse>

      <b-row>
        <b-col
          cols="12"
          md="12"
          class="mt-2"
        >
          <b-table
            ref="refTable"
            class="position-relative pt-25 tableMinHeght"
            :fields="tableColumns"
            :items="filteredProcedures"
            small
            :tbody-tr-class="rowClass"
            show-empty
            empty-text="Nenhum resultado encontrado"
            no-sort-reset
          >
            <template #cell(teeth_faces)="data">
              {{ data.item.pivot.teeth.tooth }} / {{ data.item.pivot.tooth_faces && data.item.pivot.tooth_faces.flatMap(e => e.face).toString().replace(/\["(.*?)\"]/g, '').replace(',', ' | ') }}
            </template>

            <template #cell(observation)="data">
              <div
                class="d-flex flex-row justify-content-between"
              >

                <div
                  :id="`observation-indicator-icon-${data.index}`"
                  class="align-self-center mr-50"
                >
                  <feather-icon
                    icon="InfoIcon"
                    class="cursor-pointer"
                    :class="{ 'text-primary': data.item.pivot.observation && data.item.pivot.observation !== '' }"
                    size="20"
                    @click="handleObservationShow(data)"
                  />
                  <b-tooltip
                    v-if="!data.item.pivot.deleted_at && data.item.pivot.observation && data.item.pivot.observation !== ''"
                    :target="`observation-indicator-icon-${data.index}`"
                    noninteractive
                    :delay="{show: 300, hide: 125}"
                  >
                    {{ data.item.pivot.observation }}
                  </b-tooltip>
                </div>

              </div>
            </template>

            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item
                  v-if="!data.item.pivot.deleted_at"
                  @click="addEvolution(data.item)"
                >
                  <feather-icon icon="CheckSquareIcon" />
                  <span class="align-middle ml-50">Evoluir</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="!data.item.pivot.deleted_at"
                  @click="handleEdit(data)"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Editar</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="!data.item.pivot.deleted_at"
                  @click="handleFinalization(data.item)"
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Finalizar</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="!data.item.pivot.deleted_at"
                  @click="print(data.item)"
                >
                  <feather-icon icon="PrinterIcon" />
                  <span class="align-middle ml-50">Imprimir</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="!data.item.pivot.deleted_at"
                  @click="handleCancel(data.item)"
                >
                  <feather-icon icon="SlashIcon" />
                  <span
                    class="align-middle ml-50"
                  >Cancelar</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.pivot.deleted_at"
                  @click="handleReactivate(data.item)"
                >
                  <feather-icon icon="RotateCcwIcon" />
                  <span
                    class="align-middle ml-50"
                  >Reativar</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.pivot.deleted_at"
                  @click="handleDelete(data.item)"
                >
                  <feather-icon icon="Trash2Icon" />
                  <span
                    class="align-middle ml-50"
                  >Excluir</span>
                </b-dropdown-item>

              </b-dropdown>

            </template>

          </b-table>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BButton, BTable, BDropdown, BDropdownItem,
  BTooltip, BCollapse, BFormInput, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { toRefs, computed } from '@vue/composition-api'
import store from '@/store'
import handleObservation from './handleObservation.vue'
import handleNewProcedure from './handleNewProcedure.vue'
import handleEditModal from './handleEditModal.vue'
import useTreatmentProcedures from '../../useTreatmentProcedures'
import ProcedureEvolutionModal from './ProcedureEvolutionModal.vue'
import useProcedureFilter from './useProcedureFilter'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BTooltip,
    BCollapse,
    BFormInput,
    BFormDatepicker,

    handleObservation,
    handleNewProcedure,
    handleEditModal,
    ProcedureEvolutionModal,

    vSelect,
  },

  props: {
    userData: {
      type: Object,
      required: true,
    },
    dentistOptions: {
      type: Array,
      default: () => [],
    },
  },

  setup(props, { root, emit }) {
    const procedureCategoriesOption = computed(() => store.getters['store-procedure-category/categoryProcedures'])
    const { userData } = toRefs(props)
    const {
      tableColumns,
      showModal,
      srcPdf,
      procedureData,
      handleObservationShowModal,
      handleNewProcedureShowModal,
      handleEditShowModal,
      showPrintModal,
      showLoading,
      dt,
      evolution,
      procedures,
      finalizing,

      print,
      add,
      save,
      saveEvolution,
      cancelData,
      reactiveData,
      handleObservationShow,
      handleNewProcedureShow,
      handleEdit,
      handleEvolution,
      handleFinalization,
      addEvolution,

      showActionModal,
      notFinishedProcedures,
      dentistCategories,
      locale,
      locales,
      labels,
      rowClass,
    } = useTreatmentProcedures(userData, root, emit)

    const {
      reset,
      showFilters,
      filter,
      isDateValidated,
      filteredProcedures,
    } = useProcedureFilter(userData, notFinishedProcedures, 'NOT_FINISHED')

    const handleReactivate = dta => {
      root.$bvModal
        .msgBoxConfirm('Deseja Reativar este Registro?', {
          title: 'Por Favor Confirme esta Ação',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) reactiveData(dta)
        })
    }

    const handleCancel = dta => {
      root.$bvModal
        .msgBoxConfirm('Deseja cancelar este registro?', {
          title: 'Por Favor Confirme esta Ação',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(resp => {
          if (resp) cancelData(dta)
        })
    }

    const handleDelete = dta => {
      root.$bvModal
        .msgBoxConfirm('Deseja Excluir este Procedimento?', {
          title: 'Por Favor Confirme esta Ação',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(resp => {
          if (resp) cancelData(dta, true)
        })
    }

    return {
      tableColumns,
      showModal,
      handleObservationShowModal,
      handleNewProcedureShowModal,
      handleEditShowModal,
      procedureData,
      srcPdf,
      showPrintModal,
      showLoading,
      showActionModal,
      dt,
      showFilters,
      locale,
      locales,
      labels,
      filter,
      notFinishedProcedures,
      dentistCategories,
      isDateValidated,
      procedureCategoriesOption,
      filteredProcedures,
      evolution,
      procedures,
      finalizing,

      reset,
      print,
      add,
      save,
      saveEvolution,
      handleEdit,
      handleEvolution,
      addEvolution,
      handleObservationShow,
      handleNewProcedureShow,
      handleDelete,
      handleFinalization,
      handleCancel,
      handleReactivate,
      rowClass,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.actionsTdClass {
  width: 116px;
}

.rotate {
  transform: rotate(90deg);
  transition: .15s all;
}

.undo-rotate {
  transform: rotate(0deg);
  transition: .15s all;
}
</style>
