<template>
  <div>
    <h3>
      <feather-icon
        icon="InfoIcon"
        size="16"
        class="align-middle text-body"
      />
      Tratamentos
    </h3>
    <h6
      v-for="(toothData, i) in teethData"
      :key="i"
    >
      {{ toothData.number }} - {{ toothData.face }} - {{ toothData.procedure }}
    </h6>
  </div>
</template>

<script>
import { toRefs } from '@vue/composition-api'

export default {
  props: {
    teethData: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    // eslint-disable-next-line no-unused-vars
    const { teethData } = toRefs(props)
  },
}
</script>

<style>

</style>
