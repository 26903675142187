<template>
  <div>

    <upload-modal
      :show="uploadDocumentShowModal"
      :user-data="userData"
      @hidden="uploadDocumentShowModal = false"
      @refreshData="refetchData"
    />

    <validation-observer
      #default="{invalid}"
      ref="refFormObserver"
    >

      <!-- Modal See File -->
      <b-modal
        id="modal-see-file"
        ref="modal-see-file"
        v-model="modalSeeFileShow"
        centered
        size="lg"
        title="Ver Documento"
        hide-footer
      >
        <embed
          :src="fileUrl"
          alt="Ver Documento"
          width="100%"
          height="600"
          :type="mimeType"
        >

        <!-- <object
          :data="fileUrl"
          width="100%"
          style="height: 600px;"
        /> -->
      </b-modal>

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0 pb-2 mt-2"
        >
          <label>Exibir</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end mt-2">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block"
              placeholder="Buscar..."
              debounce="500"
            />

          </div>
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="pb-2 mt-2"
        >
          <!-- Upload Button -->
          <b-button
            variant="primary"
            block
            @click="uploadDocumentModal"
          >
            Upload
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <!-- Documents List -->
        <b-table
          ref="refTable"
          class="position-relative tableMinHeght"
          :fields="tableColumns"
          :items="fetch"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Nenhum resultado encontrado"
          :sort-desc.sync="isSortDirDesc"
          no-sort-reset
        >
          <template #cell(name)="data">
            <span v-if="!data.item.editMode">
              {{ data.item.name }}
            </span>

            <b-row v-if="data.item.editMode === true">
              <b-col
                cols="12"
                md="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="Nome"
                  rules="required"
                  immediate
                >
                  <b-form-input
                    id="edit-doc-name"
                    v-model="docTempName"
                    :state="getValidationState(validationContext) ? null : false"
                    trim
                    @keydown.enter="confirmEditDocument(data)"
                  />
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-row>
          </template>

          <template #cell(type)="data">
            <span v-if="!data.item.editMode">
              {{ data.item.type === 'personal' ? 'Pessoal' : 'Outro' }}
            </span>

            <div
              v-if="data.item.editMode === true"
              class="d-flex justify-content-between"
            >
              <v-select
                v-model="docTempType"
                input-id="edit-doc-type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="docTypes"
                :clearable="false"
                :searchable="false"
                :reduce="val => val.value"
                style="min-width: 200px"
              />
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-button
                    variant="outline-primary"
                    block
                    :disabled="invalid"
                    @click="confirmEditDocument(data)"
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                      size="16"
                      class="align-middle"
                      style="text-color: #9900CC"
                    />
                  </b-button>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-button
                    variant="outline-danger"
                    block
                    @click="$store.commit('patient-store-file/SET_TO_NO_EDIT', data); refetchData()"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="16"
                      class="align-middle text-danger"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </template>

          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                @click="seeFile(data.item)"
              >
                <feather-icon icon="MaximizeIcon" />
                <span class="align-middle ml-50">Visualizar</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="activateEditMode(data)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="handleDeleteDocument(data.item)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Excluir</span>
              </b-dropdown-item>

            </b-dropdown>
          </template>

          <template #cell(docType)="data">
            <span>{{ data.item.docType }}</span>
          </template>
        </b-table>
      </b-row>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalData"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BTable, BPagination, BDropdown, BDropdownItem, BModal, BButton, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { onUnmounted, toRefs } from '@vue/composition-api'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'
import fileStoreModule from './fileStoreModule'
import useTabDocuments from './useTabDocuments'
import UploadModal from './UploadModal.vue'
import useUploadModal from './useUploadModal'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton,
    BFormInvalidFeedback,

    vSelect,
    UploadModal,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { root }) {
    const { userData } = toRefs(props)

    const DOCUMENT_STORE_MODULE = 'patient-store-file'

    // Register module
    if (!store.hasModule(DOCUMENT_STORE_MODULE)) store.registerModule(DOCUMENT_STORE_MODULE, fileStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DOCUMENT_STORE_MODULE)) store.unregisterModule(DOCUMENT_STORE_MODULE)
    })

    const {
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTable,
      uploadDocumentShowModal,
      docTempName,
      docTempType,
      fileUrl,
      mimeType,
      modalSeeFileShow,
      showLoading,

      seeFile,
      fetch,
      refetchData,
      deleteDocumentData,
      uploadDocumentModal,
      confirmEditDocument,
      activateEditMode,
    } = useTabDocuments(userData)

    const {
      docTypes,
    } = useUploadModal()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const handleDeleteDocument = data => {
      root.$bvModal
        .msgBoxConfirm('Deseja Excluir este documento?', {
          title: 'Por Favor Confirme esta Ação',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) deleteDocumentData(data)
        })
    }

    return {
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTable,
      modalSeeFileShow,
      fileUrl,
      mimeType,
      docTempName,
      docTempType,
      uploadDocumentShowModal,
      docTypes,
      refFormObserver,
      showLoading,

      seeFile,
      fetch,
      refetchData,
      deleteDocumentData,
      uploadDocumentModal,
      confirmEditDocument,
      activateEditMode,
      handleDeleteDocument,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
