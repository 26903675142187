<template>
  <div>
    <b-modal
      id="webcamPopup"
      v-model="popupActive"
      centered
      title="Foto"
    >
      <div
        id="webcam-loading"
        class="area_webcam vs-con-loading__container"
      >
        <video
          id="webCamera"
          autoplay="true"
        />
        <input
          id="base_img"
          type="hidden"
          name="base_img"
        >

      </div>
      <template #modal-footer="{}">
        <b-button
          block
          variant="primary"
          @click="takeSnapShot"
        >Tirar Foto</b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import {
  ref, watch, computed, nextTick,
} from '@vue/composition-api'

export default {
  name: 'WebcamPhoto',
  components: {
    BButton,
  },

  setup(props, { emit }) {
    const popupActive = ref(false)

    /**
     * Desliga a webcam
     * @param e
     * @returns {boolean}
     */
    const stopCamera = () => {
      const video = document.querySelector('#webCamera')
      if (video.srcObject) {
        const stream = video.srcObject
        const tracks = stream.getTracks()
        for (let i = 0; i < tracks.length; i += 1) {
          const track = tracks[i]
          track.stop()
        }
        video.srcObject = null
      } else {
        return false
      }
      return true
    }

    watch(popupActive, val => {
      if (!val) {
        stopCamera()
      }
    })

    const photo = computed({
      get: val => val,
      set: val => {
        emit('photo', val)
      },
    })

    const loadCamera = () => {
      popupActive.value = true
      // Captura elemento de vídeo
      nextTick(() => {
        const video = document.querySelector('#webCamera')
        // As opções abaixo são necessárias para o funcionamento correto no iOS
        video.setAttribute('autoplay', '')
        video.setAttribute('muted', '')
        video.setAttribute('playsinline', '')
        // Verifica se o navegador pode capturar mídia
        if (navigator.mediaDevices.getUserMedia) {
          navigator.mediaDevices.getUserMedia({ audio: false, video: { facingMode: 'user' } })
            .then(stream => {
              // Definir o elemento vídeo a carregar o capturado pela webcam
              video.srcObject = stream
            })
            .catch(() => {
              // console.error('Não foi possível carregar a webcam')
            })
        }
      })
    }

    /**
     * Envia imagem para o servidor
     * @param base64
     * @param
     */
    const sendSnapShot = base64 => {
      emit('snapShot', { base_img: base64 })
      popupActive.value = false
    }

    const takeSnapShot = () => {
      // Captura elemento de vídeo
      const video = document.querySelector('#webCamera')

      // Criando um canvas que vai guardar a imagem temporariamente
      const canvas = document.createElement('canvas')
      canvas.width = 200 // canvas.width = video.videoWidth;
      canvas.height = 200 // canvas.height = video.videoHeight;

      const ctx = canvas.getContext('2d')

      // Desenhando e convertendo as dimensões
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height)

      // Criando o JPG
      const dataURI = canvas.toDataURL('image/jpeg') // O resultado é um BASE64 de uma imagem.

      sendSnapShot(dataURI) // Gerar Imagem e Salvar Caminho no Banco
    }

    return {
      popupActive,
      photo,

      loadCamera,
      takeSnapShot,
      sendSnapShot,
      stopCamera,
    }
  },
}
</script>

<style>
  #webcamPopup .vs-popup {
    width: auto !important;
  }

  .area_webcam{
    margin: 0px auto;
    box-shadow: 0 10px 100px #ccc;
    padding: 0px;
    box-sizing: border-box;
    max-width: 600px;
  }
  .area_webcam video{
    width: 100%;
    height: auto;
    background-color: whitesmoke;
  }
  .area_webcam textarea_webcam{
    width: 100%;
    margin-top: 10px;
    height: 80px;
    box-sizing: border-box;
  }
  .area_webcam button{
    -webkit-appearance: none;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    text-align: center;
    background-color: #068c84;
    color: white;
    text-transform: uppercase;
    border: 1px solid white;
    box-shadow: 0 1px 5px #666;
  }
  .area_webcam button:focus{
    outline: none;
    background-color: #0989b0;
  }
  .area_webcam img{
    max-width: 100%;
    height: auto;
  }
  .area_webcam .caminho-imagem{
    padding: 5px 10px;
    border-radius: 3px;
    background-color: #068c84;
    text-align: center;
  }
  .area_webcam .caminho-imagem a{
    color: white;
    text-decoration: none;
  }
  .area_webcam .caminho-imagem a:hover{
    color: yellow;
  }
</style>
