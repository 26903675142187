<template>
  <div>
    <webcam-photo
      ref="webcam"
      @snapShot="onValueChanged"
    />

    <patient-status-modal
      :user-data="userData"
      :show="patientStatusShowModal"
      @hidden="patientStatusShowModal = false"
    />

    <patient-anamnese-modal
      :user-data="userData"
      :show="anamneseAlertShowModal"
      @hidden="anamneseAlertShowModal = false"
    />

    <b-card
      body-class="pb-0"
    >

      <div
        class="d-flex justify-content-between mb-2"
      >

        <!-- Patient Avatar -->
        <div
          class="d-flex align-self-center justify-content-center cursor-pointer"
          @mouseover="mouseOver = true"
          @mouseleave="mouseOver = false"
          @click="loadCamera"
        >
          <b-overlay
            :show="showOverlay"
            rounded="sm"
            no-fade
          >
            <b-avatar
              rounded="lg"
              variant="light-primary"
              size="125"
            >
              <feather-icon
                v-if="!userData.photo || !userData.photo.url"
                :icon="mouseOver === false ? 'UserIcon' : mouseOver === null ? 'UserIcon' : 'CameraIcon'"
                :size="mouseOver === false ? '85' : mouseOver === null ? '85' : '60'"
              />

              <div
                v-if="userData.photo && userData.photo.url"
                class="position-relative d-flex justify-content-center align-items-center"
              >
                <img
                  class="rounded"
                  style="width: 135px; height: 135px;"
                  :src="(userData.photo && userData.photo.url) ? userData.photo.url : userData.photo"
                  :alt="userData.name"
                >
                <feather-icon
                  v-if="mouseOver === true"
                  icon="CameraIcon"
                  size="60"
                  class="position-absolute"
                />
              </div>
            </b-avatar>
          </b-overlay>
        </div>

        <!-- Patient Info -->
        <div
          class="ml-1 my-1 p-1 border rounded"
          style="width: 36%; height: 135px;"
        >
          <div class="d-flex justify-content-between">
            <div
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <feather-icon
                icon="UserIcon"
                class="mb-25"
              />
              <span
                class="font-weight-bolder"
              >
                - {{ userData.name }}
              </span>
            </div>
            <div class="no-wrap">
              <b-badge
                pill
                :variant="userData.status === 1 ? 'light-success' : userData.status.value === 1 ? 'light-success' : 'light-danger'"
                class="text-capitalize cursor-pointer"
                @click="patientStatusModal"
              >
                <span>
                  {{ userData.status === 1 ? 'Ativo' : userData.status.value === 1 ? 'Ativo' : 'Inativo' }}
                </span>
              </b-badge>
              <b-badge
                pill
                :variant="userData.sub_status === 0 ? 'light-success' : userData.sub_status === 1 ? 'light-warning' : 'light-danger'"
                class="text-capitalize cursor-pointer ml-25"
                @click="patientStatusModal"
              >
                <span> <!-- 0 = normal / 1 = contenção / 2 = SPC / 3 = cancelado -->
                  {{ userData.sub_status === 0 ? 'Normal' : userData.sub_status === 1 ? 'Contenção' : userData.sub_status === 2 ? 'SPC' : userData.sub_status === 3 ? 'Cancelado' : '' }}
                </span>
              </b-badge>
            </div>
          </div>
          <hr class="my-50 py-0">
          <div
            class="d-flex flex-column justify-content-around align-items-start"
            style="height: 75px;"
          >
            <div>
              <feather-icon
                icon="FileTextIcon"
                class="mb-25"
              />
              <span>
                - {{ userData.id }}
              </span>
            </div>

            <div v-if="userData.phones && userData.phones.length > 0">
              <feather-icon
                icon="PhoneIcon"
                class="mb-25"
              />
              <span>
                - {{ userData.phones[0].phone }}
              </span>
            </div>

            <div v-if="userData.dental_plans && userData.dental_plans.length > 0">
              <feather-icon
                icon="ActivityIcon"
                class="mb-25"
              />
              <span>
                - {{ userData.dental_plans[0].name }}
              </span>
            </div>
          </div>
        </div>

        <!-- Active Side Menu -->
        <div
          class="m-1 p-1 border rounded "
          style="width: 44%; height: 135px;"
        >

          <!-- Tab Telefones -->
          <div v-if="sideMenu[1].active">
            <span class="my-0 py-0 font-weight-bolder">
              Telefones:
            </span>
            <hr class="my-50 py-0">
            <div
              v-if="userData.phones"
              class="d-flex flex-column justify-content-around"
              style="height: 75px;"
            >
              <div
                v-for="phone in userData.phones.slice(1, userData.phones.length)"
                :key="phone.id"
              >
                <feather-icon
                  icon="PhoneIcon"
                  class="mb-25 mr-25"
                />
                <span style="font-size: 15px">
                  - {{ phone.phone }}
                </span>
              </div>
              <div v-if="!userData.phones || (userData.phones.length <= 1)">
                <span style="font-size: 14px;">
                  Nenhum telefone opcional cadastrado, favor incluir um através da aba "cadastro" em "dados gerais".
                </span>
              </div>
            </div>
          </div>

          <!-- Tab Lembretes -->
          <div
            v-if="sideMenu[2].active"
          >
            <div class="d-flex justify-content-between">
              <span class="my-0 py-0 font-weight-bolder">
                Lembrete:
              </span>
              <div
                class="cursor-pointer"
                @click="saveReminder"
              >
                <small
                  class="text-muted"
                >
                  Salvar Lembrete -
                </small>
                <feather-icon
                  icon="CheckSquareIcon"
                  size="18"
                  class="text-success"
                />
              </div>
            </div>
            <hr class="my-50 py-0">
            <div>
              <b-form-group
                label-for="patient-reminder"
              >
                <b-form-textarea
                  id="patient-reminder"
                  v-model="userData.reminder"
                  trim
                  rows="2"
                  no-resize
                  placeholder="Para salvar um lembrete utilize o botão acima após inserir as informações necessárias aqui."
                />
              </b-form-group>
            </div>
          </div>

          <!-- Tab Planos -->
          <div
            v-if="sideMenu[3].active"
          >
            <span class="my-0 py-0 font-weight-bolder">
              Planos:
            </span>
            <hr class="my-50 py-0">
            <div
              v-if="userData.dental_plans"
              class="d-flex flex-column justify-content-around"
              style="height: 75px;"
            >
              <div
                v-for="plan in userData.dental_plans.slice(1, userData.dental_plans.length)"
                :key="plan.id"
              >
                <feather-icon
                  icon="ActivityIcon"
                  class="mb-25 mr-25"
                />
                <span style="font-size: 15px">
                  - {{ plan.name }}
                </span>
              </div>
              <div v-if="!userData.dental_plans || (userData.dental_plans.length <= 1)">
                <span style="font-size: 14px;">
                  Nenhum plano opcional cadastrado, favo incluir um através da aba "planos" em "dados gerais".
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- Side Menu -->
        <div
          id="side-menu"
          class="d-flex flex-column justify-content-around align-items-center border rounded vertical-menu mt-1"
        >
          <feather-icon
            v-for="(icon, idx) in sideMenu"
            :key="icon.key"
            :badge="idx === 0 ? userData.anamneses.map(e => e.questions).flat().filter(e => e.alert === true).length : icon.badge"
            class="cursor-pointer"
            badge-classes="badge-warning badge-glow"
            :class="{ 'text-primary': icon.active && anamneseAlertShowModal !== true || (icon.key === 'anamneseAlert' && anamneseAlertShowModal === true) }"
            :icon="icon.icon"
            size="18"
            @click="sideMenuActivation(icon)"
          />
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BOverlay, BAvatar, BBadge, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'
import WebcamPhoto from '@/views/components/webcam-photo/WebcamPhoto.vue'
import usePatientInfoCard from './usePatientInfoCard'
import PatientStatusModal from './PatientStatusModal.vue'
import PatientAnamneseModal from './PatientAnamneseModal.vue'

export default {
  components: {
    BCard,
    BOverlay,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormTextarea,

    WebcamPhoto,
    PatientStatusModal,
    PatientAnamneseModal,
  },

  props: {
    tempAnamneseData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      sideMenu,
      anamneseAlertShowModal,
      patientStatusShowModal,
      webcam,
      mouseOver,
      showOverlay,
      busyReminderTextArea,
      userData,
      celular,
      noUserPhoto,
      whatsappIcon,

      saveReminder,
      onValueChanged,
      sideMenuActivation,
      patientStatusModal,
      loadCamera,
    } = usePatientInfoCard(props)

    return {
      sideMenu,
      anamneseAlertShowModal,
      patientStatusShowModal,
      mouseOver,
      showOverlay,
      busyReminderTextArea,
      noUserPhoto,
      whatsappIcon,
      webcam,
      userData,
      celular,

      saveReminder,
      loadCamera,
      onValueChanged,
      sideMenuActivation,
      patientStatusModal,
    }
  },
}
</script>

<style lang="scss">
  .vertical-menu {
    min-width: 45px;
    width: 45px;
    height: 135px;
  }

  .no-wrap {
    white-space: nowrap;
  }
</style>
