// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { localize } from 'vee-validate'
import {
  ref, computed, onUnmounted,
} from '@vue/composition-api'
import useStoreHealthInsurances from '@/views/apps/health-insurances/useStoreHealthInsurances'

import store from '@/store'

export default function useTabAccountPlan(userData, emit) {
  localize('pt_BR') // vee-validate messages

  const USER_APP_STORE_MODULE_NAME = 'store-health-insurance'
  // Register module
  if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, useStoreHealthInsurances)
  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
  })

  let blankData = {
    healthInsurance: {
      id: userData.health_insurances.length ? userData.health_insurances[0].id : null,
      health_card: userData.health_insurances.length ? userData.health_insurances[0].pivot.health_card : null,
    },
    dentalPlans: userData.dental_plans.map(e => e.id),
  }

  const dataLocal = ref(JSON.parse(JSON.stringify(blankData)))
  const healthInsurances = computed(() => store.getters['store-health-insurance/forSelect'])

  const dentalPlansFiltered = ref([])

  const filterDentalPlans = () => healthInsurances.value
    .filter(hi => hi.value === dataLocal.value.healthInsurance.id)
    .map(dp => dp.dentalPlans)
    .flat()
    .map(e => ({ value: e.id, label: e.name }))
    .forEach(e => dentalPlansFiltered.value.push(e))

  dentalPlansFiltered.value = filterDentalPlans()

  const changeHealthInsurance = () => {
    dataLocal.value.dentalPlans = []
    dentalPlansFiltered.value = healthInsurances.value
      .filter(hi => hi.value === dataLocal.value.healthInsurance.id)
      .map(dp => dp.dentalPlans)
      .flat()
      .map(e => ({ value: e.id, label: e.name }))
  }

  const resetFormData = async () => {
    /**
     * Está estranho mas só funciona assim nos casos em que excluo o convênio saio e volto para a aba
     */
    dataLocal.value = JSON.parse(JSON.stringify(blankData))
    changeHealthInsurance()
    dataLocal.value = JSON.parse(JSON.stringify(blankData))
  }

  // Use toast
  const toast = useToast()

  const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
    toast({
      component: ToastificationContent,
      props: {
        titleToast,
        icon,
        text,
        variant,
      },
    })
  }

  const catchErr = e => {
    if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'AlertCircleIcon', 'Atenção', e.response.data.message)
    else if (e.response.status === 401) showToast('danger', 'AlertCircleIcon', 'Atenção', 'Sua sessão expirou, faça novo login!')
    else showToast('danger', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
  }

  const save = async patient => {
    try {
      const resp = await store.dispatch('store-patient/updateHealthInsuranecDentalPlans', { patient, healthInsuranceDentalPlans: dataLocal.value })
      emit('refreshUserData')
      blankData = JSON.parse(JSON.stringify({
        healthInsurance: {
          id: resp.data.health_insurances.length ? resp.data.health_insurances[0].id : null,
          health_card: resp.data.health_insurances.length ? resp.data.health_insurances[0].pivot.health_card : null,
        },
        dentalPlans: resp.data.dental_plans.map(e => e.id),
      }))

      showToast()
    } catch (e) {
      catchErr(e)
    }
  }

  return {
    dentalPlansFiltered,
    healthInsurances,
    dataLocal,

    changeHealthInsurance,
    save,
    resetFormData,
  }
}
