<template>
  <div>

    <new-anamnese-question-modal
      :show="showNewQuestionModal"
      @saveQuestion="newQuestion"
      @hidden="showNewQuestionModal = false"
    />

    <b-sidebar
      id="edit-anamnese-sidebar"
      :visible="isEditAnamneseSidebarActive"
      :sidebar-class="{
        'expanded-menu': !isVerticalMenuCollapsed,
        'retracted-menu': isVerticalMenuCollapsed,
        'no-xl' : currentBreakPoint && currentBreakPoint !== 'xl'
      }"
      bg-variant="white"
      no-header
      z-index="10"
      right
      @change="(val) => $emit('update:is-edit-anamnese-sidebar-active', val)"
      @hidden="resetQuestions"
    >

      <template #default="{ hide }">
        <!-- Header -->
        <div
          style="padding-top: 85px"
          class="d-flex content-sidebar-header"
        >
          <feather-icon
            class="cursor-pointer m-1"
            icon="ArrowLeftIcon"
            size="25"
            @click="hide"
          />
        </div>

        <div class="d-flex justify-content-between align-items-center px-2 py-1">
          <h5 class="mb-0">
            {{ isEditing ? 'Editar' : 'Criar' }} Anamnese
          </h5>
        </div>

        <div>
          <validation-observer
            #default="{invalid}"
            ref="refFormObserver"
          >
            <b-form
              class="p-2"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="8"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nome da anamnese"
                    rules="required"
                    immediate
                  >
                    <b-form-group
                      label="Nome da Anamnese"
                      label-for="anamnese-name"
                    >
                      <b-form-input
                        id="anamnese-name"
                        v-model="anamnese.identification"
                        autofocus
                        :state="errors.length > 0 ? false : null"
                        trim
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                  align="end"
                >
                  <b-button
                    variant="primary"
                    class="mt-2"
                    @click="showNewQuestionModal = true"
                  >
                    Nova Pergunta
                  </b-button>
                </b-col>
              </b-row>

              <draggable
                v-model="anamnese.questions"
                class="list-group list-group-flush mt-2"
                tag="ul"
              >
                <transition-group
                  type="transition"
                  name="flip-questions-list"
                >
                  <b-list-group-item
                    v-for="(questionItem, idx) in anamnese.questions"
                    :key="`questionItem_${idx}`"
                    tag="li"
                  >
                    <div class="d-flex align-items-center">
                      <feather-icon
                        icon="MenuIcon"
                        class="cursor-move"
                      />
                      <div class="d-flex w-100 flex-column ml-2">
                        <div class="d-flex align-items-center">
                          <feather-icon
                            v-if="questionItem.alert"
                            icon="AlertTriangleIcon"
                            class="text-warning mr-2"
                            size="16"
                          />
                          <span>{{ questionItem.question }}</span>
                        </div>
                        <b-form-group
                          :label="(questionItem.type !== 'RADIO' ? 'Resposta' : '')"
                          label-for="answer"
                          :class="[{'mt-2': questionItem.type !== 'RADIO' }]"
                        >
                          <b-form-input
                            v-if="questionItem.type !== 'RADIO'"
                            id="answer"
                            v-model="questionItem.text_answer"
                            trim
                          />
                          <b-form-radio-group
                            v-if="questionItem.type !== 'TEXT'"
                            v-model="questionItem.radio_answer"
                            :name="`questionItem_${idx}`"
                            :options="radioOptions"
                            class="demo-inline-spacing"
                          />
                        </b-form-group>
                      </div>
                      <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                      >

                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>

                        <b-dropdown-item @click="setAlert(questionItem)">
                          <feather-icon icon="AlertTriangleIcon" />
                          <span class="align-middle ml-50">Alerta</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="handleDelete(questionItem)">
                          <feather-icon icon="TrashIcon" />
                          <span
                            class="align-middle ml-50"
                          >Excluir</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </b-list-group-item>
                </transition-group>
              </draggable>

              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  variant="primary"
                  class="mr-2"
                  :disabled="invalid"
                  @click="save"
                >
                  Salvar
                </b-button>
                <b-button
                  type="button"
                  variant="outline-secondary"
                  @click="hide"
                >
                  Cancelar
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </template>

    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BButton, BRow, BCol, BFormGroup, BFormInput,
  BFormInvalidFeedback, BListGroupItem, BFormRadioGroup, BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref, toRefs, watch } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import draggable from 'vuedraggable'
import NewAnamneseQuestionModal from './NewAnamneseQuestionModal.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BListGroupItem,
    BFormRadioGroup,
    BDropdown,
    BDropdownItem,

    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,

    draggable,
    NewAnamneseQuestionModal,
  },

  model: {
    prop: 'isEditAnamneseSidebarActive',
    event: 'update:is-edit-anamnese-sidebar-active',
  },

  props: {
    isEditAnamneseSidebarActive: {
      type: Boolean,
      required: true,
    },
    anamneseModel: {
      type: Array,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isVerticalMenuCollapsed() {
      return this.$store.state.verticalMenu.isVerticalMenuCollapsed
    },
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
  },

  setup(props, { root, emit }) {
    const { userData } = toRefs(props)
    // Use toast
    const toast = useToast()

    const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
      toast({
        component: ToastificationContent,
        props: {
          titleToast,
          icon,
          text,
          variant,
        },
      },
      {
        timeout: 8000,
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const anamneseBlank = {
      id: null,
      identification: '',
      questions: [],
      user_id: userData.id,
    }
    const anamnese = ref(JSON.parse(JSON.stringify(anamneseBlank)))

    const isEditing = ref(false)

    const resetQuestions = () => {
      anamnese.value = JSON.parse(JSON.stringify(anamneseBlank))
      isEditing.value = false
    }

    const { anamneseModel, isEditAnamneseSidebarActive } = toRefs(props)

    watch(isEditAnamneseSidebarActive, newValue => {
      if (newValue && !isEditing.value) {
        anamnese.value.questions = JSON.parse(JSON.stringify(anamneseModel.value))
        anamnese.value.identification = `${userData.value.code} | ${userData.value.name}`
      }
    })

    const radioOptions = [
      { text: 'Sim', value: true },
      { text: 'Não', value: false },
    ]

    const showNewQuestionModal = ref(false)

    const newQuestion = data => {
      const question = data
      question.order = anamnese.value.questions.length // Ajustar order para o final
      anamnese.value.questions.push(question)
      showToast('success', 'CheckCircleIcon', 'Sucesso', 'A pergunta criada com sucesso.')
    }

    const removeQuestion = data => {
      const idx = anamnese.value.questions.findIndex(o => o === data)
      if (idx >= 0) {
        anamnese.value.questions.splice(idx, 1)
        showToast('danger', 'TrashIcon', 'Sucesso', 'A pergunta foi removida com sucesso.')
      } else {
        showToast('danger', 'AlertCircleIcon', 'Atenção', 'Não foi possível executar a ação desejada.')
      }
    }

    const setAlert = data => {
      const idx = anamnese.value.questions.findIndex(o => o === data)
      if (idx >= 0) {
        anamnese.value.questions[idx].alert = !anamnese.value.questions[idx].alert
        showToast('warning', 'AlertTriangleIcon', 'Sucesso', 'O alerta foi alterado com sucesso.')
      } else {
        showToast('danger', 'AlertCircleIcon', 'Atenção', 'Não foi possível executar a ação desejada.')
      }
    }

    const handleDelete = data => {
      root.$bvModal
        .msgBoxConfirm('Deseja excluir a pergunta?', {
          title: 'Por Favor Confirme esta Ação',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(resp => {
          if (resp) removeQuestion(data)
        })
    }

    const save = () => {
      emit('saveAnamnese', anamnese.value)
      emit('update:is-edit-anamnese-sidebar-active', false)
    }

    const fillData = anamneseData => {
      anamnese.value.id = anamneseData.id
      anamnese.value.identification = anamneseData.identification
      anamnese.value.questions = anamneseData.questions
    }

    return {
      refFormObserver,
      anamnese,
      radioOptions,
      isEditing,
      showNewQuestionModal,

      getValidationState,
      resetForm,
      newQuestion,
      removeQuestion,
      setAlert,
      handleDelete,
      resetQuestions,
      save,
      fillData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#edit-anamnese-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.expanded-menu {
  width: calc(100% - 288px);
}

.retracted-menu {
  width: calc(100% - 108px);
}

.no-xl {
  width: calc(100% - 28px);
}

.list-group-item {
  transition: all .4s
}
</style>
