import {
  ref, computed, onUnmounted,
} from '@vue/composition-api'
import { title } from '@core/utils/filter'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import useStoreAnamneseModels from '@/views/apps/anamnese-models/useStoreAnamneseModels'
import store from '@/store'
import useStoreAnamnese from './useStoreAnamnese'

export default function useAnamneseModel(emit, userData) {
  const ANAMNESE_MODEL_STORE_MODULE_NAME = 'store-anamnese-model'
  const ANAMNESE_STORE_MODULE_NAME = 'store-anamnese'

  // Register module
  if (!store.hasModule(ANAMNESE_MODEL_STORE_MODULE_NAME)) store.registerModule(ANAMNESE_MODEL_STORE_MODULE_NAME, useStoreAnamneseModels)
  if (!store.hasModule(ANAMNESE_STORE_MODULE_NAME)) store.registerModule(ANAMNESE_STORE_MODULE_NAME, useStoreAnamnese)

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(ANAMNESE_MODEL_STORE_MODULE_NAME)) store.unregisterModule(ANAMNESE_MODEL_STORE_MODULE_NAME)
    if (store.hasModule(ANAMNESE_STORE_MODULE_NAME)) store.unregisterModule(ANAMNESE_STORE_MODULE_NAME)
  })

  store.dispatch('store-anamnese-model/fetch')
  const anamneseModels = computed(() => store.getters['store-anamnese-model/forSelect'])

  const anamneseModel = ref(anamneseModels.value[0])

  // Use toast
  const toast = useToast()

  const refTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'identification',
      label: 'Nome',
      formatter: title,
      sortable: true,
    },
    {
      key: 'actions',
      label: 'ações',
      tdClass: 'actionsTdClass',
      sortable: false,
    },
  ]

  const refEditAnamneseSidebar = ref(null)
  const isEditAnamneseSidebarActive = ref(false)

  const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
    toast({
      component: ToastificationContent,
      props: {
        titleToast,
        icon,
        text,
        variant,
      },
    })
  }

  const catchErr = e => {
    if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'AlertCircleIcon', 'Atenção', e.response.data.message)
    else if (e.response.status === 401) showToast('danger', 'AlertCircleIcon', 'Atenção', 'Sua sessão expirou, faça novo login!')
    else showToast('danger', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
  }

  const saveAnamnese = async anamnese => {
    // ordering
    // eslint-disable-next-line no-param-reassign
    anamnese.questions = anamnese.questions.map((e, idx) => {
      e.order = idx + 1
      return e
    })

    const payload = {
      user_id: userData.value.id,
      anamnese,
    }

    try {
      let resp = null
      if (anamnese.id) {
        resp = await store.dispatch('store-anamnese/update', payload)
        const idx = userData.value.anamneses.findIndex(e => e.id === resp.data.id)
        userData.value.anamneses.splice(idx, 1, resp.data)
      } else {
        resp = await store.dispatch('store-anamnese/add', payload)
        userData.value.anamneses.push(resp.data)
      }
      showToast()
      emit('update:is-sidebar-active', false)
    } catch (e) {
      catchErr(e)
    }
  }

  const editAnamnese = anamneseData => {
    isEditAnamneseSidebarActive.value = true
    refEditAnamneseSidebar.value.isEditing = true
    refEditAnamneseSidebar.value.fillData(anamneseData)
  }

  const deleteData = async data => {
    try {
      await store.dispatch('store-anamnese/delete', data)
      const idx = userData.value.anamneses.findIndex(e => e.id === data.id)
      if (idx >= 0) {
        userData.value.anamneses.splice(idx, 1)
      }
      showToast('success', 'CheckIcon', 'Sucesso', 'Dados Excluídos com Sucesso.')
    } catch (e) {
      catchErr(e)
    }
  }

  const showModal = ref(false)
  const showLoading = ref(false)
  const srcPdf = ref('')

  const printAnamnese = async anamneseData => {
    try {
      srcPdf.value = ''
      showLoading.value = true
      showModal.value = true
      const pdf = await axios.get(`${process.env.VUE_APP_API_URL}/pdf/print-anamnese/${anamneseData.id}`)
      showLoading.value = false
      srcPdf.value = `data:application/pdf;base64,${pdf.data}`
    } catch (error) {
      showLoading.value = false
    }
  }

  return {
    tableColumns,
    refTable,
    anamneseModel,
    anamneseModels,
    isEditAnamneseSidebarActive,
    refEditAnamneseSidebar,
    saveAnamnese,
    editAnamnese,
    deleteData,
    showToast,
    catchErr,
    showModal,
    showLoading,
    printAnamnese,
    srcPdf,
  }
}
