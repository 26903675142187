<template>

  <div>
    <b-modal
      id="modal-print"
      v-model="showPrintModal"
      centered
      size="lg"
      title="Imprimir"
      hide-footer
    >
      <b-overlay
        variant="white"
        :show="showLoading"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
      >

        <object
          :data="srcPdf"
          width="100%"
          style="height: 80vh;"
        />
      </b-overlay>
    </b-modal>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="10"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block"
                placeholder="Buscar..."
                debounce="500"
              />

            </div>
          </b-col>

        </b-row>

      </div>

      <b-table
        ref="refTable"
        class="position-relative tableMinHeght"
        :items="fetch"
        :tbody-tr-class="rowClass"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum resultado encontrado"
        :sort-desc.sync="isSortDirDesc"
        no-sort-reset
      >
        <template #cell(type)="data">
          {{ handleType(data.item.type) }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <feather-icon
            v-if="!data.item.deleted_at"
            icon="PrinterIcon"
            size="16"
            class="align-middle text-body cursor-pointer"
            @click="print(data.item)"
          />
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalData"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, toRefs } from '@vue/composition-api'
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import useDocumentTemplates from '@/views/apps/document-templates/useDocumentTemplates'
import useMain from '@/views/apps/document-templates/list/side-bar-content/useMain'
import axios from '@axios'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BOverlay,

    vSelect,
  },

  props: {
    userData: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const { userData } = toRefs(props)

    const {
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTable,
      documentTemplate,
      documentType,
      refetchData,
      fetch,
    } = useDocumentTemplates()

    const { handleType } = useMain()

    // Tipos devem ser passados separados por vírgula ex 'PRESCRIPTION, GENERAL'
    documentType.value = 'PRESCRIPTION'

    const showPrintModal = ref(false)
    const srcPdf = ref('')
    const showLoading = ref(false)

    const print = async data => {
      Object.assign(data, { userId: userData.value.id })
      showPrintModal.value = true
      showLoading.value = true
      const pdf = await axios.post(`${process.env.VUE_APP_API_URL}/export-document-template`, data)
      showLoading.value = false
      srcPdf.value = `data:application/pdf;base64,${pdf.data}`
    }

    const refreshDData = () => {
      documentTemplate.value = JSON.parse(JSON.stringify(store.getters['store-document-templates/user']))
    }

    const rowClass = (item, type) => {
      if (item && (type === 'row') && item.deleted_at) return 'strikeout'
      return null
    }

    return {
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTable,
      documentTemplate,
      showPrintModal,
      srcPdf,
      showLoading,

      handleType,
      rowClass,
      refreshDData,
      fetch,
      refetchData,
      print,
    }
  },
}
</script>

<style lang="scss">
  .codeTdClass {
    width: 150px;
  }
  .statusTdClass {
    width: 120px;
  }
  .actionsTdClass {
    width: 120px;
  }
</style>
