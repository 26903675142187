<template>
  <div>
    <odontogram :user-data="userData" />
  </div>
</template>

<script>
import Odontogram from '@/views/components/odontogram/Odontogram.vue'

export default {
  components: {
    Odontogram,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
}
</script>
