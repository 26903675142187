<template>
  <b-modal
    id="modal-add-procedure-observation"
    v-model="showLocal"
    centered
    size="md"
    title="Editar Procedimento"
    @hidden="hidden"
  >
    <h5>{{ procedureData && procedureData.procedure }}</h5>

    <b-form-group
      label="Dente"
      label-for="tooth-id"
    >
      <v-select
        v-model="procedureData.pivot.teeth.id"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="mixedToothList"
        :reduce="option => option.value"
        :clearable="false"
        input-id="tooth-id"
      />

    </b-form-group>

    <b-form-group
      label="Face(s)"
      label-for="face-id"
    >
      <v-select
        id="face-id"
        v-model="procedureData.pivot.tooth_face_id"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="$store.getters['toothFaces/getToothFaces']"
        :reduce="option => option.value"
        :clearable="false"
        multiple
      />
    </b-form-group>

    <template #modal-footer="{ cancel }">

      <b-button
        variant="outline-secondary"
        @click="cancel()"
      >
        Cancelar
      </b-button>
      <b-button
        variant="primary"
        @click="save"
      >
        Salvar
      </b-button>

    </template>

  </b-modal>
</template>

<script>
import {
  toRefs, ref, watch, computed,
} from '@vue/composition-api'
import {
  BModal, BFormGroup, BButton,
} from 'bootstrap-vue'
import store from '@/store'

import vSelect from 'vue-select'

export default {
  components: {
    BModal,
    BFormGroup,
    BButton,

    vSelect,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    procedure: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const showLocal = ref(false)
    const procedureData = ref({
      pivot: {
        teeth: {
          id: 0,
        },
      },
    })

    // fetch teeth on API
    store.dispatch('toothFaces/fetch')
    store.dispatch('teeth/fetch')

    const mixedToothList = computed(() => store.getters['teeth/getAllTeeth'])

    // necessário para observar props
    const { show, procedure } = toRefs(props)
    watch(show, newValue => {
      showLocal.value = newValue
      procedureData.value = JSON.parse(JSON.stringify(procedure.value))
      procedureData.value.pivot.tooth_face_id = procedureData.value.pivot.tooth_faces ? procedureData.value.pivot.tooth_faces.map(e => e.id) : []
      procedureData.value.pivot.teeth_id = JSON.stringify(procedureData.value.pivot.teeth.id)
    })

    const hidden = () => {
      procedureData.value = {
        pivot: {
          teeth: {
            id: 0,
          },
        },
      }
      emit('hidden')
    }

    const save = () => {
      procedureData.value.pivot.teeth_id = JSON.stringify(procedureData.value.pivot.teeth.id)
      emit('save', procedureData.value)
      emit('hidden')
    }

    return {
      showLocal,
      mixedToothList,
      procedureData,
      hidden,
      save,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
