import {
  ref, watch, computed, onUnmounted, toRefs,
} from '@vue/composition-api'
import { title, currency } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import procedureCategoryStoreModule from '@/views/apps/procedures/procedureCategoryStoreModule'
import priceListStoreModule from '@/views/apps/price-list/priceListStoreModule'

import store from '@/store'

export default function useUsersList(props) {
  const USER_APP_STORE_MODULE_NAME = 'store-price-list'
  const USER_APP_STORE_MODULE2_NAME = 'store-procedure-category'

  // Register Module
  if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, priceListStoreModule)
  if (!store.hasModule(USER_APP_STORE_MODULE2_NAME)) store.registerModule(USER_APP_STORE_MODULE2_NAME, procedureCategoryStoreModule)

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    if (store.hasModule(USER_APP_STORE_MODULE2_NAME)) store.unregisterModule(USER_APP_STORE_MODULE2_NAME)
  })

  // Use toast
  const toast = useToast()

  const refProcedureListTable = ref(null)

  const perPage = ref(10)
  const totalData = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50]
  const searchQuery = ref('')
  const sortBy = ref('procedure')
  const isSortDirDesc = ref(false)
  const categoryFilter = ref(null)
  const showLoading = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refProcedureListTable.value ? refProcedureListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalData.value,
    }
  })

  // const priceListId = ref(null)
  const { priceListId } = toRefs(props)

  const refetchData = () => {
    // eslint-disable-next-line no-unused-expressions
    refProcedureListTable.value && refProcedureListTable.value.refresh()
  }

  const procedureCategoriesOption = computed(() => store.getters['store-procedure-category/categoryProcedures'])

  watch([currentPage, perPage, searchQuery, categoryFilter, priceListId], () => {
    refetchData()
  })

  const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
    toast({
      component: ToastificationContent,
      props: {
        title: titleToast,
        icon,
        text,
        variant,
      },
    })
  }

  const fetchProcedureCategories = () => {
    store
      .dispatch('store-procedure-category/fetch')
      .then(response => response)
      .catch(e => {
        let message = 'Erro ao consultar categorias de procedimentos!'
        if (e.response.status === 401) message = 'Sua sessão expirou, faça novo login!'
        showToast('danger', 'AlertTriangleIcon', message, null)
      })
  }

  const fetchProceduresPriceList = (ctx, callback) => {
    showLoading.value = true
    store
      .dispatch('store-price-list/fetchProceduresPriceList', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sort: isSortDirDesc.value ? 'desc' : 'asc',
        procedure_category_id: categoryFilter.value || '',
        price_list_id: priceListId.value || 0,
      })
      .then(response => {
        showLoading.value = false
        const { data, total } = response.data
        callback(data)
        totalData.value = total
      })
      .catch(e => {
        showLoading.value = false
        let message = 'Erro ao preencher lista de procedimentos!'
        if (e.response.status === 401) message = 'Sua sessão expirou, faça novo login!'
        toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const tableItems = ref([])

  const selected = ref([])

  const onRowSelected = items => {
    selected.value = items
  }

  // Table Handlers
  const tableColumns = [
    {
      key: 'selectAll',
      label: '#',
      sortable: false,
      tdClass: 'selectAllTdClass',
    },
    {
      key: 'code',
      label: 'Cód.',
      sortable: true,
      tdClass: 'priceTdClass',
    },
    {
      key: 'procedure_category.procedure_category',
      label: 'Categoria',
      formatter: title,
      sortable: true,
    },
    { key: 'procedure', label: 'Procedimento', sortable: true },
    {
      key: 'price',
      label: 'Preço',
      formatter: currency,
      sortable: true,
      tdClass: 'priceTdClass',
    },
  ]

  const dentalPlan = ref(null)

  const addProceduresShowModal = ref(false)

  const today = ref(new Date().toISOString().split('T')[0])

  // *===============================================---*
  // *--------- ADD TREATMENTS TAB ---------------------*
  // *===============================================---*

  const procedureTableColumns = ref([
    { label: '#', key: 'hash' },
    { label: 'Código', key: 'code' },
    { label: 'Categoria', key: 'category' },
    { label: 'Procedimento', key: 'procedure' },
    { label: 'Valor', key: 'cost' },
  ])

  return {
    perPage,
    currentPage,
    priceListId,
    dataMeta,
    perPageOptions,
    searchQuery,
    categoryFilter,
    showLoading,
    sortBy,
    isSortDirDesc,
    refProcedureListTable,
    totalData,
    procedureCategoriesOption,
    dentalPlan,
    addProceduresShowModal,
    procedureTableColumns,
    today,
    tableItems,
    selected,
    tableColumns,

    refetchData,
    fetchProcedureCategories,
    onRowSelected,
    fetchProceduresPriceList,
  }
}
