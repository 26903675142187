<template>
  <div>

    <b-row>

      <b-col
        cols="12"
        md="8"
      >
        <div>
          <!-- <hr style="position: relative; top: 206px"> -->
          <div style="border-left: 1px solid gray; height: 390px; position: absolute; left: 50%; margin-left: 2px; top: 0;" />
          <div style="border-top: 1px solid gray; width: 70%; position: absolute; top: 192px; left: 15%" />
          <b-row>

            <!-- 4th Quad -->
            <b-col
              cols="12"
              md="6"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  class="d-flex justify-content-end"
                >
                  <polygon-comp
                    id="18"
                    ref="tooth18"
                    :teeth-data="teethData[0]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp
                    id="17"
                    ref="tooth17"
                    :teeth-data="teethData[1]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp
                    id="16"
                    ref="tooth16"
                    :teeth-data="teethData[2]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp
                    id="15"
                    ref="tooth15"
                    :teeth-data="teethData[3]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp
                    id="14"
                    ref="tooth14"
                    :teeth-data="teethData[4]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp
                    id="13"
                    ref="tooth13"
                    :teeth-data="teethData[5]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp
                    id="12"
                    ref="tooth12"
                    :teeth-data="teethData[6]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp
                    id="11"
                    ref="tooth11"
                    :teeth-data="teethData[7]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  class="d-flex justify-content-end"
                >
                  <polygon-comp
                    id="55"
                    ref="tooth55"
                    :teeth-data="teethData[8]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp
                    id="54"
                    ref="tooth54"
                    :teeth-data="teethData[9]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp
                    id="53"
                    ref="tooth53"
                    :teeth-data="teethData[10]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp
                    id="52"
                    ref="tooth52"
                    :teeth-data="teethData[11]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp
                    id="51"
                    ref="tooth51"
                    :teeth-data="teethData[12]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                </b-col>
              </b-row>
            </b-col>

            <!-- 1st Quad -->
            <b-col
              cols="12"
              md="6"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  class="d-flex justify-content-start"
                >
                  <polygon-comp
                    id="21"
                    ref="tooth21"
                    :teeth-data="teethData[13]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp
                    id="22"
                    ref="tooth22"
                    :teeth-data="teethData[14]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp
                    id="23"
                    ref="tooth23"
                    :teeth-data="teethData[15]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp
                    id="24"
                    ref="tooth24"
                    :teeth-data="teethData[16]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp
                    id="25"
                    ref="tooth25"
                    :teeth-data="teethData[17]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp
                    id="26"
                    ref="tooth26"
                    :teeth-data="teethData[18]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp
                    id="27"
                    ref="tooth27"
                    :teeth-data="teethData[19]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp
                    id="28"
                    ref="tooth28"
                    :teeth-data="teethData[20]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  class="d-flex justify-content-start"
                >
                  <polygon-comp
                    id="61"
                    ref="tooth61"
                    :teeth-data="teethData[21]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp
                    id="62"
                    ref="tooth62"
                    :teeth-data="teethData[22]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp
                    id="63"
                    ref="tooth63"
                    :teeth-data="teethData[23]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp
                    id="64"
                    ref="tooth64"
                    :teeth-data="teethData[24]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp
                    id="65"
                    ref="tooth65"
                    :teeth-data="teethData[25]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                </b-col>
              </b-row>
            </b-col>

            <!-- 3rd Quad -->
            <b-col
              cols="12"
              md="6"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  class="d-flex justify-content-end"
                >
                  <polygon-comp-inv
                    id="71"
                    ref="tooth71"
                    :teeth-data="teethData[26]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp-inv
                    id="72"
                    ref="tooth72"
                    :teeth-data="teethData[27]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp-inv
                    id="73"
                    ref="tooth73"
                    :teeth-data="teethData[28]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp-inv
                    id="74"
                    ref="tooth74"
                    :teeth-data="teethData[29]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp-inv
                    id="75"
                    ref="tooth75"
                    :teeth-data="teethData[30]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  class="d-flex justify-content-end"
                >
                  <polygon-comp-inv
                    id="31"
                    ref="tooth31"
                    :teeth-data="teethData[31]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp-inv
                    id="32"
                    ref="tooth32"
                    :teeth-data="teethData[32]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp-inv
                    id="33"
                    ref="tooth33"
                    :teeth-data="teethData[33]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp-inv
                    id="34"
                    ref="tooth34"
                    :teeth-data="teethData[34]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp-inv
                    id="35"
                    ref="tooth35"
                    :teeth-data="teethData[35]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp-inv
                    id="36"
                    ref="tooth36"
                    :teeth-data="teethData[36]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp-inv
                    id="37"
                    ref="tooth37"
                    :teeth-data="teethData[37]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp-inv
                    id="38"
                    ref="tooth38"
                    :teeth-data="teethData[38]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                </b-col>
              </b-row>
            </b-col>

            <!-- 2nd Quad -->
            <b-col
              cols="12"
              md="6"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  class="d-flex justify-content-start"
                >
                  <polygon-comp-inv
                    id="85"
                    ref="tooth85"
                    :teeth-data="teethData[39]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp-inv
                    id="84"
                    ref="tooth84"
                    :teeth-data="teethData[40]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp-inv
                    id="83"
                    ref="tooth83"
                    :teeth-data="teethData[41]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp-inv
                    id="82"
                    ref="tooth82"
                    :teeth-data="teethData[42]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp-inv
                    id="81"
                    ref="tooth81"
                    :teeth-data="teethData[43]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  class="d-flex justify-content-start"
                >
                  <polygon-comp-inv
                    id="48"
                    ref="tooth48"
                    :teeth-data="teethData[44]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp-inv
                    id="47"
                    ref="tooth47"
                    :teeth-data="teethData[45]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp-inv
                    id="46"
                    ref="tooth46"
                    :teeth-data="teethData[46]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp-inv
                    id="45"
                    ref="tooth45"
                    :teeth-data="teethData[47]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp-inv
                    id="44"
                    ref="tooth44"
                    :teeth-data="teethData[48]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp-inv
                    id="43"
                    ref="tooth43"
                    :teeth-data="teethData[49]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp-inv
                    id="42"
                    ref="tooth42"
                    :teeth-data="teethData[50]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                  <polygon-comp-inv
                    id="41"
                    ref="tooth41"
                    :teeth-data="teethData[51]"
                    @showModal="importProcedures"
                    @showModalExtraction="extractionShowModal"
                  />
                </b-col>
              </b-row>
            </b-col>

          </b-row>
        </div>
      </b-col>

      <b-col
        cols="12"
        md="4"
      >
        <info-pannel
          :teeth-data="teethData.filter(e => e.lingualTreatment.procedure || e.mesialTreatment.procedure || e.distalTreatment.procedure || e.vestibularTreatment.procedure || e.oclusalTreatment.procedure).map(e => {
            const ret = []
            if (e.lingualTreatment.procedure) ret.push({number: e.number, face: 'lingual', procedure: e.lingualTreatment.procedure})
            if (e.mesialTreatment.procedure) ret.push({number: e.number, face: 'mesial', procedure: e.mesialTreatment.procedure})
            if (e.distalTreatment.procedure) ret.push({number: e.number, face: 'distal', procedure: e.distalTreatment.procedure})
            if (e.vestibularTreatment.procedure) ret.push({number: e.number, face: 'vestibular', procedure: e.vestibularTreatment.procedure})
            if (e.oclusalTreatment.procedure) ret.push({number: e.number, face: 'oclusal', procedure: e.oclusalTreatment.procedure})
            return ret
          }).flat()"
          :user-data="userData"
        />
      </b-col>

      <!-- Spacer -->
      <b-col
        cols="12"
        md="10"
      />

      <!-- PDF Button -->
      <b-col
        cols="12"
        md="2"
      >
        <b-button
          block
          variant="primary"
          @click="printPdf"
        >
          <feather-icon
            size="18"
            icon="PrinterIcon"
          />
          <span class="ml-1">PDF</span>
        </b-button>

      </b-col>

    </b-row>

    <!-- Modal: Procedures -->
    <add-procedure
      ref="refModalAddProcedure"
      :show="importProceduresShowModal"
      :active-teeth-data="activeTeethData"
      @hidden="importProceduresShowModal = false"
      @updateTeeth="updData"
    />

    <!-- Modal Print -->
    <b-modal
      id="modal-print"
      ref="modal-print"
      v-model="modalPrintShow"
      centered
      size="lg"
      title="Exportar"
      hide-footer
    >
      <b-overlay
        variant="white"
        :show="showLoading"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
      >

        <object
          :data="srcPdf"
          width="100%"
          style="height: 80vh;"
        />
      </b-overlay>
    </b-modal>

  </div>
</template>

<script>
import Vue from 'vue'
import { ref, toRefs, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BButton, BOverlay,
} from 'bootstrap-vue'
import store from '@/store'
import axios from '@axios'
import PolygonComp from './polygon/PolygonComp.vue'
import PolygonCompInv from './polygon/PolygonCompInv.vue'
import InfoPannel from './infoPannel/InfoPannel.vue'
import AddProcedure from './addProcedure/AddProcedure.vue'
import odontogramHandler from './odontogramHandler'

// Store Module
import odontogramStoreModule from './odontogramStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BOverlay,

    PolygonComp,
    PolygonCompInv,
    InfoPannel,
    AddProcedure,
  },

  props: {
    userData: {
      type: Object,
      required: true,
    },
  },

  setup(props, { root }) {
    const USER_APP_STORE_MODULE_NAME = 'store-odontogram'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, odontogramStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const { userData } = toRefs(props)
    const importProceduresShowModal = ref(false)
    const extractionShow = ref(false)
    const refModalAddProcedure = ref(null)
    const activeTeethData = ref({ id: null, face: null })
    const showLoading = ref(false)

    const {
      fetch,
      save,
    } = odontogramHandler()

    const odontogram = ref(null)

    const teethData = ref([
      {
        id: null,
        number: 18,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 17,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 16,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 15,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 14,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 13,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 12,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 11,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 55,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 54,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 53,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 52,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 51,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 21,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 22,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 23,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 24,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 25,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 26,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 27,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 28,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 61,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 62,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 63,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 64,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 65,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 85,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 84,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 83,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 82,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 81,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 48,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 47,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 46,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 45,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 44,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 43,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 42,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 41,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 71,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 72,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 73,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 74,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 75,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 31,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 32,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 33,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 34,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 35,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 36,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 37,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
      {
        id: null,
        number: 38,
        extracted: false,
        lingual: 'white',
        lingualTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        mesial: 'white',
        mesialTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        distal: 'white',
        distalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        vestibular: 'white',
        vestibularTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
        oclusal: 'white',
        oclusalTreatment: {
          procedure_id: null,
          procedure: null,
          observation: '',
        },
      },
    ])
    fetch(userData.value).then(resp => {
      odontogram.value = resp
      odontogram.value.teeth.forEach(el => {
        const idx = teethData.value.findIndex(e => e.number === parseInt(el.number, 10))
        if (idx >= 0) {
          teethData.value[idx].id = el.id
          teethData.value[idx].extracted = !!el.pivot.extracted
          if (el.toothFace) {
            teethData.value[idx][el.toothFace.face.toLowerCase()] = el.pivot.color
            teethData.value[idx][`${el.toothFace.face.toLowerCase()}Treatment`].procedure_id = el.procedure.id
            teethData.value[idx][`${el.toothFace.face.toLowerCase()}Treatment`].procedure = el.procedure.procedure
            teethData.value[idx][`${el.toothFace.face.toLowerCase()}Treatment`].observation = el.pivot.observation
          }
        }
      })
    })

    const modalPrintShow = ref(false)
    const srcPdf = ref('')

    const printPdf = async () => {
      try {
        srcPdf.value = ''
        modalPrintShow.value = true
        showLoading.value = true
        const pdf = await axios.get(`${process.env.VUE_APP_API_URL}/pdf/print-odontogram/${odontogram.value.id}`)
        showLoading.value = false
        srcPdf.value = `data:application/pdf;base64,${pdf.data}`
      } catch (e) {
        showLoading.value = false
      }
    }

    const updData = async tooth => {
      try {
        const idx = teethData.value.findIndex(e => e.number === tooth.number)
        if (idx >= 0) {
          await store.dispatch('store-odontogram/update', { userData: userData.value, tooth: teethData.value[idx] })
          Vue.set(teethData.value, idx, JSON.parse(JSON.stringify(tooth)))
        }
      } catch (e) {
        // console.log(e)
      }
    }

    const importProcedures = (handleTeeth, face) => {
      activeTeethData.value = handleTeeth
      activeTeethData.value.face = face
      importProceduresShowModal.value = true
    }

    const toggleExtracted = async number => {
      const idx = teethData.value.findIndex(e => e.number === number)
      teethData.value[idx].extracted = !teethData.value[idx].extracted
      await store.dispatch('store-odontogram/update', { userData: userData.value, tooth: teethData.value[idx] })
    }

    const extractionShowModal = tooth => {
      root.$bvModal
        .msgBoxConfirm(`Deseja ${tooth.extracted ? 'repor' : 'remover'} o dente ${tooth.number}?`, {
          title: 'Por Favor Confirme esta Ação',
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) toggleExtracted(tooth.number)
        })
    }

    return {
      importProceduresShowModal,
      extractionShow,
      refModalAddProcedure,
      activeTeethData,
      teethData,
      srcPdf,
      modalPrintShow,
      showLoading,

      updData,
      toggleExtracted,
      printPdf,
      extractionShowModal,
      importProcedures,
      fetch,
      save,
    }
  },
}
</script>
