<template>
  <div>

    <edit-anamnese-sidebar
      ref="refEditAnamneseSidebar"
      :is-edit-anamnese-sidebar-active.sync="isEditAnamneseSidebarActive"
      :anamnese-model="anamneseModel.data.questions"
      :user-data="userData"
      @saveAnamnese="saveAnamnese"
    />

    <b-modal
      id="modal-print"
      v-model="showModal"
      centered
      size="lg"
      title="Imprimir"
      hide-footer
    >
      <b-overlay
        variant="white"
        :show="showLoading"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
      >
        <object
          :data="srcPdf"
          width="100%"
          style="height: 80vh;"
        />
      </b-overlay>
    </b-modal>

    <b-form>
      <b-row>
        <b-col
          cols="12"
          md="6"
        />
        <b-col
          cols="12"
          md="3"
        >
          <!-- !!! DO NOT USE REDUCE !!! -->
          <v-select
            v-model="anamneseModel"
            input-id="user-status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="anamneseModels"
            :clearable="false"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-button
            variant="primary"
            block
            @click="isEditAnamneseSidebarActive = true"
          >
            Criar anamnese
          </b-button>
        </b-col>
        <b-col
          cols="12"
          md="12"
          class="mt-2"
        >
          <b-table
            ref="refTable"
            class="position-relative pt-25 tableMinHeght"
            :fields="tableColumns"
            :items="userData.anamneses"
            show-empty
            empty-text="Nenhum resultado encontrado"
            no-sort-reset
          >
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="editAnamnese(data.item)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Editar</span>
                </b-dropdown-item>

                <b-dropdown-item @click="printAnamnese(data.item)">
                  <feather-icon icon="PrinterIcon" />
                  <span class="align-middle ml-50">Imprimir</span>
                </b-dropdown-item>

                <b-dropdown-item
                  @click="handleDelete(data.item)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span
                    class="align-middle ml-50"
                  >Deletar</span>
                </b-dropdown-item>
              </b-dropdown>

            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BButton, BTable,
  BDropdown, BDropdownItem, BOverlay,
} from 'bootstrap-vue'
import { toRefs } from '@vue/composition-api'
import vSelect from 'vue-select'
import EditAnamneseSidebar from './EditAnamneseSidebar.vue'
import useAnamnese from './useAnamnese'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BOverlay,

    vSelect,
    EditAnamneseSidebar,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { root, emit }) {
    const { userData } = toRefs(props)
    const {
      isEditAnamneseSidebarActive,
      refEditAnamneseSidebar,
      anamneseModel,
      anamneseModels,
      tableColumns,
      showModal,
      showLoading,
      srcPdf,
      saveAnamnese,
      editAnamnese,
      deleteData,
      printAnamnese,
    } = useAnamnese(emit, userData)

    const handleDelete = data => {
      root.$bvModal
        .msgBoxConfirm('Deseja Excluir esta Anamnese?', {
          title: 'Por Favor Confirme esta Ação',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) deleteData(data)
        })
    }

    return {
      refEditAnamneseSidebar,
      isEditAnamneseSidebarActive,
      anamneseModel,
      anamneseModels,
      tableColumns,
      showModal,
      showLoading,

      saveAnamnese,
      editAnamnese,
      handleDelete,
      printAnamnese,
      srcPdf,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.actionsTdClass {
  width: 116px;
}
</style>
