import Vue from 'vue'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    healthInsurance: {},
    healthInsurances: [],
  },

  getters: {
    forSelect(state) {
      const data = []
      if (Array.isArray(state.healthInsurances)) {
        // eslint-disable-next-line array-callback-return
        state.healthInsurances.map(e => {
          data.push({
            value: e.id,
            label: e.name,
            dentalPlans: e.dental_plans,
          })
        })
      }
      return data
    },
  },

  mutations: {
    SET(state, data) {
      state.healthInsurance = data
    },
    SET_LIST(state, data) {
      state.healthInsurances = data
    },
    ADD_IN_LIST(state, data) {
      if (Array.isArray(state.healthInsurances)) state.healthInsurances.push(data)
    },

    UPDATE_IN_LIST(state, data) {
      if (Array.isArray(state.healthInsurances)) {
        const idx = state.healthInsurances.findIndex(o => o.id === data.id)
        if (idx >= 0) Vue.set(state.healthInsurances, idx, data)
      }
    },

    DELETE_REGISTER(state, payload) {
      const idx = state.healthInsurances.data.findIndex(o => o.id === payload)
      if (idx >= 0) {
        state.healthInsurances.data.splice(idx, 1)
        state.healthInsurances.total -= 1
      }
      if (Array.isArray(state.healthInsurances)) {
        const idx2 = state.healthInsurances.findIndex(o => o.id === payload)
        if (idx2 >= 0) {
          state.healthInsurances.splice(idx, 1)
        }
      }
      if (state.healthInsurance && state.healthInsurance.id === payload) {
        state.healthInsurance = { }
      }
    },
  },

  actions: {
    async fetch({ state, commit }, args) {
      try {
        if (!state.healthInsurances.length || args) {
          const resp = await axios.get(`${process.env.VUE_APP_API_URL}/health-insurances${args ? `?${new URLSearchParams(args).toString()}` : '/'}`)
          if (!args) commit('SET_LIST', resp.data)
          else { commit('SET_LIST', resp.data) }
          return resp
        }
        return state.healthInsurances
      } catch (e) {
        return e
      }
    },

    fetchOne({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/health-insurances/${id}`)
          .then(response => {
            commit('SET', response.data)
            commit('UPDATE_IN_LIST', response.data)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    add({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/health-insurance`, data)
          .then(response => {
            commit('SET', response.data)
            commit('ADD_IN_LIST', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    update({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/health-insurance/${data.id}`, data)
          .then(response => {
            commit('SET', response.data)
            commit('UPDATE_IN_LIST', response.data)
            return resolve(response)
          })
          .catch(e => {
            reject(e)
          })
      })
    },

    delete({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${process.env.VUE_APP_API_URL}/health-insurance/${data.id}`)
          .then(response => {
            commit('DELETE_REGISTER', data.id)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
