/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["data"] }] */
/* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
import {
  ref, watch, computed, onUnmounted,
} from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useStoreHealthInsurances from '@/views/apps/health-insurances/useStoreHealthInsurances'
import userStoreModule from '@/views/apps/user/userStoreModule'
import store from '@/store'

export default function useGeneral(emit, userData) {
  const HEALTH_INSURANCE_STORE_MODULE_NAME = 'store-health-insurance'
  const USER_APP_STORE_MODULE_NAME = 'app-user'

  // Register modules
  if (!store.hasModule(HEALTH_INSURANCE_STORE_MODULE_NAME)) store.registerModule(HEALTH_INSURANCE_STORE_MODULE_NAME, useStoreHealthInsurances)
  if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(HEALTH_INSURANCE_STORE_MODULE_NAME)) store.unregisterModule(HEALTH_INSURANCE_STORE_MODULE_NAME)
    if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
  })

  // Use toast
  const toast = useToast()

  const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
    toast({
      component: ToastificationContent,
      props: {
        title: titleToast,
        icon,
        text,
        variant,
      },
    })
  }

  const catchErr = e => {
    if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'AlertCircleIcon', 'Atenção', e.response.data.message)
    else if (e.response.status === 401) showToast('danger', 'AlertCircleIcon', 'Atenção', 'Sua sessão expirou, faça novo login!')
    else showToast('danger', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
  }

  const [today] = new Date().toISOString().split('T')

  const budget = ref({
    id: null,
    patient: computed(() => store.getters['store-patient/patient']),
    status: 'PENDING', // status: is budget pending, approved or canceled
    creation_date: today,
    effective_date: null, // approved date
    health_insurance_id: null,
    dental_plan_id: null,
    seller_id: null,
    doc_identification_number: null, // ?
    dentist_id: null,
    discount: 0, // full discount
    main_discount_type: '$',
    main_discount_mode: 'DECREASE',
    subtotal: 0, // total value without discounts
    procedures: [],
    installments: [],
    updated_at: null,
  })

  const isDiscountIndividual = ref(false)

  const dentalPlan = ref(null)
  const seller = ref(null)
  const user = ref(JSON.parse(localStorage.getItem('userData')))

  const dentistOptions = ref([])
  const sellers = computed(() => store.getters['app-user/forSelect'])
  store.dispatch('app-user/fetchAll').then(() => {
    const sellerObject = sellers.value.find(s => s.data.id === user.value.id)
    seller.value = sellerObject.data.id
    dentistOptions.value = sellers.value.filter(s => (s.data.roles[0].name === 'ADMIN' || s.data.roles[0].name === 'DENTIST'))
  })

  const docIdentificationNumber = ref(null)

  const refProcedureListTable = ref(null)
  const healthInsurance = ref(null)
  const printBudgetFlag = ref(true)
  const printContractFlag = ref(true)

  const perPage = ref(10)
  const totalData = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50]
  const searchQuery = ref('')
  const sortBy = ref('procedure')
  const isSortDirDesc = ref(false)
  const categoryFilter = ref(null)
  const dentalPlansOptions = ref([])
  const dentist = ref(null)

  const changeDentalPlansOptions = () => {
    dentalPlansOptions.value = (healthInsurance.value && healthInsurance.value.dentalPlans) ? healthInsurance.value.dentalPlans.map(e => {
      const z = e
      z.label = z.name
      z.value = z.id
      return z
    }) : []
    const [f] = dentalPlansOptions.value
    dentalPlan.value = f
  }

  //----------------------------------------------------------------------------------------------------
  // REGRA:
  // CASO O PACIENTE NÃO TENHA NENHUM PLANO, DEVE SER MOSTRADO O CONVÊNIO/PLANO PARTICULAR SELECIONADOS
  // CASO O PACIENTE TENHA PLANO, O MESMO DEVE SER MOSTRADO E SELECIONADO
  store.dispatch('store-health-insurance/fetch')
  const healthInsuranceOptions = computed(() => {
    if (budget.value.patient.health_insurances && budget.value.patient.health_insurances.length) {
      if (!budget.value.patient.health_insurances.find(el => el.name === 'Particular')) {
        const t = store.getters['store-health-insurance/forSelect'].filter(el => budget.value.patient.health_insurances.find(el2 => el2.id === el.value || el.label === 'Particular')).reverse()
        healthInsurance.value = t && t.find(el => el.label !== 'Particular')
        return t
      }
      const t = store.getters['store-health-insurance/forSelect'].filter(el => budget.value.patient.health_insurances.find(el2 => el2.id === el.value)).reverse()
      healthInsurance.value = t && t.find(el => el.label === 'Particular')
      return t
    }
    const t = store.getters['store-health-insurance/forSelect'].filter(el => el.label === 'Particular')
    healthInsurance.value = t && t.find(el => el.label === 'Particular')
    return t
  })
  //----------------------------------------------------------------------------------------------------

  watch([healthInsurance], () => {
    changeDentalPlansOptions()
  })

  const dataMeta = computed(() => {
    const localItemsCount = refProcedureListTable.value ? refProcedureListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalData.value,
    }
  })

  const refetchData = () => {
    refProcedureListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, categoryFilter], () => {
    refetchData()
  })

  const fetchProcedureCategories = () => {
    store
      .dispatch('store-procedure-category/fetch')
      .then(response => response)
      .catch(e => {
        let message = 'Erro ao consultar categorias de procedimentos!'
        if (e.response.status === 401) message = 'Sua sessão expirou, faça novo login!'
        showToast('danger', 'AlertTriangleIcon', message, null)
      })
  }

  const entryDate = ref(JSON.parse(JSON.stringify(budget.value.creation_date)))

  const fetchProcedures = (ctx, callback) => {
    store
      .dispatch('store-procedure/fetchProcedures', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sort: isSortDirDesc.value ? 'desc' : 'asc',
        procedure_category_id: categoryFilter.value,
      })
      .then(response => {
        const { data, total } = response.data
        callback(data)
        totalData.value = total
      })
      .catch(e => {
        let message = 'Erro ao preencher lista de procedimentos!'
        if (e.response.status === 401) message = 'Sua sessão expirou, faça novo login!'
        toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const teethTypes = ref([
    { label: 'Misto', value: 'mixed' },
    { label: 'Dentes Permanentes', value: 'adult' },
    { label: 'Dentes Decíduos', value: 'child' },
  ])

  const importProceduresShowModal = ref(false)
  const requestPasswordShowModal = ref(false)
  const addObservationShowModal = ref(false)
  const procedureData = ref(null)

  const importProcedures = () => {
    importProceduresShowModal.value = true
  }

  const requestPasswordShow = () => {
    requestPasswordShowModal.value = true
  }

  const addObservationShow = data => {
    addObservationShowModal.value = true
    procedureData.value = data.item
  }

  const procedureTableColumns = ref([
    { label: 'Código', key: 'code' },
    { label: 'Procedimento', key: 'procedure' },
    { label: 'Dente(s)', key: 'teeth' },
    { label: 'Face', key: 'face' },
    { label: 'Valor', key: 'cost' },
    { label: 'Desconto', key: 'discount' },
    { label: 'Status', key: 'status2' },
    { label: 'Ações', key: 'actions' },
  ])

  const selectedFace = ref({ label: 'Dente Inteiro' })

  const isPreApproved = ref(false)
  const preDiscountValue = ref(0)
  const aproovedArr = ref([])
  const totalAfterDiscount = ref(0)

  const calculateTotalValue = () => {
    if (budget.value.main_discount_type === '$') {
      if (budget.value.main_discount_mode === 'DECREASE') {
        totalAfterDiscount.value = parseFloat(budget.value.subtotal) - parseFloat(budget.value.discount)
        if (totalAfterDiscount.value < 0) {
          totalAfterDiscount.value = 0
        }
      } else if (budget.value.main_discount_mode === 'INCREASE') {
        totalAfterDiscount.value = parseFloat(budget.value.subtotal) + parseFloat(budget.value.discount)
      }
    } else if (budget.value.main_discount_type === '%') {
      if (budget.value.main_discount_mode === 'DECREASE') {
        if (budget.value.discount > 100) {
          totalAfterDiscount.value = 0
        } else {
          totalAfterDiscount.value = budget.value.subtotal * (1 - (budget.value.discount / 100))
        }
      } else if (budget.value.main_discount_mode === 'INCREASE') {
        totalAfterDiscount.value = budget.value.subtotal * (1 + (budget.value.discount / 100))
      }
    }
  }

  const changeMainDiscountType = () => {
    if (budget.value.main_discount_type === '$') {
      budget.value.main_discount_type = '%'
    } else if (budget.value.main_discount_type === '%') {
      budget.value.main_discount_type = '$'
    }
    calculateTotalValue()
  }

  const returnTotal = () => {
    if (budget.value.discount > budget.value.subtotal && budget.value.main_discount_mode === 'DECREASE') {
      totalAfterDiscount.value = 0
    } else {
      calculateTotalValue()
    }
  }

  const changeValueMode = () => {
    if (budget.value.main_discount_mode === 'INCREASE') {
      budget.value.main_discount_mode = 'DECREASE'
    } else if (budget.value.main_discount_mode === 'DECREASE') {
      budget.value.main_discount_mode = 'INCREASE'
    }
    returnTotal()
  }

  const resetMainDiscountField = () => {
    if (isDiscountIndividual.value) {
      isDiscountIndividual.value = false
      for (let i = 0; i < budget.value.procedures.length; i++) {
        budget.value.procedures[i].discount = 0
        budget.value.procedures[i].price = budget.value.procedures[i].originalPrice
      }
    }
    returnTotal()
  }

  const changeCreationDate = a => {
    budget.value.creation_date = a.value
  }

  // fetch teeth on API
  store.dispatch('teeth/fetch')
  const mixedToothList = computed(() => store.getters['teeth/getAllTeeth'])
  const permanentToothList = computed(() => store.getters['teeth/getPermanentTeeth'])
  const tissueToothList = computed(() => store.getters['teeth/getTissueTeeth'])

  const teethType = ref({ label: 'Misto', value: 'mixed' })

  const valueOnEdit = ref(null)

  const sendBudgetData = async () => {
    if (isPreApproved.value) {
      budget.value.status = 'APPROVED'
    }
    const budgetPayload = {
      id: budget.value.id,
      user_id: budget.value.patient.id,
      status: budget.value.status,
      creation_date: budget.value.creation_date,
      effective_date: budget.value.effective_date,
      health_insurance_id: healthInsurance.value ? healthInsurance.value.value : null,
      dental_plan_id: dentalPlan.value ? dentalPlan.value.id : null,
      seller_id: seller.value,
      doc_identification_number: docIdentificationNumber.value,
      dentist_id: dentist.value,
      discount: budget.value.discount,
      main_discount_type: budget.value.main_discount_type,
      main_discount_mode: budget.value.main_discount_mode,
      subtotal: budget.value.subtotal,
      procedures: budget.value.procedures,
      installments: budget.value.installments,
    }

    if (totalAfterDiscount.value <= 0) {
      try {
        let resp = null
        if (budgetPayload.id) resp = await store.dispatch('store-budget/update', budgetPayload)
        else resp = await store.dispatch('store-budget/add', budgetPayload)

        // eslint-disable-next-line no-param-reassign
        userData.value.procedures = resp.data.user.procedures

        showToast()
        emit('refetchBudgets')
        emit('closeSideBar')
      } catch (e) {
        catchErr(e)
      }
    } else if ((valueOnEdit.value !== totalAfterDiscount.value) && budget.value.id) {
      emit('editingBudgetValue', budgetPayload, totalAfterDiscount.value)
    }
    emit('budgetData', budgetPayload, totalAfterDiscount.value)
  }

  const filteringAprooved = () => {
    for (let i = 0; i < budget.value.procedures.length; i++) {
      if (budget.value.procedures[i].status2 === 'AUTHORIZED') {
        aproovedArr.value.push(budget.value.procedures[i])
      }
    }
  }

  const getPrice = item => (item.originalPrice > item.price ? item.originalPrice : item.price)

  const sumAprooved = () => {
    budget.value.subtotal = aproovedArr.value.reduce((accum, item) => accum + parseFloat(getPrice(item)), 0)
  }

  const sumPreDiscount = () => {
    preDiscountValue.value = budget.value.procedures.reduce((accum, item) => accum + parseFloat(getPrice(item)), 0)
    aproovedArr.value = []
    filteringAprooved()
    sumAprooved()
    returnTotal()
  }

  const changeStatus = data => {
    if (data.item.status2 === 'AUTHORIZED') {
      data.item.status2 = 'UNAUTHORIZED'
    } else if (data.item.status2 === 'UNAUTHORIZED') {
      data.item.status2 = 'AUTHORIZED'
    }
    sumPreDiscount()
  }

  const percentDiscount = (value, discount) => {
    const procedureValue = value
    let procedureDiscount = discount / 100
    if (discount === '') {
      procedureDiscount = 0
    }
    const totalValue = value - (procedureValue * procedureDiscount)
    return totalValue
  }

  const modularDiscount = (value, discount) => {
    const procedureValue = value
    let procedureDiscount = discount
    if (discount === '') {
      procedureDiscount = 0
    }
    const totalValue = procedureValue - procedureDiscount
    return totalValue
  }

  const resetDiscountField = () => {
    if (isDiscountIndividual === false) {
      isDiscountIndividual.value = true
      budget.value.main_discount_type = '$'
      budget.value.main_discount_mode = 'DECREASE'
    }
  }

  const changeCost = data => {
    const cost = data.item.originalPrice
    const newCost = data.item.price
    if (data.item.discountType === 'percent') {
      const newDiscount = 100 - ((newCost / cost) * 100)
      if (newDiscount !== Infinity && newDiscount >= 0) data.item.discount = newDiscount
      else data.item.discount = 0
    }
    if (data.item.discountType === 'modular') {
      const newDiscount = cost - newCost
      if (newDiscount >= 0) data.item.discount = newDiscount
      else data.item.discount = 0
    }
    const discountsArr = budget.value.procedures.map(el => el.originalPrice - el.price)
    const tmpDiscount = discountsArr.reduce((acum, a) => acum + a, 0)
    budget.value.discount = tmpDiscount > 0 ? tmpDiscount : 0
    sumPreDiscount()
  }

  const applyDiscount = data => {
    if (isDiscountIndividual.value === true) {
      budget.value.discount = 0
    }
    if (data.item.discountType === 'percent') {
      data.item.price = parseFloat(percentDiscount(data.item.originalPrice, data.item.discount).toFixed(2))
    } else if (data.item.discountType === 'modular') {
      data.item.price = parseFloat(modularDiscount(data.item.originalPrice, data.item.discount).toFixed(2))
    }
    const discountsArr = budget.value.procedures.map(el => el.originalPrice - el.price)
    budget.value.discount = discountsArr.reduce((acum, a) => acum + a, 0)
    resetDiscountField()
    sumPreDiscount()
  }

  const changeSingleDiscountType = data => {
    if (data.item.discountType === 'percent') {
      data.item.discountType = 'modular'
    } else if (data.item.discountType === 'modular') {
      data.item.discountType = 'percent'
    }
    changeCost(data)
  }

  const cloneProcedure = data => {
    const clonedProcedure = data.item
    if (clonedProcedure.clone) clonedProcedure.clone += 1
    else clonedProcedure.clone = 0
    const arrIndex = (budget.value.procedures.map(e => e.id).indexOf(data.item.id)) + 1
    budget.value.procedures.splice(arrIndex, 0, JSON.parse(JSON.stringify(clonedProcedure)))
    changeCost(data)
  }

  const cancelProcedure = data => {
    const idx = budget.value.procedures.findIndex(s => s.id === data.item.id)
    budget.value.procedures.splice(idx, 1)
    sumPreDiscount()
  }

  const resetData = () => {
    isPreApproved.value = false
    budget.value.id = null
    budget.value.creation_date = today
    budget.value.effective_date = null
    teethType.value = { label: 'Misto', value: 'mixed' }
    changeDentalPlansOptions()
    const sellerObject = sellers.value.find(s => s.data.id === user.value.id)
    seller.value = sellerObject.data.id
    docIdentificationNumber.value = null
    dentist.value = null
    budget.value.procedures = []
    budget.value.discount = 0
    budget.value.subtotal = 0
    preDiscountValue.value = 0
    totalAfterDiscount.value = 0
    budget.value.status = 'PENDING'
    budget.value.main_discount_type = '$'
    budget.value.main_discount_mode = 'DECREASE'
    budget.value.updated_at = null
  }

  const fillData = data => {
    budget.value.id = data.id
    const [creationDt] = data.creation_date.split(' ')
    budget.value.creation_date = creationDt
    budget.value.effective_date = data.effective_date
    healthInsurance.value = healthInsuranceOptions.value.find(e => e.value === data.health_insurance_id)
    dentalPlan.value = dentalPlansOptions.value.find(e => e.id === data.dental_plan_id)
    seller.value = data.seller_id
    docIdentificationNumber.value = data.doc_identification_number
    dentist.value = data.dentist_id
    budget.value.procedures = data.procedures
    budget.value.discount = data.discount
    budget.value.subtotal = data.subtotal
    budget.value.status = data.status
    budget.value.main_discount_type = data.main_discount_type
    budget.value.main_discount_mode = data.main_discount_mode
    budget.value.updated_at = data.updated_at
    sumPreDiscount()
    valueOnEdit.value = (data.subtotal - data.discount)
  }

  const budgetMainButton = () => {
    let buttonValue = null

    if ((valueOnEdit.value !== totalAfterDiscount.value) && budget.value.id) {
      buttonValue = 'Ir ao financeiro'
      return buttonValue
    }

    if (!totalAfterDiscount.value || (budget.value.status === 'PENDING' && budget.value.id)) {
      buttonValue = `${(!budget.value.id ? 'Gerar' : 'Salvar')} orçamento`
    } else {
      buttonValue = 'Ir ao financeiro'
    }

    return buttonValue
  }

  return {
    budget,
    isDiscountIndividual,
    printBudgetFlag,
    printContractFlag,
    seller,
    sellers,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProcedureListTable,
    totalData,
    healthInsuranceOptions,
    dentalPlansOptions,
    healthInsurance,
    dentalPlan,
    docIdentificationNumber,
    dentistOptions,
    dentist,
    teethTypes,
    importProceduresShowModal,
    requestPasswordShowModal,
    procedureTableColumns,
    addObservationShowModal,
    procedureData,
    permanentToothList,
    teethType,
    tissueToothList,
    mixedToothList,
    selectedFace,
    isPreApproved,
    preDiscountValue,
    totalAfterDiscount,
    entryDate,

    fetchProcedureCategories,
    refetchData,
    fetchProcedures,
    importProcedures,
    requestPasswordShow,
    changeValueMode,
    resetMainDiscountField,
    changeCreationDate,
    changeSingleDiscountType,
    changeStatus,
    addObservationShow,
    applyDiscount,
    changeCost,
    sumPreDiscount,
    returnTotal,
    cloneProcedure,
    cancelProcedure,
    sendBudgetData,
    changeMainDiscountType,
    changeDentalPlansOptions,
    fillData,
    resetData,
    budgetMainButton,
  }
}
