<template>
  <div>

    <validation-observer
      #default="{ invalid }"
      ref="refFormObserver"
    >
      <b-form>
        <b-row>
          <!-- Field: Full Name -->
          <b-col
            cols="12"
            md="4"
          >

            <validation-provider
              #default="{ errors }"
              name="nome do paciente"
              rules="required"
              immediate
            >
              <b-form-group
                label="Nome Completo"
                label-for="patient-name-edit"
              >
                <b-form-input
                  id="patient-name-edit"
                  v-model="userData.name"
                  :state="errors.length > 0 ? false : null"
                  trim
                />
                <b-form-invalid-feedback>campo obrigatório</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Call Me -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Como deseja ser chamado"
              label-for="nickname-edit"
            >
              <b-form-input
                id="nickname-edit"
                v-model="userData.nickname"
              />
            </b-form-group>
          </b-col>
          <!-- Field: ocupation -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Profissão"
              label-for="ocupation"
            >
              <b-form-input
                id="ocupation"
                v-model="userData.ocupation"
              />
            </b-form-group>
          </b-col>
          <!-- Field: naturality -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Naturalidade"
              label-for="naturality"
            >
              <b-form-input
                id="naturality"
                v-model="userData.naturality"
              />
            </b-form-group>
          </b-col>

          <!-- Field: CPF / CNPJ -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="CPF"
              :rules="(age(userData.birthday) > 17 ? ($store.getters['store-patient/patient'].identification1 !== userData.identification1 ? `required|cpfCnpj|checkDoubleCpf:${userData.id},PATIENT` : 'required|cpfCnpj') : ($store.getters['store-patient/patient'].identification1 !== userData.identification1 ? `cpfCnpj|checkDoubleCpf:${userData.id},PATIENT` : 'cpfCnpj'))"
              immediate
            >
              <b-form-group
                label="CPF"
                label-for="cpf-edit"
              >
                <b-form-input
                  id="cpf-edit"
                  v-model="userData.identification1"
                  v-mask="['###.###.###-##']"
                  :state="errors.length > 0 ? false : null"
                  trim
                />

                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          </b-col>
        </b-row>

        <b-row>
          <!-- Field: RG -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="RG"
              label-for="rg-edit"
            >
              <b-form-input
                id="rg-edit"
                v-model="userData.identification2"
              />
            </b-form-group>
          </b-col>

          <!-- Field: orgao-emissor-rg-edit-->
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              label="Órgão Emissor"
              label-for="orgao-emissor-rg-edit"
            >
              <v-select
                v-model="userData.orgao_emissor_rg"
                :options="orgaoOmissorRgOptions"
                :reduce="val => val.value"
                :clearable="true"
                :searchable="false"
                input-id="orgao-emissor-rg-edit"
                placeholder="Selecione"
              />

            </b-form-group>
          </b-col>

          <!-- Field: Issued State-->
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              label="UF / RG"
              label-for="orgao-emissor-rg-edit-state"
            >
              <v-select
                v-model="userData.orgao_emissor_rg_uf_id"
                :options="$store.getters['states/getStates']"
                :reduce="val => val.value"
                :clearable="true"
                :searchable="true"
                input-id="orgao-emissor-rg-edit-state"
                placeholder="Selecione"
              />

            </b-form-group>
          </b-col>

          <!-- Field: Birth Date -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="data de nascimento"
              :rules="userData.responsible && !userData.responsible.name && !userData.responsible.identification1 ? `responsibleRequired:${userData.birthday}` : ''"
              immediate
            >
              <b-form-group
                label-for="birthday-edit"
              >
                <label for="birthday-edit">Nascimento</label>
                <small
                  class="text-muted ml-1"
                ><i>{{ userData.birthday | age() }} anos.</i></small>
                <b-form-input
                  id="birthday-edit"
                  v-model="userData.birthday"
                  type="date"
                  trim
                  :state="errors.length > 0 ? false : null"
                  @input="showToastWhenMinor(userData)"
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: Marital Status -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Estado Civil"
              label-for="marital-status"
            >
              <v-select
                v-model="userData.marital_status"
                :options="maritalOptions"
                :reduce="val => val.value"
                :clearable="true"
                :searchable="false"
                input-id="marital-status"
                placeholder="Selecione"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Gender -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Gênero"
              label-for="gender"
            >
              <v-select
                v-model="userData.gender"
                :options="genderOptions"
                :reduce="val => val.value"
                :clearable="false"
                :searchable="false"
                input-id="gender"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Email -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="email"
            >
              <b-form-group
                label="Email"
                label-for="email-edit"
              >
                <b-form-input
                  id="email-edit"
                  v-model="userData.email"
                  type="email"
                  :state="errors.length > 0 ? false : null"
                  trim
                />

                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>

        <b-row>
          <!-- Field: Phone Number 1 -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="celuar principal"
              rules="required"
              immediate
            >
              <!-- MUDAR NO BACKEND PARA TYPE: 'MOBILE' -->
              <b-form-group
                label="Celular - Principal"
                label-for="patient-phone-number"
              >
                <b-form-input
                  id="patient-phone-number"
                  v-model="userData.phoneNumber"
                  v-mask="['(##) ####-#####', '(##) # ####-####']"
                  :state="errors.length > 0 ? false : null"
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Phone Number 2 -->
          <b-col
            cols="12"
            md="4"
          >
            <!-- MUDAR NO BACKEND PARA TYPE: 'MOBILE' -->
            <b-form-group
              label="Celular"
              label-for="second-phone-number"
            >
              <b-form-input
                id="second-phone-number"
                v-model="userData.secondPhoneNumber"
                v-mask="['(##) ####-#####', '(##) # ####-####']"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Phone Number 3 -->
          <b-col
            cols="12"
            md="4"
          >
            <!-- MUDAR NO BACKEND PARA TYPE: 'PHONE' -->
            <b-form-group
              label="Fixo"
              label-for="third-phone-number"
            >
              <b-form-input
                id="third-phone-number"
                v-model="userData.thirdPhoneNumber"
                v-mask="['(##) ####-#####', '(##) # ####-####']"
              />
            </b-form-group>
          </b-col>

        </b-row>
        <b-row>
          <!--HOW DID YOU FIND US-->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Como você nos encontrou?"
              label-for="how_did_you_find_us"
            >
              <v-select
                id="id-how_did_you_find_us"
                v-model="userData.how_did_you_find_us"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :reduce="val => val.value"
                :clearable="true"
                :options="howDidYouFindUsOptions"
                label="text"
                placeholder="Selecione"
              />
            </b-form-group>
          </b-col>

        </b-row>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :disabled="invalid"
          @click="save"
        >
          Salvar Alterações
        </b-button>

      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import moment from 'moment'
import { mask } from 'vue-the-mask'
import { ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormInvalidFeedback,
    vSelect,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    mask,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props) {
    const toast = useToast()
    const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
      toast({
        component: ToastificationContent,
        props: {
          titleToast,
          icon,
          text,
          variant,
        },
      },
      {
        timeout: 8000,
      })
    }

    const showOverlay = false
    const howDidYouFindUsOptions = [
      { value: 'GOOGLE', text: 'GOOGLE' },
      { value: 'FACEBOOK', text: 'FACEBOOK' },
      { value: 'INSTAGRAM', text: 'INSTAGRAM' },
      { value: 'INDICAÇÃO', text: 'INDICAÇÃO' },
      { value: 'FACHADA', text: 'FACHADA' },
      { value: 'PANFLHETO', text: 'PANFLHETO' },
      { value: 'RÁDIO', text: 'RÁDIO' },
      { value: 'TV', text: 'TV' },
      { value: 'OUTRO', text: 'OUTRO' },
    ]
    const treatmentTypeOptions = [
      { value: 'AVALIAÇÃO ORTODÔNTICA', text: 'AVALIAÇÃO ORTODÔNTICA' },
      { value: 'AVALIAÇÃO CLÍNICA', text: 'AVALIAÇÃO CLÍNICA' },
      { value: 'OUTRO', text: 'OUTRO' },
    ]

    const blankUserData = {
      id: null,
      code: null,
      photo: null,
      name: null,
      nickname: null,
      email: null,
      identification1: null,
      identification2: null,
      orgao_emissor_rg: null,
      orgao_emissor_rg_uf_id: null,
      marital_status: null,
      birthday: '',
      gender: 'UNDEFINED',
      phoneNumber: null,
      secondPhoneNumber: null,
      thirdPhoneNumber: null,
      observation: null,
      how_did_you_find_us: null, // GOOGLE, FACEBOOK, INSTAGRAM, INDICAÇÃO, FACHADA, TV, RÁDIO, PANFLHETO, OUTRO
      treatment_type: null, // AVALIAÇÃO ORTODONTICA, AVALIAÇÃO CLÍNICA, OUTRO
      reminder: null,
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    const genderOptions = [
      { label: 'Masculino', value: 'MALE' },
      { label: 'Feminino', value: 'FEMALE' },
      { label: 'Indefinido', value: 'UNDEFINED' },
    ]

    const maritalOptions = [
      { label: 'Casado(a)', value: 'MARRIED' },
      { label: 'Convivente', value: 'COHABITANT' },
      { label: 'Solteiro(a)', value: 'SINGLE' },
      { label: 'Viúvo(a)', value: 'WIDOWER' },
      { label: 'Divorciado(a)', value: 'DIVORCED' },
    ]

    const orgaoOmissorRgOptions = [
      { label: 'SSP', value: 'SSP' },
      { label: 'SSPDS', value: 'SSPDS' },
      { label: 'SESP', value: 'SESP' },
      { label: 'SESED', value: 'SESED' },
      { label: 'SESDEC', value: 'SESDEC' },
    ]

    const age = value => {
      if (value && value.length === 10 && moment(value, 'YYYY-MM-DD').isValid()) {
        const years = moment().diff(value, 'years')
        return years || 0
      }
      return 0
    }

    const showToastWhenMinor = value => {
      if (!value.responsible.name && !value.responsible.identification1 && value.birthday && age(value.birthday) < 18 && value.birthday.length === 10) {
        showToast('danger', 'AlertCircleIcon', 'Atenção', 'É necessário cadastrar os dados do responsável')
      }
    }

    return {
      genderOptions,
      maritalOptions,
      orgaoOmissorRgOptions,
      age,
      showToastWhenMinor,

      refFormObserver,
      getValidationState,
      resetForm,

      howDidYouFindUsOptions,
      treatmentTypeOptions,
      showOverlay,

    }
  },

  async mounted() {
    await this.$store.dispatch('states/fetchStates')
  },

  methods: {
    async save() {
      try {
        this.showOverlay = true
        await this.$store.dispatch('store-patient/update', this.userData)
        this.$emit('refreshUserData')
        this.showToast()
        this.showOverlay = false
      } catch (error) {
        this.showOverlay = false
        if (error.response.status === 412) {
          this.showToast('warning', 'AlertCircleIcon', 'Atenção', error.response.data.message)
        } else {
          this.showToast('danger', 'AlertCircleIcon', 'Atenção', error.message)
        }
      }
    },

    showToast(variant = 'success', icon = 'CheckIcon', title = 'Sucesso', text = 'Dados Salvos com Sucesso.') {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
