<template>
  <div>
    <div>
      <template>
        <b-tabs
          v-if="userData"
          v-model="activeTab"
          small
          vertical
          justified
          pills
        >
          <!-- Tab: Cadastro -->
          <b-tab
            active
          >
            <template #title>
              <feather-icon
                icon="UserIcon"
                size="16"
                class="mr-0 mr-sm-50 mr-auto"
              />
              <span class="d-none d-sm-inline mr-auto">Cadastro</span>
            </template>
            <user-edit-tab-account-info
              ref="registerTab"
              :user-data="userData"
              class="mt-0 pt-75"
              @refreshUserData="refreshUserData"
            />
          </b-tab>

          <!-- Tab: Endereço -->
          <b-tab>
            <template #title>
              <feather-icon
                icon="MapPinIcon"
                size="16"
                class="mr-0 mr-sm-50 mr-auto"
              />
              <span class="d-none d-sm-inline mr-auto">Endereço</span>
            </template>
            <user-edit-tab-account-address
              ref="addressTab"
              :user-data="userData"
              class="mt-0 pt-75"
              @refreshUserData="refreshUserData"
            />
          </b-tab>

          <!-- Tab: Responsável -->
          <b-tab>
            <template #title>
              <feather-icon
                icon="UsersIcon"
                size="16"
                class="mr-0 mr-sm-50 mr-auto"
              />
              <span class="d-none d-sm-inline mr-auto">Responsável</span>
            </template>
            <user-edit-tab-account-responsible
              ref="responsibleTab"
              :user-data="userData"
              class="mt-0 pt-75"
              @refreshUserData="refreshUserData"
            />
          </b-tab>

          <!-- Tab: Planos -->
          <b-tab
            :active.sync="activeTabPlans"
            @click="clickedPlan"
          >
            <template #title>
              <feather-icon
                icon="ActivityIcon"
                size="16"
                class="mr-0 mr-sm-50 mr-auto"
              />
              <span class="d-none d-sm-inline mr-auto">Planos</span>
            </template>
            <user-edit-tab-account-plan
              ref="planTab"
              :user-data="userData"
              class="mt-0 pt-75"
              @refreshUserData="refreshUserData"
            />
          </b-tab>

          <!-- Tab: Informações Adicionais -->
          <b-tab>
            <template #title>
              <feather-icon
                icon="InfoIcon"
                size="16"
                class="mr-0 mr-sm-50 mr-auto"
              />
              <span class="d-none d-sm-inline mr-auto">Informações</span>
            </template>
            <user-edit-tab-account-misc-info
              ref="infoTab"
              :user-data="userData"
              class="mt-0 pt-75"
              @refreshUserData="refreshUserData"
            />
          </b-tab>
        </b-tabs>
      </template>
    </div>
  </div>
</template>

<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import UserEditTabAccountAddress from './address/UserEditTabAccountAddress.vue'
import UserEditTabAccountPlan from './plans/UserEditTabAccountPlan.vue'
import UserEditTabAccountResponsible from './responsible/UserEditTabAccountResponsible.vue'
import UserEditTabAccountInfo from './account-info/UserEditTabAccountInfo.vue'
import UserEditTabAccountMiscInfo from './misc-info/UserEditTabAccountMiscInfo.vue'

export default {
  components: {
    BTabs,
    BTab,

    UserEditTabAccountAddress,
    UserEditTabAccountPlan,
    UserEditTabAccountResponsible,
    UserEditTabAccountInfo,
    UserEditTabAccountMiscInfo,
  },

  setup(props, { root }) {
    const userData = ref(JSON.parse(JSON.stringify(store.getters['store-patient/patient'])))

    const activeTab = ref(0)
    const registerTab = ref(null)
    const addressTab = ref(null)
    const responsibleTab = ref(null)
    const planTab = ref(null)
    const infoTab = ref(null)
    const check = ref(true)

    const refreshUserData = () => {
      userData.value = JSON.parse(JSON.stringify(store.getters['store-patient/patient']))
    }

    watch(activeTab, (to, from) => {
      const tabRefs = [registerTab, addressTab, responsibleTab, planTab, infoTab]

      // fica diferente em função da máscara aplicada, por isso preciso tirar os espaços antes de comparar
      if (tabRefs[from].value.userData.phoneNumber) tabRefs[from].value.userData.phoneNumber = tabRefs[from].value.userData.phoneNumber.replace(/\s/g, '')
      if (tabRefs[from].value.userData.secondPhoneNumber) tabRefs[from].value.userData.secondPhoneNumber = tabRefs[from].value.userData.secondPhoneNumber.replace(/\s/g, '')
      if (tabRefs[from].value.userData.thirdPhoneNumber) tabRefs[from].value.userData.thirdPhoneNumber = tabRefs[from].value.userData.thirdPhoneNumber.replace(/\s/g, '')

      const userDataPlan = {
        healthInsurance: {
          id: store.getters['store-patient/patient'].health_insurances.length ? store.getters['store-patient/patient'].health_insurances[0].id : null,
          health_card: store.getters['store-patient/patient'].health_insurances.length ? store.getters['store-patient/patient'].health_insurances[0].pivot.health_card : null,
        },
        dentalPlans: store.getters['store-patient/patient'].dental_plans.map(e => e.id),
      }

      if (check.value && ((JSON.stringify(store.getters['store-patient/patient']) !== JSON.stringify(tabRefs[from].value.userData)) || (JSON.stringify(userDataPlan) !== JSON.stringify(planTab.value.dataLocal)))) {
        root.$bvModal
          .msgBoxConfirm('Você não salvou as alterações do registro! Deseja voltar e salvar ?', {
            title: 'Por Favor Confirme esta Ação',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Sim',
            cancelTitle: 'Não',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              check.value = false
              activeTab.value = from // tabRefs[from].value.save()
            } else {
              refreshUserData()
              planTab.value.resetFormData()
            }
          })
      }
      check.value = true
    })

    return {
      userData,
      activeTab,
      registerTab,
      addressTab,
      responsibleTab,
      planTab,
      infoTab,

      refreshUserData,
    }
  },

  data() {
    return {
      activeTabPlans: false,
    }
  },
  methods: {
    clickedPlan() {
      if (!this.activeTabPlans) this.$refs.planTab.resetFormData()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
