<template>
  <div>
    <b-modal
      id="modal-patient-status"
      v-model="showLocal"
      centered
      size="md"
      title="Status do Paciente"
      hide-footer
      @hidden="hidden"
    >
      <b-form>

        <b-row>
          <!-- Field: Ativo / Inativo -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Status de atividade"
              label-for="patient-status"
            >
              <v-select
                v-model="patientStatus"
                input-id="patient-status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :reduce="val => val.value"
                :options="activeOptions"
                :clearable="false"
                :searchable="false"
              >
                <template #no-options="{ }">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- Field: Sub Status -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Sub Status"
              label-for="patient-sub-status"
            >
              <v-select
                v-model="patientSubStatus"
                input-id="patient-sub-status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :reduce="val => val.value"
                :options="subStatusOptions"
                :clearable="false"
                :searchable="false"
                :disabled="patientStatus === 0"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <hr>

        <!-- Footer -->
        <b-row>
          <b-col
            cols="12"
            md="4"
          />

          <b-col
            cols="12"
            md="4"
          >
            <b-button
              variant="outline-secondary"
              block
              @click="hidden"
            >
              Cancelar
            </b-button>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <b-button
              variant="primary"
              block
              @click="confirmStatusChange"
            >
              Confirmar
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm, BRow, BCol, BButton, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import usePatientStatusModal from './usePatientStatusModal'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,

    vSelect,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    userData: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const {
      showLocal,
      hidden,
      activeOptions,
      subStatusOptions,
      patientStatus,
      patientSubStatus,
      confirmStatusChange,
    } = usePatientStatusModal(emit, props)

    return {
      showLocal,
      hidden,
      activeOptions,
      subStatusOptions,
      patientStatus,
      patientSubStatus,
      confirmStatusChange,
    }
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
