import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  computed, ref, onMounted,
} from '@vue/composition-api'
import store from '@/store'

export default function usePatientInfoCard() {
  const toast = useToast()

  const userData = computed(() => store.getters['store-patient/patient'])
  const celular = computed(() => userData.value.phones.find(phone => phone.type === 'MOBILE').phone.replace('(', '').replace(')', '').replace(/-/g, ''))

  const sideMenu = ref([
    {
      icon: 'AlertCircleIcon', key: 'anamneseAlert', label: 'Alerta de Anamnese', active: false, badge: 0,
    },
    {
      icon: 'PhoneIcon', key: 'phones', label: 'Telefones', active: true, badge: 0,
    },
    {
      icon: 'FileIcon', key: 'observations', label: 'Lembretes', active: false, badge: (userData.value.reminder && userData.value.reminder.length > 0 ? 1 : 0),
    },
    {
      icon: 'ActivityIcon', key: 'dentalPlans', label: 'Planos', active: false, badge: 0,
    },
  ])

  const anamneseAlertShowModal = ref(false)

  const sideMenuActivation = icon => {
    if (icon.key !== 'anamneseAlert') {
      sideMenu.value.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.active = false
      })
      // eslint-disable-next-line no-param-reassign
      icon.active = true
    }
    if (icon.key === 'anamneseAlert') {
      anamneseAlertShowModal.value = true
    }
  }

  const patientStatusShowModal = ref(false)

  const patientStatusModal = () => {
    patientStatusShowModal.value = true
  }

  const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
    toast({
      component: ToastificationContent,
      props: {
        titleToast,
        icon,
        text,
        variant,
      },
    })
  }

  const catchErr = e => {
    if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'AlertCircleIcon', 'Atenção', e.response.data.message)
    else if (e.response.status === 401) showToast('danger', 'AlertCircleIcon', 'Atenção', 'Sua sessão expirou, faça novo login!')
    else showToast('danger', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
  }

  // eslint-disable-next-line global-require
  const noUserPhoto = require('@/assets/images/avatars/no-picture.png')
  // eslint-disable-next-line global-require
  const whatsappIcon = require('@/assets/images/icons/icon-whatsApp.png')

  const patientReminder = ref(userData.reminder ? userData.reminder : '')
  const webcam = ref(null)
  const loadCamera = () => {
    webcam.value.loadCamera()
  }
  const mouseOver = ref(null)
  const showOverlay = ref(false)
  const busyReminderTextArea = ref(false)

  const onValueChanged = async value => {
    try {
      showOverlay.value = true
      userData.value.photo = value.base_img
      await store.dispatch('store-patient/changePhoto', userData.value)
      showToast()
      showOverlay.value = false
    } catch (e) {
      showOverlay.value = false
      catchErr(e)
    }
  }

  /*
    TODO
    refactor reminder badge reactivity with vuex, it was done without it
    for the reason of short notice needs to refactor everything with vuex
  */
  const saveReminder = async () => {
    try {
      busyReminderTextArea.value = true
      await store.dispatch('store-patient/changeReminder', { id: userData.value.id, reminder: userData.value.reminder })
      showToast()
      busyReminderTextArea.value = false
      if (userData.value.reminder) {
        sideMenu.value[2].badge = 1
      } else {
        sideMenu.value[2].badge = 0
      }
    } catch (e) {
      busyReminderTextArea.value = false
      catchErr(e)
    }
  }

  const addClickToBadge = (child, idx) => {
    if (child.querySelector('.badge')) {
      child.classList.add('cursor-pointer')
      child.addEventListener('click', () => { sideMenuActivation(sideMenu.value[idx]) })
    }
  }

  onMounted(() => {
    const { children } = document.querySelector('#side-menu')
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < children.length; i++) {
      addClickToBadge(children[i], i)
    }
  })

  return {
    sideMenu,
    anamneseAlertShowModal,
    patientStatusShowModal,
    patientReminder,
    webcam,
    mouseOver,
    showOverlay,
    busyReminderTextArea,
    userData,
    celular,
    noUserPhoto,
    whatsappIcon,

    saveReminder,
    onValueChanged,
    sideMenuActivation,
    loadCamera,
    patientStatusModal,
  }
}
