/* eslint-disable func-names */
/* eslint-disable no-extend-native */
import {
  ref, watch, computed,
} from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { currency } from '@core/utils/filter'

import store from '@/store'

export default function useFinance(userData, emit) {
  // AJUSTE DE VENCIMENTOS 30 DIAS EM MESES COM MENOS DE 31 DIAS
  Date.isLeapYear = year => (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0))
  Date.getDaysInMonth = (year, month) => [31, (Date.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month]
  Date.prototype.isLeapYear = function () { return Date.isLeapYear(this.getFullYear()) }
  Date.prototype.getDaysInMonth = function () { return Date.getDaysInMonth(this.getFullYear(), this.getMonth()) }
  Date.prototype.addMonths = function (value) {
    const n = this.getDate()
    this.setDate(1)
    this.setMonth(this.getMonth() + value)
    this.setDate(Math.min(n, this.getDaysInMonth()))
    // return `${this.getFullYear()}-0${this.getMonth() + 1}-${this.getDate()}`
    return this
  }

  // Use toast
  const toast = useToast()

  const addInstallmentsModal = ref(false)
  const changeInstallmentsModal = ref(false)
  const readjustInstallmentsModal = ref(false)
  const editInstallmentModal = ref(false)
  const deleteModal = ref(false)
  const refFinancesMonthlyPaymentTable = ref(null)
  const transaction = ref({
    installments: [{
      value: 0,
    }],
  })

  // Table Handlers
  const tableColumns = [
    {
      key: 'selectAll',
      label: '#',
      sortable: false,
      tdClass: 'selectAllTdClass',
    },
    {
      key: 'expiration_date',
      label: 'Vencimento',
      tdClass: 'expirationDateTdClass',
      sortable: true,
    },
    {
      key: 'transaction',
      label: 'Pago em',
      tdClass: 'paymentDateTdClass',
      sortable: true,
    },
    {
      key: 'description',
      label: 'Descrição',
      tdClass: 'descriptionTdClass',
      sortable: false,
    },
    {
      key: 'value_installment',
      label: 'Valor',
      formatter: currency,
      tdClass: 'cashOutTdClass',
      sortable: true,
    },
    {
      key: 'transactionm',
      label: 'Valor Pago',
      formatter: currency,
      tdClass: 'paidValueTdClass',
      sortable: true,
    },
    {
      key: 'actions',
      tdClass: 'actionsTdClass',
      label: 'ações',
    },
  ]

  const perPage = ref(10)
  const totalData = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50]
  const filter = ref(['PAID', 'NOT_PAID'])
  const filterOptions = ref([
    { item: 'PAID', name: 'Pago' },
    { item: 'NOT_PAID', name: 'A Pagar' },
  ])
  const sortBy = ref('expiration_date')
  const isSortDirDesc = ref(false)

  const selected = ref([])
  const onRowSelected = items => {
    // eslint-disable-next-line array-callback-return
    items.filter(e => e.deleted_at).map((e, idx) => {
      refFinancesMonthlyPaymentTable.value.unselectRow(idx)
    })
    selected.value = items.filter(e => !e.deleted_at)
  }

  const dataMeta = computed(() => {
    const localItemsCount = refFinancesMonthlyPaymentTable.value ? refFinancesMonthlyPaymentTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalData.value,
    }
  })

  const refetchData = () => {
    refFinancesMonthlyPaymentTable.value.refresh()
  }

  watch([currentPage, perPage, filter], () => {
    refetchData()
  })

  const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
    toast({
      component: ToastificationContent,
      props: {
        titleToast,
        icon,
        text,
        variant,
      },
    })
  }

  const catchErr = e => {
    if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'AlertCircleIcon', 'Atenção', e.response.data.message)
    else if (e.response.status === 401) showToast('danger', 'AlertCircleIcon', 'Atenção', 'Sua sessão expirou, faça novo login!')
    else showToast('danger', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
  }

  const showChangeInstallmentsModal = () => {
    if (!selected.value.length) showToast('warning', 'AlertCircleIcon', 'Atenção', 'Selecione ao menos uma parcela para alterar!')
    else changeInstallmentsModal.value = true
  }

  const fetch = (ctx, callback) => {
    store
      .dispatch('store-finance/fetchBills', {
        q: '',
        situation: filter.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sort: isSortDirDesc.value ? 'desc' : 'asc',
        user_id: userData ? userData.id : null,
        origin: 'MONTHLY_PAYMENT',
        withTrashed: true,
      })
      .then(response => {
        const { data, total } = response.data.transactions

        callback(data)
        totalData.value = total
        // totalExpired.value = response.data.totalExpired
        // totalNotPaid.value = response.data.totalNotPaid
        // totalPaid.value = response.data.totalPaid
      })
      .catch(e => {
        let message = 'Erro ao preencher lista de pacientes!'
        if (e.response.status === 401) message = 'Sua sessão expirou, faça novo login!'
        toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const resolveInstallments = installmentOptions => {
    const installments = []
    let dueDate = new Date(`${installmentOptions.firstDueDate}T00:00:00`)
    for (let step = 0; step < installmentOptions.numInstallments; step += 1) {
      installments.push({
        expiration_date: `${dueDate.getFullYear()}-${(`0${dueDate.getMonth() + 1}`).slice(-2)}-${(`0${dueDate.getDate()}`).slice(-2)}`,
        installment: step + 1,
        value_installment: installmentOptions.valueInstallment,
        value_total: installmentOptions.valueInstallment * installmentOptions.numInstallments,
        dental_plan_id: installmentOptions.dental_plan_id,
        account_plan_id: installmentOptions.account_plan_id,
        type: installmentOptions.type || 'CREDIT',
        origin: 'MONTHLY_PAYMENT',
        description: installmentOptions.description,
      })

      if (installmentOptions.frequency === 'MONTHLY') {
        dueDate = new Date(`${installmentOptions.firstDueDate}T00:00:00`).addMonths(step + 1)
      } else if (installmentOptions.frequency === 'BIWEEKLY') dueDate.setDate(dueDate.getDate() + 15)
      else if (installmentOptions.frequency === 'WEEKLY') dueDate.setDate(dueDate.getDate() + 7)
    }
    return installments
  }

  const saveInstallments = async data => {
    try {
      await store.dispatch('store-finance/addInstallments', {
        user_id: userData ? userData.id : null,
        transactions: resolveInstallments(data),
      })
      refetchData()
      showToast()
      emit('action') // Refresh history tab
      addInstallmentsModal.value = false
    } catch (e) {
      catchErr(e)
    }
  }

  const changeInstallments = async data => {
    try {
      await store.dispatch('store-finance/changeInstallments', {
        user_id: userData ? userData.id : null,
        transactions: data,
      })
      refetchData()
      showToast()
      emit('action') // Refresh history tab
      changeInstallmentsModal.value = false
    } catch (e) {
      catchErr(e)
    }
  }

  const readjustInstallments = async data => {
    try {
      await store.dispatch('store-finance/readjustInstallments', {
        user_id: userData ? userData.id : null,
        options: data,
      })
      refetchData()
      showToast()
      emit('action') // Refresh history tab
      readjustInstallmentsModal.value = false
    } catch (e) {
      catchErr(e)
    }
  }

  const editInstallment = async data => {
    try {
      await store.dispatch('store-finance/editInstallment', {
        user_id: userData ? userData.id : null,
        transaction: data,
      })
      refetchData()
      showToast()
      emit('action') // Refresh history tab
      editInstallmentModal.value = false
    } catch (e) {
      catchErr(e)
    }
  }

  const deleteData = async data => {
    try {
      await store.dispatch('store-finance/delete', data)
      refetchData()
      showToast('success', 'CheckIcon', 'Sucesso', 'Cancelado com Sucesso.')
      deleteModal.value = false
      emit('action') // Refresh history tab
    } catch (e) {
      catchErr(e)
    }
  }

  return {
    tableColumns,
    perPage,
    currentPage,
    totalData,
    dataMeta,
    perPageOptions,
    filter,
    filterOptions,
    sortBy,
    isSortDirDesc,
    refFinancesMonthlyPaymentTable,
    addInstallmentsModal,
    changeInstallmentsModal,
    readjustInstallmentsModal,
    editInstallmentModal,
    deleteModal,
    transaction,
    selected,

    showChangeInstallmentsModal,
    onRowSelected,
    fetch,
    refetchData,
    saveInstallments,
    changeInstallments,
    readjustInstallments,
    editInstallment,
    deleteData,
  }
}
