<template>
  <validation-observer
    #default="{invalid}"
    ref="refFormObserver"
  >
    <b-modal
      v-model="showLocal"
      centered
      size="md"
      :title="title"
      @hidden="hidden"
    >
      <b-form
        @submit.prevent="onSubmit"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <validation-provider
              #default="validationContext"
              name="evolução"
              rules="required"
              immediate
            >
              <b-form-group
                label="Evolução"
                label-for="evolution"
                :state="getValidationState(validationContext)"
                class="treatment-evolution-editor"
              >
                <ckeditor
                  v-model="dataLocal.content"
                  :editor="editor"
                  :config="editorConfig"
                  @ready="onReady"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-secondary"
          @click="cancel()"
        >
          Cancelar
        </b-button>
        <b-button
          variant="primary"
          :disabled="invalid"
          @click="save"
        >
          Salvar
        </b-button>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import { ref, toRefs, watch } from '@vue/composition-api'
import {
  BModal, BButton, BFormInvalidFeedback, BRow, BCol,
  BForm, BFormGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'

import CKEditor from '@ckeditor/ckeditor5-vue2'

// ⚠️ NOTE: We don't use @ckeditor/ckeditor5-build-classic any more!
// Since we're building CKEditor from source, we use the source version of ClassicEditor.
// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import ClassicEditor from '@/util/ckeditor5/src/ckeditor'
import '@ckeditor/ckeditor5-build-classic/build/translations/pt-br'

import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote'
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold'
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials'
import Heading from '@ckeditor/ckeditor5-heading/src/heading'
import Indent from '@ckeditor/ckeditor5-indent/src/indent'
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic'
import List from '@ckeditor/ckeditor5-list/src/list'
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice'
import Table from '@ckeditor/ckeditor5-table/src/table'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor'
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily'
import FontSize from '@ckeditor/ckeditor5-font/src/fontsize'
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight'
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock'
import TableCaption from '@ckeditor/ckeditor5-table/src/tablecaption'
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties'
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline'

export default {
  components: {
    BModal,
    BButton,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BForm,
    BFormGroup,

    ValidationProvider,
    ValidationObserver,
    ckeditor: CKEditor.component,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => { },
    },
  },

  setup(props, { emit }) {
    const showLocal = ref(false)

    const blankData = {
      id: null,
      treatment_type: '', // ORTHODONTIC - IMPLANT
      evolution_type: '', // ATTENDANCE - PLANNING - EVOLUTION
      content: '',
      user_id: null, // Patient
    }

    const dataLocal = ref(JSON.parse(JSON.stringify(blankData)))
    const { show, data } = toRefs(props)

    const resetData = () => {
      dataLocal.value = JSON.parse(JSON.stringify(blankData))
    }

    const hidden = () => {
      resetData()
      emit('hidden')
    }

    watch(show, newValue => {
      showLocal.value = newValue
      if (showLocal.value) {
        Object.assign(dataLocal.value, JSON.parse(JSON.stringify(data.value)))
      }
    })

    const editor = ClassicEditor
    const ckEd = ref(null)
    const editorConfig = {
      language: 'pt-br',
      plugins: [
        BlockQuote,
        Bold,
        Essentials,
        Heading,
        Indent,
        Italic,
        List,
        Paragraph,
        PasteFromOffice,
        Table,
        TableToolbar,
        TextTransformation,
        Alignment,
        FontColor,
        FontFamily,
        FontSize,
        Highlight,
        IndentBlock,
        TableCaption,
        TableCellProperties,
        TableProperties,
        Underline,
      ],
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          'underline',
          // 'bulletedList',
          // 'numberedList',
          '|',
          'outdent',
          'indent',
          '|',
          'blockQuote',
          'insertTable',
          'undo',
          'redo',
          'alignment',
          // 'fontBackgroundColor',
          'fontColor',
          'fontFamily',
          'fontSize',
          'highlight',
        ],
      },
      table: {
        contentToolbar: [
          'tableColumn',
          'tableRow',
          'mergeTableCells',
          // 'tableCellProperties',
          // 'tableProperties'
        ],
      },
      heading: {
        options: [
          {
            model: 'paragraph',
            title: 'Paragraph',
            class: 'ck-heading_paragraph',
          },
          {
            model: 'heading1',
            view: 'h1',
            title: 'Heading 1',
            class: 'ck-heading_heading1',
          },
          {
            model: 'heading2',
            view: 'h2',
            title: 'Heading 2',
            class: 'ck-heading_heading2',
          },
        ],
      },
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const onReady = ed => {
      ckEd.value = ed
      // console.log(ed.ui.componentFactory.names())
    }

    const save = () => {
      emit('save', dataLocal.value)
      hidden()
    }

    return {
      showLocal,
      refFormObserver,
      editorConfig,
      dataLocal,
      editor,

      hidden,
      onReady,
      save,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.treatment-evolution-editor .ck-editor__editable {
  min-height: 400px;
}

.dark-layout {
  div ::v-deep {
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
  }

  .ck:not(.ck-color-grid__tile) {
    background: #283046 !important;
    color: white !important;
    border-color: #404656 !important;
  }

}
</style>
