<template>
  <div
    class="mr-1"
  >
    <!-- Modal Lista Contratos -->
    <b-modal
      v-model="contractListModal"
      centered
      size="lg"
      title="Contratos"
    >
      <b-overlay
        variant="white"
        :show="showLoading"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
      >
        <h2>uma lista de contratos para escolher</h2>
      </b-overlay>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-secondary"
          @click="cancel()"
        >
          Cancelar
        </b-button>
      </template>

    </b-modal>

    <!-- Modal Print -->
    <b-modal
      id="modal-print"
      v-model="printBudgetPdfModal"
      centered
      size="lg"
      title="Orçamento"
      hide-footer
    >
      <b-overlay
        variant="white"
        :show="showLoading"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
      >
        <object
          :data="srcBudgetPdf"
          width="100%"
          style="height: 80vh;"
        />
      </b-overlay>
    </b-modal>

    <add-treatments
      :price-list-id="dentalPlan ? dentalPlan.price_list_id : null"
      :dentist="dentist"
      :show="importProceduresShowModal"
      @selectedProcedures="injectData"
      @hidden="importProceduresShowModal = false"
    />

    <request-password
      :show="requestPasswordShowModal"
      :creation-date="budget.creation_date"
      :alert-text="'A data de criação do orçamento só pode ser alterada pelo administrador!'"
      @changeCreationDate="changeCreationDate"
      @hidden="requestPasswordShowModal = false"
    />

    <add-observation
      :show="addObservationShowModal"
      :procedure="procedureData"
      @hidden="addObservationShowModal = false"
    />

    <b-row>
      <b-col
        cols="12"
        md="4"
        style="height: 100px"
      >
        <info-card
          style="height: 90%"
          icon="FileTextIcon"
          :statistic="budget.patient ? budget.patient.code : ''"
          statistic-title="Ficha do Paciente"
          color="primary"
        />
      </b-col>

      <b-col
        cols="12"
        md="4"
        style="height: 100px"
      >
        <info-card
          style="height: 90%"
          icon="UserIcon"
          :statistic="budget.patient ? budget.patient.name : ''"
          statistic-title="Nome do Paciente"
          color="success"
        />
      </b-col>

      <b-col
        cols="12"
        md="4"
        style="height: 100px"
      >
        <info-card
          style="height: 90%"
          icon="FileIcon"
          :statistic="budget.id || '-'"
          statistic-title="Número do Orçamento"
          color="danger"
        />
      </b-col>

      <!-- ========== Treatment Title ========== -->
      <b-col
        cols="12"
        md="4"
        class="d-flex align-items-center"
      >
        <feather-icon
          icon="ChevronsRightIcon"
          size="20"
        />
        <h4 class="mb-0">
          Informações Gerais do Orçamento
        </h4>
      </b-col>

      <!-- ========== Budget Dates ========== -->
      <b-col
        cols="12"
        md="2"
        class="d-flex align-items-center justify-content-center"
      >
        <b-badge
          :variant="budget.status === 'APPROVED' ? 'success' : budget.status === 'PENDING ' ? 'warning' : 'danger'"
          class="text-capitalize"
        >
          {{ budget.status === 'APPROVED' ? 'Aprovado' : budget.status === 'CANCELED' ? 'Cancelado' : 'Pendente' }}
        </b-badge>
      </b-col>

      <b-col
        cols="12"
        md="2"
      >
        <div
          class="d-flex flex-column justify-content-between pb-1"
        >
          <p
            style="font-size: 0.857rem"
            class="mb-25"
          >
            Data de Criação:
          </p>
          <div
            style="padding: 0.438rem 1rem; margin: 0px; height: 2.714rem"
            class="border rounded d-flex justify-content-between"
          >
            <p
              style="font-size: 1.25rem"
              class="font-weight-bold"
            >
              {{ budget.creation_date | dateTime('date') }}
            </p>
            <!-- ========== Alterar data com senha admin ===== -->
            <div
              id="change-date-icon"
            >
              <feather-icon
                icon="AlertCircleIcon"
                class="cursor-pointer"
                style="margin-top: 1px"
                @click="requestPasswordShow"
              />
              <b-tooltip
                target="change-date-icon"
                noninteractive
                :delay="{show: 300, hide: 125}"
              >
                Alterar data
              </b-tooltip>
            </div>
          </div>
        </div>
      </b-col>

      <b-col
        cols="12"
        md="2"
      >
        <div
          class="d-flex flex-column justify-content-between pb-1"
        >
          <p
            style="font-size: 0.857rem"
            class="mb-25"
          >
            Data de Efetivação:
          </p>
          <div
            style="padding: 0.438rem 1rem; margin: 0px; height: 2.714rem"
            class="border rounded"
          >
            <p
              style="font-size: 1.25rem"
              class="font-weight-bold"
            >
              <span v-if="budget.effective_date">
                {{ budget.effective_date | dateTime('date') }}
              </span>
              <span v-else>-</span>
            </p>
          </div>
        </div>
      </b-col>

      <b-col
        cols="12"
        md="2"
      >
        <div
          class="d-flex flex-column justify-content-between pb-1"
        >
          <p
            style="font-size: 0.857rem"
            class="mb-25"
          >
            Data de Alteração:
          </p>
          <div
            style="padding: 0.438rem 1rem; margin: 0px; height: 2.714rem"
            class="border rounded"
          >
            <p
              style="font-size: 1.25rem"
              class="font-weight-bold"
            >
              <span v-if="budget.updated_at">
                {{ budget.updated_at | dateTime('date') }}
              </span>
              <span v-else>-</span>
            </p>
          </div>
        </div>
      </b-col>
    </b-row>
    <hr
      class="mt-0"
    >

    <validation-observer
      #default="{invalid}"
      ref="refFormObserver"
    >

      <b-row>

        <!-- Health Ensurance -->
        <b-col
          cols="12"
          md="4"
        >

          <b-form-group
            label="Convênio"
            label-for="health-ensurance"
          >
            <v-select
              id="id-health-ensurance"
              v-model="healthInsurance"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :options="healthInsuranceOptions"
              placeholder="Selecione..."
              :searchable="false"
            >
              <template #no-options="{}">
                Nenhum resultado encontrado.
              </template>
            </v-select>

          </b-form-group>

        </b-col>

        <!-- Plans -->
        <b-col
          cols="12"
          md="4"
        >

          <b-form-group
            label="Plano"
            label-for="health-ensurance-plan"
          >
            <v-select
              id="id-health-ensurance-plan"
              v-model="dentalPlan"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :options="dentalPlansOptions"
              placeholder="Selecione..."
              :searchable="false"
            >
              <template #no-options="{}">
                Nenhum resultado encontrado.
              </template>
            </v-select>

          </b-form-group>
        </b-col>

        <!-- Vendedor -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Vendedor"
            label-for="health-ensurance-plan"
          >
            <v-select
              id="id-health-ensurance-plan"
              v-model="seller"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :options="sellers"
              :reduce="val => val.value"
              placeholder="Selecione..."
              :searchable="false"
            >
              <template #no-options="{}">
                Nenhum resultado encontrado.
              </template>
            </v-select>

          </b-form-group>
        </b-col>

        <!-- Número da Guia -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Número da Guia"
            label-for="doc-identification-number"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                <span>Nº</span>
              </b-input-group-prepend>
              <b-form-input
                id="doc-identification-number"
                v-model="docIdentificationNumber"
                type="number"
                trim
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- Dentists -->
        <b-col
          cols="12"
          md="4"
        >

          <validation-provider
            #default="{ errors }"
            name="Dentista"
            rules="required"
            immediate
          >

            <b-form-group
              label="Dentista"
              label-for="id-dentist"
              :state="errors.length > 0 ? false:null"
            >
              <v-select
                id="id-dentist"
                v-model="dentist"
                :reduce="option => option.value"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :options="dentistOptions"
                placeholder="Selecione..."
              />

              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>

            </b-form-group>

          </validation-provider>

        </b-col>

        <b-col
          cols="12"
          md="4"
        >

          <b-form-group
            label="Dentição"
            label-for="filter-teeth-type"
          >
            <v-select
              id="id-filter-teeth-type"
              v-model="teethType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :options="teethTypes"
              placeholder="Selecione..."
              :searchable="false"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <div
        class="d-flex justify-content-between align-items-center mb-1"
      >
        <div
          class="d-flex align-items-center"
        >
          <feather-icon
            icon="ChevronsRightIcon"
            size="20"
          />
          <h2 class="mb-0">
            Tratamentos
          </h2>
        </div>
        <b-button
          variant="primary"
          @click="importProcedures"
        >
          Adicionar Tratamentos
        </b-button>
      </div>

      <b-table
        class="position-relative pt-25 tableMinHeght"
        :items="budget.procedures"
        :fields="procedureTableColumns"
        show-empty
        empty-text="Nenhum resultado encontrado"
        no-sort-reset
      >

        <template #cell(procedure)="data">
          <div
            class="d-flex flex-row justify-content-between"
          >

            <a
              v-if="data.item.observation && data.item.observation !== ''"
              :id="`observation-indicator-icon-${data.index}`"
              class="align-self-center mr-50"
            >
              <feather-icon
                icon="InfoIcon"
                class="cursor-pointer"
                size="20"
                @click="addObservationShow(data)"
              />
              <b-tooltip
                :target="`observation-indicator-icon-${data.index}`"
                noninteractive
                :delay="{show: 300, hide: 125}"
              >
                {{ data.item.observation }}
              </b-tooltip>
            </a>

            <div>
              {{ data.item.procedure }}
            </div>

          </div>
        </template>

        <template #cell(teeth)="data">

          <validation-provider
            #default="{ errors }"
            name="Dentista"
            rules="required"
            immediate
          >

            <b-form-group
              label=""
              label-for="selcted-tooth"
              :state="errors.length > 0 ? false:null"
            >
              <v-select
                id="selcted-tooth"
                v-model="data.item.teeth_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="(teethType.value === 'adult' ? permanentToothList : teethType.value === 'mixed' ? mixedToothList : tissueToothList)"
                :reduce="option => option.value"
                :clearable="false"
                placeholder="..."
                style="min-width: 185px"
                class="d-inline-block mx-50 pt-1"
              />

            </b-form-group>

          </validation-provider>

        </template>

        <template #cell(face)="data">
          <v-select
            id="selcted-tooth"
            v-model="data.item.tooth_face_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="$store.getters['toothFaces/getToothFaces']"
            :reduce="option => option.value"
            :clearable="false"
            placeholder="..."
            style="min-width: 157px"
            class="d-inline-block mx-50"
            multiple
          />
        </template>

        <template #cell(cost)="data">
          <b-input-group
            style="min-width: 130px"
          >
            <b-input-group-prepend is-text>
              <span>R$</span>
            </b-input-group-prepend>
            <currency-input
              id="procedure-cost"
              v-model="data.item.price"
              @input="changeCost(data)"
            />
          </b-input-group>
        </template>

        <template #cell(discount)="data">

          <b-input-group
            id="procedure-discount-field"
            class="input-group-merge"
            style="min-width: 150px"
          >

            <b-input-group-prepend v-if="data.item.discountType === 'modular'">
              <b-button
                variant="danger"
                @click="changeSingleDiscountType(data)"
              >
                <span>
                  R$
                </span>
              </b-button>
            </b-input-group-prepend>

            <currency-input
              id="procedure-discount"
              v-model="data.item.discount"
              trim
              :disabled="!data.item.originalPrice"
              @input="applyDiscount(data)"
            />

            <b-input-group-append
              v-if="!data.item.originalPrice"
              id="procedure-disabled-discount"
              is-text
            >
              <feather-icon
                icon="AlertCircleIcon"
              />
            </b-input-group-append>

            <b-tooltip
              v-if="!data.item.originalPrice"
              target="procedure-disabled-discount"
              noninteractive
              :delay="{show: 300, hide: 125}"
            >
              Não é possível aplicar desconto neste tratamento pois seu valor de tabela é zero
            </b-tooltip>

            <b-input-group-append v-if="data.item.discountType === 'percent' && data.item.originalPrice">
              <b-button
                variant="primary"
                @click="changeSingleDiscountType(data)"
              >
                <feather-icon
                  icon="PercentIcon"
                />
              </b-button>
            </b-input-group-append>

          </b-input-group>
        </template>

        <template #cell(status2)="data">
          <div
            class="d-flex justify-content-start"
          >
            <b-badge
              pill
              :variant="data.item.status2 === 'AUTHORIZED' ? 'light-success' : data.item.status2 === 'UNAUTHORIZED' ? 'light-danger' : 'light-primary'"
              class="text-capitalize cursor-pointer"
              @click="changeStatus(data)"
            >
              <span v-if="data.item.status2 === 'AUTHORIZED'">
                Autorizado
              </span>
              <span v-else-if="data.item.status2 === 'UNAUTHORIZED'">
                Não Autorizado
              </span>
            </b-badge>
          </div>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="cloneProcedure(data)"
            >
              <feather-icon icon="CopyIcon" />
              <span class="align-middle ml-50">Duplicar Procedimento</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="addObservationShow(data)"
            >
              <feather-icon icon="Edit3Icon" />
              <span class="align-middle ml-50">Adicionar Observação</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="cancelProcedure(data)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Cancelar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <hr>

      <b-row>

        <b-col
          cols="12"
          md="3"
        >
          <div
            class="d-flex flex-column justify-content-between pb-1"
          >
            <p
              style="font-size: 0.857rem"
              class="mb-25"
            >
              Total Orçado:
            </p>
            <div
              style="padding: 0.438rem 1rem; margin: 0px; height: 2.714rem"
              class="border rounded"
            >
              <p
                style="font-size: 1.25rem"
                class="font-weight-bold"
              >
                {{ preDiscountValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
              </p>
            </div>
          </div>
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <div
            class="d-flex flex-column justify-content-between pb-1"
          >
            <p
              style="font-size: 0.857rem"
              class="mb-25"
            >
              Total Aprovado:
            </p>
            <div
              style="padding: 0.438rem 1rem; margin: 0px; height: 2.714rem"
              class="border rounded"
            >
              <p
                style="font-size: 1.25rem"
                class="font-weight-bold"
              >
                {{ budget.subtotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
              </p>
            </div>
          </div>
        </b-col>

        <!-- Field: Desconto / Acréscimo -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            :label="`${budget.main_discount_mode === 'INCREASE' ? 'Acréscimo' : 'Desconto'} Total`"
            :label-for="`${budget.main_discount_mode === 'INCREASE' ? 'add' : 'remove'}-value`"
          >
            <b-input-group>

              <b-input-group-prepend v-if="!isDiscountIndividual">
                <b-button
                  variant="outline-danger"
                  @click="(budget.main_discount_type === '$' ? budget.main_discount_type = '%' : budget.main_discount_type = '$'); returnTotal()"
                >
                  <span v-if="budget.main_discount_type === '$'">R$</span>
                  <feather-icon
                    v-else
                    icon="PercentIcon"
                  />
                </b-button>
              </b-input-group-prepend>
              <currency-input
                :id="`${budget.main_discount_mode === 'INCREASE' ? 'add' : 'remove'}-value`"
                v-model="budget.discount"
                :disabled="!budget.procedures.length || preDiscountValue <= 0"
                @input="resetMainDiscountField()"
              />
              <b-input-group-append v-if="!isDiscountIndividual">
                <b-button
                  :variant="budget.main_discount_mode === 'INCREASE' ? 'primary' : 'danger'"
                  @click="changeValueMode"
                >
                  <feather-icon :icon="budget.main_discount_mode === 'INCREASE' ? 'ArrowUpRightIcon' : 'ArrowDownRightIcon'" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <div
            class="d-flex flex-column justify-content-between pb-1"
          >
            <p
              style="font-size: 0.857rem"
              class="mb-25"
            >
              Total a Pagar:
            </p>
            <div
              style="padding: 0.438rem 1rem; margin: 0px; height: 2.714rem"
              class="border rounded"
            >
              <p
                style="font-size: 1.25rem"
                class="font-weight-bold"
              >
                {{ totalAfterDiscount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
              </p>
            </div>
          </div>
        </b-col>
      </b-row>

      <!-- Footer -->
      <div
        class="d-flex align-items-center justify-content-between pt-1 pb-2 mt-1 border-top"
      >
        <div>
          <b-button
            v-if="budget.id"
            variant="outline-primary"
            @click="printBudgetPdf"
          >
            Imprimir Orçamento
          </b-button>

          <b-button
            v-if="budget.id"
            class="ml-2"
            variant="outline-primary"
          >
            Imprimir Contrato
          </b-button>
        </div>

        <b-dropdown
          v-if="totalAfterDiscount === 0"
          :text="budgetMainButton()"
          block
          split
          right
          :variant="isPreApproved ? 'success' : 'warning'"
          :disabled="invalid || !budget.procedures.length"
          menu-class="w-100"
          @click="sendBudgetData"
        >
          <b-dropdown-item @click="isPreApproved = !isPreApproved">
            <div class="d-flex align-items-center">
              <feather-icon :icon="isPreApproved ? 'CheckSquareIcon' : 'SquareIcon'" />
              <span class="pl-1">Efetivar ao {{ budget.id ? 'salvar' : 'gerar' }}</span>
            </div>
          </b-dropdown-item>
        </b-dropdown>

        <b-button
          v-else
          variant="warning"
          :disabled="invalid || !budget.procedures.length"
          @click="sendBudgetData"
        >
          {{ budgetMainButton() }}
        </b-button>
      </div>

    </validation-observer>

  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BInputGroup, BFormInput, BInputGroupPrepend,
  BButton, BTable, BInputGroupAppend, BBadge, BDropdown, BDropdownItem,
  BFormInvalidFeedback, BTooltip, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { onUnmounted, ref, toRefs } from '@vue/composition-api'
import addTreatments from '@/views/components/add-treatments/addTreatments.vue'
import requestPassword from '@/views/components/change-budget-date/requestPassword.vue'
import addObservation from '@/views/components/add-procedure-observation/addObservation.vue'
import CurrencyInput from '@/views/components/currency/CurrencyInput.vue'
import patientStoreModule from '@/views/apps/patients/patientStoreModule'
import userStoreModule from '@/views/apps/user/userStoreModule'
import store from '@/store'
import axios from '@axios'
import InfoCard from '../cards/InfoCard.vue'
import useGeneral from './useGeneral'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BButton,
    BTable,
    BInputGroupAppend,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormInvalidFeedback,
    BTooltip,
    BOverlay,
    InfoCard,
    addTreatments,
    requestPassword,
    addObservation,
    CurrencyInput,

    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    localize('pt_BR')

    store.dispatch('toothFaces/fetch')

    const USER_APP_STORE_MODULE_NAME = 'store-patient'
    const USER_APP_STORE_MODULE_NAME2 = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, patientStoreModule)
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME2)) store.registerModule(USER_APP_STORE_MODULE_NAME2, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE_NAME2)) store.unregisterModule(USER_APP_STORE_MODULE_NAME2)
    })

    const resetFormData = () => {
      // addInstallments.value = JSON.parse(JSON.stringify(blankAddInstallments))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetFormData)

    const { userData } = toRefs(props)

    const {
      budget,
      isDiscountIndividual,
      seller,
      sellers,
      patient,
      healthInsurance,
      healthInsuranceOptions,
      dentalPlansOptions,
      dentalPlan,
      docIdentificationNumber,
      dentistOptions,
      dentist,
      teethTypes,
      importProceduresShowModal,
      procedureTableColumns,
      faceOptions,
      requestPasswordShowModal,
      addObservationShowModal,
      procedureData,
      permanentToothList,
      teethType,
      tissueToothList,
      mixedToothList,
      isPreApproved,
      preDiscountValue,
      totalAfterDiscount,
      approveBudget,
      requestPasswordShow,
      importProcedures,
      changeValueMode,
      fetchProcedures,
      resetMainDiscountField,
      changeCreationDate,
      changeSingleDiscountType,
      changeStatus,
      addObservationShow,
      applyDiscount,
      changeCost,
      sumPreDiscount,
      returnTotal,
      cloneProcedure,
      cancelProcedure,
      sendBudgetData,
      changeMainDiscountType,
      fillData,
      resetData,
      budgetMainButton,
    } = useGeneral(emit, userData)

    const injectData = data => {
      budget.value.procedures = budget.value.procedures.concat(data)
      sumPreDiscount()
    }

    const showLoading = ref(false)
    const printBudgetPdfModal = ref(false)
    const contractListModal = ref(false)

    const srcBudgetPdf = ref('')
    const printBudgetPdf = async (budgetId = null) => {
      try {
        srcBudgetPdf.value = ''
        showLoading.value = true
        printBudgetPdfModal.value = true
        const payload = budgetId || budget.value.id
        const pdf = await axios.post(`${process.env.VUE_APP_API_URL}/pdf/print-budget`, { id: payload })
        srcBudgetPdf.value = `data:application/pdf;base64,${pdf.data}`
        showLoading.value = false
      } catch (e) {
        showLoading.value = false
      }
    }

    // eslint-disable-next-line no-unused-vars
    const printContractList = async (budgetId = null) => {
      try {
        contractListModal.value = true
        // console.log(budgetId)
        // srcBudgetPdf.value = ''
        // showLoading.value = true
        // printBudgetPdfModal.value = true
        // const payload = budgetId || budget.value.id
        // const pdf = await axios.post(`${process.env.VUE_APP_API_URL}/pdf/print-budget`, { id: payload })
        // srcBudgetPdf.value = `data:application/pdf;base64,${pdf.data}`
        // showLoading.value = false
      } catch (e) {
        // showLoading.value = false
      }
    }

    return {
      budget,
      isDiscountIndividual,
      seller,
      sellers,
      patient,
      healthInsurance,
      healthInsuranceOptions,
      dentalPlansOptions,
      dentalPlan,
      dentistOptions,
      dentist,
      docIdentificationNumber,
      teethTypes,
      importProceduresShowModal,
      procedureTableColumns,
      faceOptions,
      requestPasswordShowModal,
      addObservationShowModal,
      procedureData,
      permanentToothList,
      teethType,
      tissueToothList,
      mixedToothList,
      isPreApproved,
      preDiscountValue,
      totalAfterDiscount,
      refFormObserver,
      approveBudget,
      showLoading,
      printBudgetPdfModal,
      contractListModal,
      srcBudgetPdf,
      requestPasswordShow,
      importProcedures,
      changeValueMode,
      fetchProcedures,
      resetMainDiscountField,
      changeCreationDate,
      changeSingleDiscountType,
      changeStatus,
      addObservationShow,
      applyDiscount,
      changeCost,
      sumPreDiscount,
      returnTotal,
      cloneProcedure,
      cancelProcedure,
      sendBudgetData,
      changeMainDiscountType,
      injectData,
      getValidationState,
      resetForm,
      fillData,
      resetData,
      budgetMainButton,
      printBudgetPdf,
      printContractList,
    }
  },
}
</script>

<style lang="scss" scoped>
  @import '~@core/scss/base/bootstrap-extended/include';
  @import '~@core/scss/base/components/variables-dark';
  .dark-layout {
    .b-overlay-wrap ::v-deep .b-overlay {
      // border: 10px solid red;
      .bg-white {
        background-color: $theme-dark-body-bg !important;
      }
    }
  }
</style>
