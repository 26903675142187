<template>
  <!-- Procedimentos não aprovados -->
  <div
    class="mr-1"
  >
    <!-- Modal Print -->
    <b-modal
      id="modal-print"
      v-model="showModal"
      centered
      size="lg"
      title="Imprimir"
      hide-footer
    >
      <b-overlay
        variant="white"
        :show="showLoading"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
      >
        <object
          :data="srcPdf"
          width="100%"
          style="height: 80vh;"
        />
      </b-overlay>
    </b-modal>

    <!-- Alterações -->
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <!-- ========== CHANGES LOG TABLE ========== -->
        <b-row
          class="px-1"
        >

          <b-col
            cols="12"
            md="8"
            class="d-flex justify-content-start"
          >
            <div
              class="d-flex align-content-start mt-1"
            >
              <feather-icon
                icon="ChevronsRightIcon"
                size="18"
                style="margin: 15px 0px 0px 10px"
              />
              <h4
                class="pt-1 pl-25 pr-1"
              >
                Alterações
              </h4>
            </div>
          </b-col>

          <b-col
            cols="12"
            md="12"
            class="mt-25"
          >
            <b-table
              class="position-relative tableMinHeght"
              responsive
              :items="changeLog"
              :fields="changeLogTableHandler"
              primary-key="id"
              show-empty
              empty-text="Não há histórico de alterações"
              no-sort-reset
            >

              <template #cell(updated_at)="data">
                <span>{{ data.item.updated_at | dateTime('date') }}</span>
              </template>

              <!-- Column: Ações -->
              <template #cell(actions)="data">
                <b-button
                  variant="flat-info"
                  class="btn-icon"
                  @click="printBudgetLogPdf(data.item)"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>

              </template>

            </b-table>
          </b-col>
        </b-row>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BTable, BButton, BOverlay,
} from 'bootstrap-vue'
import useHistory from './useHistory'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BButton,
    BOverlay,
  },
  setup() {
    const {
      nonAproovedTableHander,
      changeLogTableHandler,
      nonAproovedProcedures,
      changeLog,
      showLoading,
      srcPdf,
      showModal,
      fillData,
      printBudgetLogPdf,
    } = useHistory()

    return {
      nonAproovedTableHander,
      changeLogTableHandler,
      nonAproovedProcedures,
      changeLog,
      srcPdf,
      showModal,
      showLoading,
      fillData,
      printBudgetLogPdf,
    }
  },
}
</script>

<style>

</style>
