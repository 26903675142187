/* eslint-disable func-names */
/* eslint-disable no-extend-native */
import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { currency } from '@core/utils/filter'

import store from '@/store'

export default function useScheduledTransactions(userData) {
  // Use toast
  const toast = useToast()

  const addModal = ref(false)
  const editModal = ref(false)
  const deleteModal = ref(false)
  const refFinancesScheduleTransactionsTable = ref(null)
  const transaction = ref({
    installments: [{
      value: 0,
    }],
  })

  // Table Handlers
  const tableColumns = [
    {
      key: 'payday',
      label: 'Dia de vencimento',
      tdClass: 'paydayTdClass',
      sortable: true,
    },
    {
      key: 'initial_date',
      label: 'A partir de',
      tdClass: 'initial_dateTdClass',
      sortable: true,
    },
    {
      key: 'final_date',
      label: 'Até',
      tdClass: 'final_dateTdClass',
      sortable: true,
    },
    {
      key: 'description',
      label: 'Descrição',
      tdClass: 'descriptionTdClass',
      sortable: false,
    },
    {
      key: 'value_installment',
      label: 'Valor',
      formatter: currency,
      tdClass: 'cashOutTdClass',
      sortable: true,
    },
    {
      key: 'actions',
      tdClass: 'actionsTdClass',
      label: 'ações',
    },
  ]

  const perPage = ref(10)
  const totalData = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50]
  const sortBy = ref('initial_date')
  const isSortDirDesc = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refFinancesScheduleTransactionsTable.value ? refFinancesScheduleTransactionsTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalData.value,
    }
  })

  const refetchData = () => {
    refFinancesScheduleTransactionsTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
    toast({
      component: ToastificationContent,
      props: {
        titleToast,
        icon,
        text,
        variant,
      },
    })
  }

  const catchErr = e => {
    if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'AlertCircleIcon', 'Atenção', e.response.data.message)
    else if (e.response.status === 401) showToast('danger', 'AlertCircleIcon', 'Atenção', 'Sua sessão expirou, faça novo login!')
    else showToast('danger', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
  }

  const fetch = (ctx, callback) => {
    store
      .dispatch('store-scheduled-transaction/fetch', {
        q: '',
        situation: '',
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sort: isSortDirDesc.value ? 'desc' : 'asc',
        user_id: userData ? userData.id : null,
        origin: 'MONTHLY_PAYMENT',
        withTrashed: true,
      })
      .then(response => {
        const { data, total } = response.data

        callback(data)
        totalData.value = total
      })
      .catch(e => {
        catchErr(e)
      })
  }

  const add = async data => {
    try {
      await store.dispatch('store-scheduled-transaction/add', {
        user_id: userData ? userData.id : null,
        scheduledTransaction: data,
      })
      refetchData()
      showToast()
      addModal.value = false
    } catch (e) {
      catchErr(e)
    }
  }

  const edit = async data => {
    try {
      await store.dispatch('store-scheduled-transaction/edit', {
        user_id: userData ? userData.id : null,
        scheduledTransaction: data,
      })
      refetchData()
      showToast()
      editModal.value = false
    } catch (e) {
      catchErr(e)
    }
  }

  const deleteData = async data => {
    try {
      await store.dispatch('store-scheduled-transaction/delete', data)
      showToast('success', 'CheckIcon', 'Sucesso', 'Cancelado com Sucesso.')
      deleteModal.value = false
      refetchData()
    } catch (e) {
      catchErr(e)
    }
  }

  return {
    tableColumns,
    perPage,
    currentPage,
    totalData,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refFinancesScheduleTransactionsTable,
    addModal,
    editModal,
    deleteModal,
    transaction,

    fetch,
    refetchData,
    add,
    edit,
    deleteData,
  }
}
