var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-modal',{attrs:{"id":"new-anamnese-question-modal","centered":"","size":"md","title":"Nova Pergunta"},on:{"hidden":_vm.hidden},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cancelar ")]),_c('b-button',{attrs:{"variant":"primary","disabled":invalid},on:{"click":_vm.save}},[_vm._v(" Inserir pergunta ")])]}}],null,true),model:{value:(_vm.showLocal),callback:function ($$v) {_vm.showLocal=$$v},expression:"showLocal"}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"pergunta","rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Pergunta","label-for":"question"}},[_c('b-form-input',{attrs:{"id":"question","autofocus":"","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.newQuestion.question),callback:function ($$v) {_vm.$set(_vm.newQuestion, "question", $$v)},expression:"newQuestion.question"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo de pergunta","label-for":"question-type","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.questionTypes,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"question-type"},model:{value:(_vm.newQuestion.type),callback:function ($$v) {_vm.$set(_vm.newQuestion, "type", $$v)},expression:"newQuestion.type"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }