import axios from '@axios'

export default {
  namespaced: true,
  state: {
    treatment: {},
    treatments: [],
  },

  getters: {
  },

  mutations: {
  },

  actions: {
    async fetch({ state }, args) {
      try {
        if (!state.treatments.length || args) {
          const resp = await axios.get(`${process.env.VUE_APP_API_URL}/treatment-evolutions${args ? `?${new URLSearchParams(args).toString()}` : '/'}`)
          return resp
        }
        return state.treatments
      } catch (e) {
        return e
      }
    },

    fetchOne(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/treatment-evolution/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    add(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/treatment-evolution`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    update(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/treatment-evolution/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    async reactive(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/treatment-evolution/reactive/${data.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    delete(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${process.env.VUE_APP_API_URL}/treatment-evolution/${data.data.id}/${data.force || ''}`)
          .then(response => {
            resolve(response)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
  },
}
