<template>
  <div>
    <validation-observer
      #default="{invalid}"
      ref="refFormObserver"
    >
      <b-form>
        <b-row>

          <b-col
            cols="12"
            md="8"
          >
            <b-form-group
              label="Convênio"
              label-for="health-insurance-id"
            >
              <v-select
                id="health-insurance-id"
                v-model="dataLocal.healthInsurance.id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :reduce="val => val.value"
                :clearable="true"
                :options="healthInsurances"
                placeholder="Selecione"
                @input="changeHealthInsurance"
              >
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>

            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >

            <b-form-group
              label="Carteira"
              label-for="health-card-id"
            >
              <b-form-input
                id="health-card-id"
                v-model="dataLocal.healthInsurance.health_card"
                trim
              />
            </b-form-group>
          </b-col>

        </b-row>

        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <validation-provider
              #default="{ errors }"
              name="Plano(s)"
              rules="required"
              immediate
            >
              <b-form-group
                label="Plano(s)"
                label-for="dental-plans-id"
                :state="errors.length > 0 ? false : null"
              >
                <!-- {{ dataLocal.dentalPlans }} -->
                <v-select
                  id="dental-plans-id"
                  v-model="dataLocal.dentalPlans"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :reduce="val => val.value"
                  :clearable="true"
                  :options="dentalPlansFiltered"
                  placeholder="Selecione"
                  multiple
                >
                  <template #no-options="{}">
                    Nenhum resultado encontrado, selecione um convênio.
                  </template>
                </v-select>

                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :disabled="invalid"
          @click="save(userData)"
        >
          Salvar Alterações
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BFormInvalidFeedback,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import useTabAccountPlan from './useTabAccountPlan'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const {
      dentalPlansFiltered,
      healthInsurances,
      dataLocal,
      resetFormData,
      changeHealthInsurance,
      save,
    } = useTabAccountPlan(props.userData, emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetFormData)

    return {
      dentalPlansFiltered,
      healthInsurances,
      required,
      refFormObserver,
      dataLocal,

      getValidationState,
      resetForm,
      resetFormData,
      changeHealthInsurance,
      save,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
