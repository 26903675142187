import { ref, computed } from '@vue/composition-api'

export default function useProcedureFilter(userData, procedures, status = 'IN_EVOLUTION') {
  const blankData = ({
    evolution_type: 'EVOLUTION', // ATTENDANCE - PLANNING - EVOLUTION
    user_id: userData.value.id, // Patient
  })

  const data = ref(JSON.parse(JSON.stringify(blankData)))
  const reset = () => {
    data.value = JSON.parse(JSON.stringify(blankData))
  }

  const showFilters = ref(false)

  const filter = ref({
    dateStart: null,
    dateEnd: null,
    content: null,
    dentist_id: null,
    category_id: null,
  })

  const treatmentsToRemove = ref([])

  const contentFilter = () => {
    if (+filter.value.content > 0 && filter.value.content) {
      treatmentsToRemove.value.push(...procedures.value.filter(el => !el.code || el.code !== filter.value.content))
    } else if (filter.value.content) {
      treatmentsToRemove.value.push(...procedures.value.filter(el => !el.procedure.toLowerCase().includes(filter.value.content.toLowerCase())))
    }
  }

  const categoryFilter = () => {
    treatmentsToRemove.value.push(...procedures.value.filter(el => el.procedure_category_id !== filter.value.category_id))
  }

  const dentistFilter = () => {
    treatmentsToRemove.value.push(...procedures.value.filter(el => el.pivot.dentist_id !== filter.value.dentist_id))
  }

  const dateFilter = () => {
    if (filter.value.dateStart && !filter.value.dateEnd) {
      treatmentsToRemove.value.push(...procedures.value.filter(el => new Date(el.pivot.created_at.substr(0, 10).replace(/-/g, '/')) < new Date(filter.value.dateStart.replace(/-/g, '/'))))
    } else if (!filter.value.dateStart && filter.value.dateEnd) {
      treatmentsToRemove.value.push(...procedures.value.filter(el => new Date(el.pivot.created_at.substr(0, 10).replace(/-/g, '/')) > new Date(filter.value.dateEnd.replace(/-/g, '/'))))
    } else if (filter.value.dateStart && filter.value.dateEnd) {
      treatmentsToRemove.value.push(...procedures.value.filter(el => new Date(el.pivot.created_at.substr(0, 10).replace(/-/g, '/')) > new Date(filter.value.dateEnd.replace(/-/g, '/')) || new Date(el.pivot.created_at.substr(0, 10).replace(/-/g, '/')) < new Date(filter.value.dateStart.replace(/-/g, '/'))))
    }
  }

  const isDateValidated = ref(true)
  const returnDiff = (start, end) => (end - start)
  const validateDate = () => {
    const isValid = ref(true)
    if (filter.value.dateStart && filter.value.dateEnd) {
      const yearsDiff = returnDiff(Number(filter.value.dateStart.slice(0, 4)), Number(filter.value.dateEnd.slice(0, 4)))
      const monthDiff = returnDiff(Number(filter.value.dateStart.slice(5, 7)), Number(filter.value.dateEnd.slice(5, 7)))
      const daysDiff = returnDiff(Number(filter.value.dateStart.slice(8, 10)), Number(filter.value.dateEnd.slice(8, 10)))
      isValid.value = (yearsDiff > 0 || (yearsDiff === 0 && monthDiff > 0) || (yearsDiff === 0 && monthDiff === 0 && daysDiff >= 0))
    }
    isDateValidated.value = isValid.value

    if (isDateValidated.value) dateFilter()
  }

  const isFiltering = () => {
    const filtering = ref(false)
    Object.keys(filter.value).forEach(key => {
      if (filter.value[key] !== null && (filter.value[key].length > 0 || filter.value[key] > 0)) filtering.value = true
    })
    return filtering.value
  }

  const filteredProcedures = computed(() => {
    treatmentsToRemove.value = []

    if (isFiltering()) {
      if (filter.value.content) contentFilter()
      if (filter.value.category_id) categoryFilter()
      if (filter.value.dentist_id) dentistFilter()
      if (filter.value.dateStart || filter.value.dateEnd) validateDate()

      if (status === 'NOT_FINISHED') {
        return userData.value.procedures.filter(e => e.pivot.status === 'NOT_FINISHED' && !e.pivot.evolutions.length).filter(el => !treatmentsToRemove.value.includes(el))
      }

      if (status === 'FINISHED') {
        return userData.value.procedures.filter(e => e.pivot.status === 'FINISHED').filter(el => !treatmentsToRemove.value.includes(el))
      }

      // in evolution
      return userData.value.procedures.filter(e => e.pivot.status === 'NOT_FINISHED' && e.pivot.evolutions.length).filter(el => !treatmentsToRemove.value.includes(el))
    }

    if (status === 'NOT_FINISHED') {
      return userData.value.procedures.filter(e => e.pivot.status === 'NOT_FINISHED' && (!e.pivot.evolutions || !e.pivot.evolutions.length))
    }

    if (status === 'FINISHED') {
      return userData.value.procedures.filter(e => e.pivot.status === 'FINISHED')
    }

    // in evolution
    return userData.value.procedures.filter(e => e.pivot.status === 'NOT_FINISHED' && e.pivot.evolutions && e.pivot.evolutions.length)
  })

  return {
    data,
    showFilters,
    filter,
    isDateValidated,
    filteredProcedures,

    reset,
    categoryFilter,
    dentistFilter,
    validateDate,
  }
}
