import { ref } from '@vue/composition-api'

export default function useShowDetailsModal(emit) {
  const show = ref(false)

  const showModal = () => {
    show.value = !show.value
  }

  const hidden = () => {
    emit('hidden')
  }

  return {
    show,
    showModal,
    hidden,
  }
}
