<template>
  <div>
    <div>
      <template>
        <component :is="userData === undefined ? 'div' : 'b-card'">

          <b-tabs
            v-if="userData"
            pills
          >

            <!-- Tab: Procedimentos -->
            <b-tab active>
              <template #title>
                <feather-icon
                  icon=""
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">A realizar</span>
              </template>
              <not-finished
                :user-data="userDataLocal"
                :dentist-options="dentistOptions"
                class="mt-2 pt-75"
                @refresh="refreshUserData"
              />
            </b-tab>

            <!-- Tab: Próteses -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon=""
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Em evolução</span>
              </template>
              <in-evolution
                :user-data="userDataLocal"
                :dentist-options="dentistOptions"
                class="mt-2 pt-75"
                @refresh="refreshUserData"
              />
            </b-tab>

            <!-- Tab: Implantes -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon=""
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Finalizados</span>
              </template>
              <finished
                :user-data="userDataLocal"
                :dentist-options="dentistOptions"
                class="mt-2 pt-75"
                @refresh="refreshUserData"
              />
            </b-tab>

          </b-tabs>
        </component>
      </template>
    </div>

  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BCard, BTabs, BTab,
} from 'bootstrap-vue'
import {
  toRefs, ref, watch,
} from '@vue/composition-api'
import NotFinished from './subtabs-procedures/NotFinished.vue'
import InEvolution from './subtabs-procedures/InEvolution.vue'
import Finished from './subtabs-procedures/Finished.vue'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BTabs,
    BTab,

    NotFinished,
    Finished,
    InEvolution,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    dentistOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { userData } = toRefs(props)
    const userDataLocal = ref(JSON.parse(JSON.stringify(userData.value)))

    const refreshUserData = newValue => {
      userDataLocal.value = JSON.parse(JSON.stringify(newValue))
    }

    watch(userData, newValue => {
      refreshUserData(newValue)
    }, {
      deep: true,
    })

    return {
      userDataLocal,
      refreshUserData,
    }
  },
}
</script>

<style lang="scss">

</style>
