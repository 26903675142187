import { render, staticRenderFns } from "./addTreatments.vue?vue&type=template&id=9ea74672&scoped=true"
import script from "./addTreatments.vue?vue&type=script&lang=js"
export * from "./addTreatments.vue?vue&type=script&lang=js"
import style0 from "./addTreatments.vue?vue&type=style&index=0&id=9ea74672&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ea74672",
  null
  
)

export default component.exports