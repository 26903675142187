import axios from '@axios'

export default {
  namespaced: true,
  state: {
    odontogram: {},
  },

  getters: { },

  mutations: {
    SET(state, data) {
      state.odontogram = data
    },
  },

  actions: {
    async fetch({ commit }, userData) {
      try {
        const resp = await axios.get(`${process.env.VUE_APP_API_URL}/odontograms/${userData.id}`)
        commit('SET', resp.data)
        return resp
      } catch (e) {
        return e
      }
    },

    update({ commit }, { userData, tooth }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/odontogram/${userData.id}`, tooth)
          .then(response => {
            commit('SET', response.data)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
