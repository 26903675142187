import axios from '@axios'

export default {
  namespaced: true,
  state: {
    treatment: {},
    treatments: [],
  },

  getters: {
  },

  mutations: {
  },

  actions: {
    add(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/patient/add-procedures`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    update(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/patient/update-procedures/${data.pivot.user_id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    async reactive(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/patient/reactive-procedure/${data.pivot.user_id}/${data.pivot.uuid}/${data.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    delete(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${process.env.VUE_APP_API_URL}/patient/delete-procedure/${data.data.pivot.user_id}/${data.data.pivot.uuid}/${data.data.id}/${data.force || ''}`)
          .then(response => {
            resolve(response)
          })
          .catch(e => {
            reject(e)
          })
      })
    },

    /**
     * addEvolution
     * @param {*} ctx
     * @param {*} data
     * @returns
     */
    addEvolution(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/procedure-evolution`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    /**
     * updateEvolution
     * @param {*} ctx
     * @param {*} data
     * @returns
     */
    updateEvolution(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/procedure-evolution/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    /**
     * deleteEvolution
     * @param {*} ctx
     * @param {*} data
     * @returns
     */
    deleteEvolution(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${process.env.VUE_APP_API_URL}/procedure-evolution/${data.evolut.id}/${data.force || ''}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    /**
     * reactiveEvolution
     * @param {*} ctx
     * @param {*} data
     * @returns
     */
    reactiveEvolution(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/procedure-evolution/reactive/${data.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
