<template>
  <validation-observer
    #default="{invalid}"
    ref="refFormObserver"
  >
    <b-modal
      id="editModal"
      v-model="showLocal"
      centered
      size="xl"
      title="Alterar Mensalidade Programada"
      @hidden="$emit('hidden')"
    >

      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <b-form class="pl-2 pr-2">
          <b-row>
            <b-col
              cols="12"
              lg="2"
            >
              <b-form-group
                label="Valor"
                label-for="value-installment"
              >
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <span>R$</span>
                  </b-input-group-prepend>
                  <currency-input
                    id="value-installment"
                    v-model="scheduledTransaction.value_installment"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              lg="2"
            >
              <validation-provider
                #default="validationContext"
                name="Todo o dia"
                rules="required|integer|min_value:1|max_value:31"
                immediate
              >
                <b-form-group
                  label="Todo o dia"
                  label-for="id-payday"
                >
                  <b-form-input
                    id="id-payday"
                    v-model="scheduledTransaction.payday"
                    :state="getValidationState(validationContext) ? null : false"
                    type="number"
                    trim
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              lg="4"
            >
              <validation-provider
                #default="validationContext"
                name="A partir de"
                rules="required"
                immediate
              >
                <b-form-group
                  label="A partir de"
                  label-for="id-initial-date"
                >
                  <b-input-group>
                    <b-form-input
                      id="id-initial-date"
                      v-model="scheduledTransaction.initial_date"
                      :state="getValidationState(validationContext) ? null : false"
                      type="month"
                    />
                  </b-input-group>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              lg="4"
            >
              <b-form-group
                label="Até"
                label-for="id-final-date"
              >
                <b-input-group>
                  <b-form-input
                    id="id-final-date"
                    v-model="scheduledTransaction.final_date"
                    type="month"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              lg="6"
            >
              <b-form-group
                label="Plano"
                label-for="dental-plans-id"
              >
                <v-select
                  id="dental-plans-id"
                  v-model="scheduledTransaction.dental_plan_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :reduce="val => val.value"
                  :clearable="true"
                  :options="dentalPlans"
                  placeholder="Selecione"
                >
                  <template #no-options="{}">
                    Nenhum resultado encontrado.
                  </template>
                </v-select>

              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              lg="6"
            >
              <b-form-group
                label="Plano de Contas"
                label-for="account-plan-id"
              >
                <v-select
                  id="account-plan-id"
                  v-model="scheduledTransaction.account_plan_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :reduce="val => val.value"
                  :clearable="true"
                  :options="accountPlans"
                  placeholder="Selecione"
                >
                  <template #no-options="{}">
                    Nenhum resultado encontrado.
                  </template>
                </v-select>

              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              lg="12"
            >
              <!-- Description -->
              <b-form-group
                label="Descrição"
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  v-model="scheduledTransaction.description"
                  rows="3"
                  trim
                />
              </b-form-group>
            </b-col>

          </b-row>
        </b-form>

      </b-card>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="primary"
          :disabled="invalid"
          @click="$emit('save', scheduledTransaction)"
        >
          Salvar
        </b-button>
        <b-button
          variant="outline-secondary"
          @click="cancel()"
        >
          Cancelar
        </b-button>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>

import {
  toRefs, ref, watch,
} from '@vue/composition-api'

import {
  BFormTextarea, BForm, BFormGroup, BFormInvalidFeedback, BInputGroup, BInputGroupPrepend,
  BCard, BRow, BCol, BFormInput, BButton,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import CurrencyInput from '@/views/components/currency/CurrencyInput.vue'

export default {
  components: {
    CurrencyInput,

    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormTextarea,
    BInputGroup,
    BInputGroupPrepend,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    dentalPlans: {
      type: Array,
      required: true,
    },
    accountPlans: {
      type: Array,
      required: true,
    },
    scheduledTransaction: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    localize('pt_BR') // vee-validate messages
    const showLocal = ref(false)
    // necessário para observar props
    const { show } = toRefs(props)
    watch(show, newValue => {
      showLocal.value = newValue
    })

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      showLocal,
      // installment,
      getValidationState,
      refFormObserver,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
