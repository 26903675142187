// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default function odontogramHandler() {
  // Use toast
  const toast = useToast()

  const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
    toast({
      component: ToastificationContent,
      props: {
        title: titleToast,
        icon,
        text,
        variant,
      },
    })
  }

  const fetch = async userData => {
    try {
      const t = await store.dispatch('store-odontogram/fetch', userData)
      return t.data
    } catch (e) {
      return e
    }
  }

  const save = async data => {
    try {
      if (data.value.id) await store.dispatch('store-procedure/update', data.value)
      else await store.dispatch('store-procedure/add', data.value)
      showToast()
    } catch (error) {
      if (error.response.status === 412) {
        showToast('warning', 'AlertCircleIcon', 'Atenção', error.response.data.message)
      } else {
        showToast('danger', 'AlertCircleIcon', 'Atenção', error.message)
      }
    }
  }

  return {
    fetch,
    save,
  }
}
