<template>
  <b-modal
    id="modal-add-procedure-observation"
    v-model="showLocal"
    centered
    size="md"
    title="Adicionar Observação"
    @hidden="hidden"
  >

    <!-- Textarea -->
    <b-form-group
      label="Observação"
      label-for="procedure-observation"
      label-align-left
    >
      <b-form-textarea
        id="procedure-observation"
        v-model="observationValue"
      />
    </b-form-group>

    <template #modal-footer="{ cancel }">

      <b-button
        variant="outline-secondary"
        @click="cancel()"
      >
        Cancelar
      </b-button>
      <b-button
        variant="primary"
        @click="save"
      >
        Salvar
      </b-button>

    </template>

  </b-modal>
</template>

<script>
import { toRefs, ref, watch } from '@vue/composition-api'
import {
  BModal, BFormGroup, BFormTextarea, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormTextarea,
    BButton,

  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    procedure: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const showLocal = ref(false)
    const observationValue = ref(null)
    const procedureData = ref({})

    const { procedure } = toRefs(props)

    // necessário para observar props
    const { show } = toRefs(props)
    watch(show, newValue => {
      showLocal.value = newValue
      procedureData.value = JSON.parse(JSON.stringify(procedure.value))
      procedureData.value.pivot.tooth_face_id = procedureData.value.pivot.tooth_faces ? procedureData.value.pivot.tooth_faces.map(e => e.id) : []
      procedureData.value.pivot.teeth_id = JSON.stringify(procedureData.value.pivot.teeth.id)

      observationValue.value = procedureData.value && procedureData.value.pivot.observation
    })

    const hidden = () => {
      observationValue.value = null
      emit('hidden')
    }

    const save = () => {
      procedureData.value.pivot.observation = observationValue.value
      emit('save', procedureData.value)
      emit('hidden')
    }

    return {
      showLocal,
      hidden,
      observationValue,
      save,
    }
  },
}
</script>

<style>

</style>
