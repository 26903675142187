import axios from '@axios'

export default {
  namespaced: true,
  state: {
    scheduledTransaction: {},
    scheduledTransactions: [],
  },
  getters: {},
  mutations: {},
  actions: {
    add(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/scheduled-transactions`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    edit(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/scheduled-transactions/${data.scheduledTransaction.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    async fetch(ctx, args) {
      try {
        const resp = await axios.get(`${process.env.VUE_APP_API_URL}/scheduled-transactions${args ? `?${new URLSearchParams(args).toString()}` : '/'}`)
        // if (!args) commit('SET_LIST', resp.data)
        // else { commit('SET_LIST', resp.data) }
        return resp
      } catch (e) {
        return e
      }
    },

    delete(ctx, data) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_URL}/scheduled-transaction/${data.scheduledTransaction.id}`, { reason: data.reason })
          .then(response => {
            resolve(response)
          })
          .catch(e => { reject(e) })
      })
    },
  },
}
