<template>
  <b-modal
    id="show-details-modal"
    v-model="showLocal"
    centered
    size="md"
    :title="title"
    @hidden="hidden"
  >
    <section>
      {{ details }}
    </section>

    <template #modal-footer="{ cancel }">
      <b-button
        variant="primary"
        @click="cancel()"
      >
        Ok
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { toRefs, ref, watch } from '@vue/composition-api'
import { BModal, BButton } from 'bootstrap-vue'
import useShowDetailsModal from './useShowDetalsModal'

export default {
  components: {
    BModal,
    BButton,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: () => '',
    },
    details: {
      type: String,
      default: () => '',
    },
  },

  setup(props, { emit }) {
    const showLocal = ref(false)
    const { show } = toRefs(props)
    watch(show, newValue => {
      showLocal.value = newValue
    })
    const {
      showModal,
      hidden,
    } = useShowDetailsModal(emit)

    return {
      showModal,
      hidden,
      showLocal,
    }
  },
}
</script>

<style>

</style>
