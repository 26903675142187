import { ref, toRefs, watch } from '@vue/composition-api'
import store from '@/store'

export default function usePatientStatusModal(emit, props) {
  const showLocal = ref(false)

  const { show, userData } = toRefs(props)
  watch(show, newValue => {
    showLocal.value = newValue
  })

  const activeOptions = [
    { label: 'Ativo', value: 1 },
    { label: 'Inativo', value: 0 },
  ]

  // padrão de substatus
  const subStatusOptions = [
    { label: 'Normal', value: 0 },
    { label: 'Contenção', value: 1 },
    { label: 'SPC', value: 2 },
    { label: 'Cancelado', value: 3 },
  ]

  const patientStatus = ref(
    userData.value.status ? userData.value.status : 1,
  )

  const patientSubStatus = ref(
    userData.value.sub_status ? userData.value.sub_status : 0,
  )

  const resetPatientStatus = () => {
    patientStatus.value = userData.value.status
    patientSubStatus.value = userData.value.sub_status
  }

  const hidden = () => {
    emit('hidden')
    resetPatientStatus()
  }

  const confirmStatusChange = () => {
    const payload = userData.value
    payload.status = patientStatus.value
    payload.sub_status = patientSubStatus.value

    store.dispatch('store-patient/update', payload)
      .then(() => {
        userData.value.status = patientStatus.value
        userData.value.sub_status = patientSubStatus.value
      })
      .catch(() => { })
    hidden()
  }

  return {
    showLocal,
    hidden,
    activeOptions,
    subStatusOptions,
    patientStatus,
    patientSubStatus,
    confirmStatusChange,
  }
}
