// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref } from '@vue/composition-api'

import store from '@/store'

export default function useMain(emit) {
  // Use toast
  const toast = useToast()

  const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
    toast({
      component: ToastificationContent,
      props: {
        titleToast,
        icon,
        text,
        variant,
      },
    })
  }

  const catchErr = e => {
    if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'AlertCircleIcon', 'Atenção', e.response.data.message)
    else if (e.response.status === 401) showToast('danger', 'AlertCircleIcon', 'Atenção', 'Sua sessão expirou, faça novo login!')
    else showToast('danger', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
  }

  const showOverlay = ref(false)

  const save = async documentTemplate => {
    try {
      if (documentTemplate.id) await store.dispatch('store-document-templates/update', documentTemplate)
      else await store.dispatch('store-document-templates/add', documentTemplate)
      emit('refreshData')
      emit('close')
      showToast()
    } catch (e) {
      catchErr(e)
    }
  }

  const typeOptions = [
    { label: 'Geral', value: 'GENERAL' }, // APARECE EM TODOS OS LOCAIS
    { label: 'Contrato Orçamento', value: 'BUDGET_CONTRACT' },
    { label: 'Contrato Ortodontia', value: 'ORTHODONTIC_CONTRACT' },
    { label: 'Atestado Médico', value: 'MEDICAL_CERTIFICATE' },
    { label: 'Receituário', value: 'PRESCRIPTION' },
  ]

  const handleType = type => typeOptions.find(e => e.value === type).label

  return {
    showOverlay,
    typeOptions,
    handleType,
    save,
  }
}
